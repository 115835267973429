/* eslint-disable no-extend-native */
if (!Array.prototype.find) {
    Object.defineProperty(Array.prototype, "find", {
        /**
         * @param  {function} predicate
         * @return {*}
         */
        value(predicate) {
            if (!this) {
                throw new TypeError("\"this\" is null or not defined");
            }

            if (typeof predicate !== "function") {
                throw new TypeError("\"predicate\" must be a \"Function\"");
            }

            const array = Object(this);
            const length = array.length >>> 0;
            const thisArg = arguments[1];

            for (let i = 0; i < length; i++) {
                const item = array[i];

                if (predicate.call(thisArg, item, i, array)) {
                    return item;
                }
            }

            return undefined;
        },
        configurable: true,
        writable: true
    });
}
