import { isEmpty } from "../../../helpers";

function getUserDetails(userDetails, isAuthValid, isWilling) {
    const { firstName, lastName, orgId, orgName, email } = userDetails;
    let { name } = userDetails;
    let orgDetails = {orgId, orgName};
    if (isAuthValid) {
        name = `${firstName} ${lastName}`;
    } else {
        name = isWilling === "yes" ? name: "Anonymous User";
    }
    return {name, email, orgDetails};

}

function getPayloadForFeedback(formData, isAuthValid) {
    const { userDetails, description, category, feedback, isWilling = "Not selected" } = formData;
    const { name, email, orgDetails: { orgId, orgName } } = getUserDetails(userDetails, isAuthValid, isWilling);
    const otherDetailsField = [
        {
            "type": "mrkdwn",
            "text": `*Is willing to participate in a follow-up conversation:*\n${isWilling}`
        }
    ];
    if(!isEmpty(email)) {
        otherDetailsField.push({
            "type": "mrkdwn",
            "text": `*Email:*\n${email}`
        });
    }
    if(!isEmpty(orgId)) {
        otherDetailsField.push({
            "type": "mrkdwn",
            "text": `*Org ID:*\n${orgId}`
        });
    }
    if(!isEmpty(orgName)) {
        otherDetailsField.push({
            "type": "mrkdwn",
            "text": `*Org Name:*\n${orgName}`
        });
    }
    const payload = {
        "blocks": [
            // header section
            {
                "type": "header",
                "text": {
                    "type": "plain_text",
                    "text": `New feedback from ${name}`,
                    "emoji": true
                }
            },
            // category and feedback type section
            {
                "type": "section",
                "fields": [
                    {
                        "type": "mrkdwn",
                        "text": `*Category:*\n${category}`
                    },
                    {
                        "type": "mrkdwn",
                        "text": `*Feedback Type:*\n${feedback}`
                    }
                ]
            },
            {
                "type": "section",
                "fields": otherDetailsField
            },
            {
                "type": "section",
                "text": {
                    "type": "mrkdwn",
                    "text": `*Feedback:*\n${description}`
                }
            }
        ]
    };

    return payload;
}

export default getPayloadForFeedback;