import React, { createContext, useReducer, useContext, useMemo } from "react";
import { object } from "prop-types";
import  alertReducer from "./reducer";

export const AlertContext = createContext(null);

export function AlertContextProvider(props) {
    const { initialState, children } = props;
    const [
        state,
        dispatch
    ] = useReducer(alertReducer, initialState);

    const contextValue = useMemo(() => ({ state, dispatch }), [
        state,
        dispatch
    ]);

    return (
        <AlertContext.Provider value={contextValue}>
            {children}
        </AlertContext.Provider>
    );
}

AlertContextProvider.propTypes = {
    initialState: object
};

export function useAlertContext() {
    return useContext(AlertContext);
}