import React from "react";
import { Link } from "react-router-dom";

import "./style.css";

function getValue(property) {
    return typeof property === "function"
        ? property()
        : property;
}

export default function ErrorPage(props) {
    const {
        code = 404,
        title = "Page not found",
        description = (
            <div className="error-description">
                <p>The page you are looking for doesn&#39;t exist or has been removed! Please check your URL is
                    valid.</p>
                <p>
                    <Link to="/">Click here</Link>
                    &nbsp;to go back and try again, or head to the&nbsp;
                    <Link to="/">
                        VMware Cloud
                        Services
                    </Link>
                    &nbsp;console.
                </p>
            </div>
        )
    } = props;

    return (
        <div className="error">
            <h2 className="error-code">{code}</h2>
            <p className="error-title">{getValue(title)}</p>

            {getValue(description)}
        </div>
    );
}
