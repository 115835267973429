import React from "react";
import PropTypes from "prop-types";
import { CdsAlert } from "@cds/react/alert";
import { CdsIcon } from "@cds/react/icon";
import { ClarityIcons, windowCloseIcon  } from "@cds/core/icon";
import "./Alert.scss";
import { isEmpty } from "../../helpers";

ClarityIcons.addIcons(windowCloseIcon);

// helper for returning alert class
const mssgType = type => {
    switch (type) {
    case "error":
        return "alert-styling alert-error";
    case "warning":
        return "alert-styling alert-warning";
    case "success":
        return "alert-styling alert-success";
    case "danger":
        return "alert-styling alert-danger";
    default:
        return "alert-styling alert-info";
    }
};

const Alert = ({ type = "info", message, closeAlert, actions, closable }) => (

    <div className={mssgType(type)}>
        <div className="alert-wrapper">
            <div role="region" tabIndex="0" aria-label={message} className={`${ isEmpty(actions) ? "alert-section-full-width" : "alert-section"}`}>
                <CdsAlert status={type} closable onCloseChange={closeAlert}>
                    <span className="alert-text">{message}</span>
                </CdsAlert>
            </div>
            {!!actions && actions.map(({ action, label, className, id }) => <button key={id} className={className} onClick={action}>{label}</button>)}
            {closable && <button aria-label="Close" type="button" className="close" onClick={closeAlert}>
                <CdsIcon aria-hidden="true" shape="window-close" size="22" />
            </button>}
        </div>
    </div>
);

Alert.propType = {
    type: PropTypes.string.isRequired, // type of alert: success, error, warning, info. default is info
    message: PropTypes.string.isRequired, // message
    closeAlert: PropTypes.func.isRequired, // handler to close alert
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            action: PropTypes.func.isRequired,
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            className: PropTypes.string.isRequired
        })
    ), // additional actions
    closable: PropTypes.bool
};

export default Alert;