const config = require(`./config-files/${process.env.REACT_APP_CONFIG_ENV}.json`);

const appConf = config["app-config"];
const mapIconsBase = config["mapIconsBase"];
const featureFlagSdkKey = config["featureFlagSDKKey"];
const swaggerDocLink = config["swaggerDocLink"];
const interactiveValidationRedirectLink = config["interactiveValidationRedirectLink"];


const APP_CONFIG = {

    SSO : {
        DEV : {
            CSP_HOST_STG    : appConf.CSP_HOST_STG,
            DISCOVERY_URL   : appConf.DISCOVERY_URL,
            CLIENT_ID       : appConf.CLIENT_ID,
            CLIENT_SECRET   : appConf.CLIENT_SECRET,
            REDIRECT_URL    : appConf.REDIRECT_URL,
            SERVICE_ID      : appConf.SERVICE_ID,
            APP_BASE        : appConf.APP_BASE,
            APP_VERSION     : appConf.APP_VERSION,
            CONTEXT_URL     : appConf.CONTEXT_URL
        },
        DEVQA : {
            CSP_HOST_STG    : appConf.CSP_HOST_STG,
            DISCOVERY_URL   : appConf.DISCOVERY_URL,
            CLIENT_ID       : appConf.CLIENT_ID,
            CLIENT_SECRET   : appConf.CLIENT_SECRET,
            REDIRECT_URL    : appConf.REDIRECT_URL,
            SERVICE_ID      : appConf.SERVICE_ID,
            APP_BASE        : appConf.APP_BASE,
            APP_VERSION     : appConf.APP_VERSION,
            CONTEXT_URL     : appConf.CONTEXT_URL
        },
        DEVINT : {
            CSP_HOST_STG    : appConf.CSP_HOST_STG,
            DISCOVERY_URL   : appConf.DISCOVERY_URL,
            CLIENT_ID       : appConf.CLIENT_ID,
            CLIENT_SECRET   : appConf.CLIENT_SECRET,
            REDIRECT_URL    : appConf.REDIRECT_URL,
            SERVICE_ID      : appConf.SERVICE_ID,
            APP_BASE        : appConf.APP_BASE,
            APP_VERSION     : appConf.APP_VERSION,
            CONTEXT_URL     : appConf.CONTEXT_URL
        },
        LOCAL : {
            CSP_HOST_STG    : appConf.CSP_HOST_STG,
            DISCOVERY_URL   : appConf.DISCOVERY_URL,
            CLIENT_ID       : appConf.CLIENT_ID,
            CLIENT_SECRET   : appConf.CLIENT_SECRET,
            REDIRECT_URL    : appConf.REDIRECT_URL,
            SERVICE_ID      : appConf.SERVICE_ID,
            APP_BASE        : appConf.APP_BASE,
            APP_VERSION     : appConf.APP_VERSION,
            CONTEXT_URL     : appConf.CONTEXT_URL
        },
        PROD : {
            CSP_HOST_STG    : appConf.CSP_HOST_STG,
            DISCOVERY_URL   : appConf.DISCOVERY_URL,
            CLIENT_ID       : appConf.CLIENT_ID,
            CLIENT_SECRET   : appConf.CLIENT_SECRET,
            REDIRECT_URL    : appConf.REDIRECT_URL,
            SERVICE_ID      : appConf.SERVICE_ID,
            APP_BASE        : appConf.APP_BASE,
            APP_VERSION     : appConf.APP_VERSION,
            CONTEXT_URL     : appConf.CONTEXT_URL
        },
        PRODUCTION : {
            CSP_HOST_STG    : appConf.CSP_HOST_STG,
            DISCOVERY_URL   : appConf.DISCOVERY_URL,
            CLIENT_ID       : appConf.CLIENT_ID,
            CLIENT_SECRET   : appConf.CLIENT_SECRET,
            REDIRECT_URL    : appConf.REDIRECT_URL,
            SERVICE_ID      : appConf.SERVICE_ID,
            APP_BASE        : appConf.APP_BASE,
            APP_VERSION     : appConf.APP_VERSION,
            CONTEXT_URL     : appConf.CONTEXT_URL
        },
        STAGING : {
            CSP_HOST_STG    : appConf.CSP_HOST_STG,
            DISCOVERY_URL   : appConf.DISCOVERY_URL,
            CLIENT_ID       : appConf.CLIENT_ID,
            CLIENT_SECRET   : appConf.CLIENT_SECRET,
            REDIRECT_URL    : appConf.REDIRECT_URL,
            SERVICE_ID      : appConf.SERVICE_ID,
            APP_BASE        : appConf.APP_BASE,
            APP_VERSION     : appConf.APP_VERSION,
            CONTEXT_URL     : appConf.CONTEXT_URL
        }
    },
    mapIconsBase,
    featureFlagSdkKey,
    swaggerDocLink,
    interactiveValidationRedirectLink
};

export default APP_CONFIG;
