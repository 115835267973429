import { Component } from "react";
import { get } from "../helpers";

//custom component with initial methods
export class BaseComponent extends Component {
    // getProps() {
    //     return this.props;
    // }

    getProp(key = "", defaultKey = "") {
        return get(this.props, key, defaultKey);
    }

    // getState(key = "") {
    //     return get(this.state, key);
    // }

    // callHook(callback, value = "") {
    //     return callback(value);
    // }

    // render() {
    //     return null;
    // }
}