import React from "react";
import { Routes } from "./routes";
import { appContext } from "../appContext";

function Mainbody (props) {

    return (
        <appContext.Provider value={{ffClient: props.ffClient}}>
            <div className={"content-container"}>
                <div className="content-area">
                    <Routes showFooter={props.showFooter} userTokenValid={props.userTokenValid} ffClient={props.ffClient} isAdmin={props.isAdmin} showNavInlineSearch={props.showNavInlineSearch} isOrgOwner={props.isOrgOwner} />
                </div>
            </div>
        </appContext.Provider>
    );

}

export default Mainbody;
