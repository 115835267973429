import React from "react";
import { Tooltip } from "../Tooltip";
import PropTypes from "prop-types";

/**
 * Use this component to have a label for the form elements
 */
const BasicLabel = props => {

    const { label, required = false, helpTextPosition = "", helpText = "", Id="" } = props;

    return (
        <label htmlFor={Id} className="clr-control-label">
            <div className="crc-label-content">
                <div className={ `crc-label-text ${required ? "required" : ""}` }>{label}</div>
                {helpText &&!!helpTextPosition && helpTextPosition === "after-label" && 
                    <Tooltip content={helpText} >
                        <span className="icon"><clr-icon shape="info-circle" class="is-info" size="20"></clr-icon></span>
                    </Tooltip>
                }
            </div>
        </label>
    );
};

BasicLabel.propTypes = {
    /** label */
    label: PropTypes.string,
    /** set required as true if the field is mandatory */
    required: PropTypes.bool,
    /** if helptext is needed, mention the position using  helpTextPosition*/
    helpTextPosition: PropTypes.string,
    /** helptext string*/
    helpText: PropTypes.string
};

export default BasicLabel;