import React from "react";
import PropTypes from "prop-types";
import { CdsDivider } from "@cds/react/divider";

const BrandSeparator = props => {
    const {
        orientation,
        size,
        color
    } = props;
    const customeStyle = {
        "--size" : size,
        "--color" : color
    };
    return (
        <CdsDivider orientation={orientation} style={customeStyle} />
    );
};

BrandSeparator.propTypes = {
    orientation: PropTypes.oneOf([
        "horizontal",
        "vertical"
    ]),
    size: PropTypes.string,
    color: PropTypes.string
};

BrandSeparator.defaultProps = { 
    orientation:"horizontal", 
    size:"5px", 
    color:"linear-gradient(90deg, #78BE20 5.27%, #00C1D5 26.55%, #00A1B3 51.37%, #0091DA 76.7%, #7F35B2 100%)" 
};

export default BrandSeparator;