const getUserDetails = () => {
    const orgId = window.localStorage.getItem("current_org");
    const orgName = window.localStorage.getItem("orgName");
    const userDetails = window.localStorage.getItem("user");
    let userObj = {};
    try {
        userObj = userDetails ? JSON.parse(userDetails) : {};
    } catch(e) {
        userObj = {};
    }
    const {email, phonenumber, userid, firstname, lastname} = userObj;
    return {
        orgId,
        orgName,
        email,
        phoneNumber: phonenumber,
        userId: userid,
        firstName: firstname,
        lastName: lastname
    };
};

export default getUserDetails;
