import { request } from "../helpers/httpHelpers";
import {SERVER_URL} from "./apiHelper";

const login = payload => {
    const config = {
        url: `${SERVER_URL}/sso/login`,
        method: "POST",
        payload
    };

    return request(config);
};

const relogin = payload => {
    const config = {
        url: `${SERVER_URL}/sso/relogin`,
        method: "POST",
        payload
    };

    return request(config);
};

const validate = paramsArray => {
    const config = {
        url: `${SERVER_URL}/sso/session/validate`,
        method: "GET",
        payload: paramsArray
    };
    return request(config);
};

export { login, relogin, validate };

// import serverConfig from "../../config/server";
// import appConfig from "../../config/app";

// const CURRENT_ENV = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : "prod";
// const SERVER_URL = serverConfig[CURRENT_ENV].url;

// class SSO {

//     constructor() {

//     }

//     validateUser() {
//         return true;
//     }

//     initiateLogout() {
//         return true;
//     }

//     finishSSO() {
//         return true;
//     }
// }

// export default new SSO();

