import React, { Component } from "react";

// Importing clarity styles and script
import "@clr/ui/clr-ui.min.css";

import "@clr/icons/clr-icons.min.css";
import "@webcomponents/custom-elements/custom-elements.min.js";

import "modern-normalize/modern-normalize.css"; // css reset
import "@cds/core/global.css"; // clarity global styles
import "@cds/core/styles/module.shims.css"; // non-evergreen browser shims
import "@cds/city/css/bundles/default.css"; // load base font
// Custom style
import "../App.css";

import Main from "./Main";
import Notification from "../components/Notification/Notification";
import { appConfig } from "../helpers";
import LocalStorageHelper from "../services/helpers/LocalStorageHelper";

const lumos = window["lumos"];

class App extends Component {

    componentDidMount(){
        // This line initializes and loads the library
        const {lumosClientId} = appConfig;
        const userID = LocalStorageHelper.get("userId");
        if (userID){
            lumos.identify(userID);
        }
        lumos.init(lumosClientId, "marketplace-lumos-client");
    }

    render() {
        return (
            <>
                <div className="hide-refresh-notification" id="refresh-notification" onClick={() => {
                    window.location.reload(); 
                }}>
                    <Notification type={"warning"} text={"The page is out of date. Please refresh the browser window to use the new version or click here to refresh this tab."} />
                </div>
                <Main />
            </>
            
        );
    }
}

export default App;
