import React from "react";
import PERMISSIONS from "../../config/permissions";
import {appConfig, get, getFeatureFlagValue} from "../../helpers";
import { checkIsAdminRole } from "../../services/helpers";
import { CdsIcon } from "@cds/react/icon";
import { ClarityIcons, checkCircleIcon  } from "@cds/core/icon";
ClarityIcons.addIcons(checkCircleIcon);

const CSP_FEATURE_FLAGS = get(appConfig, "CSP_FEATURE_FLAGS", "");
const {COMMERCE_FEATURES, TRANSIENT_REPO_CLIENT, SERVICE_CATALOG_CLIENT, VAC_CLIENT, SERVICE_CATALOG_CLIENT_V2} = CSP_FEATURE_FLAGS;

// Enum for different user types/perona
const USER_TYPES = {
    USER: "marketplaceuser",
    ADMIN: "marketplaceadmin"
};

const userObj = window.localStorage.getItem("user");
let user={};
try{
    user = JSON.parse(userObj);
}catch(e){
    user = {};
}
    
// Main lop level navigation items list with roles and permissions.
const mainNavList =  (ffClient, isLoggedIn) => {
    const isSvcCatalogEnabled = getFeatureFlagValue(ffClient, SERVICE_CATALOG_CLIENT_V2);
    
    return [
        {
            displayname: "View",
            slug: "view",
            roles: [
                USER_TYPES.USER,
                USER_TYPES.ADMIN
            ],
            permissions: [PERMISSIONS.GENERAL_PERMISSION]
        },
        {
            displayname: "Publish",
            slug: "publish",
            roles: [USER_TYPES.USER],
            permissions: [PERMISSIONS.ORG_PUBLISH_ENABLED]
        },
        {
            displayname: "Catalog",
            slug: "catalog",
            roles: [
                USER_TYPES.USER,
                USER_TYPES.ADMIN
            ],
            permissions: [PERMISSIONS.GENERAL_PERMISSION],
            show: () => (isLoggedIn && (isSvcCatalogEnabled))
        },
        {
            displayname: "Manage",
            slug: "manage",
            roles: [
                USER_TYPES.USER,
                USER_TYPES.ADMIN
            ],
            permissions: [PERMISSIONS.GENERAL_PERMISSION]
        },
        {
            displayname: "Settings",
            slug: "settings",
            roles: [USER_TYPES.USER],
            permissions: [PERMISSIONS.GENERAL_PERMISSION]
        },
        {
            displayname: "Analytics",
            slug: "analytics",
            roles: [USER_TYPES.ADMIN],
            permissions: [PERMISSIONS.GENERAL_PERMISSION]
        }
    ]; 
};

// Subnav menu items for View, const name "view" should correspond to slug of mainNavList
const view = [
    {
        displayname: "Solutions",
        slug: "solutions",
        roles: [USER_TYPES.USER],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        route: "/services"
    },
    {
        displayname: "Analytics",
        slug: "analytics",
        roles: [USER_TYPES.USER],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        route: "/analytics/subscription"
    },
    {
        displayname: "Leads",
        slug: "leads",
        roles: [USER_TYPES.USER],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        route: "/leads"
    },
    {
        displayname: "Notifications",
        slug: "notifications",
        roles: [USER_TYPES.ADMIN],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        route: "/user/notifications"
    },
    {
        displayname: "Users",
        slug: "users",
        roles: [USER_TYPES.ADMIN],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        route: "/user/list"
    },
    {
        displayname: "Legal Agreement List",
        slug: "legalagreementlist",
        roles: [USER_TYPES.ADMIN],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        route: "/admin/legal-agreement"
    },
    {
        displayname: "Egress IPs",
        slug: "egressips",
        roles: [USER_TYPES.ADMIN],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        route: "/egress-info"
    },
    {
        displayname: "Leads",
        slug: "leads",
        roles: [USER_TYPES.ADMIN],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        route: "/leads"
    }
];

// Subnav menu items for Publish, const name "publish" should correspond to slug of mainNavList
const publish = [
    {
        displayname: "Solution",
        slug: "solution",
        roles: [USER_TYPES.USER],
        permissions: [PERMISSIONS.ORG_PUBLISH_ENABLED],
        route: "/services/management"
    },
    {
        displayname: "Edit Publisher Name ",
        slug: "editPublisherName",
        roles: [USER_TYPES.USER],
        permissions: [PERMISSIONS.ORG_PUBLISH_ENABLED],
        route: "/user/org/settings"
    }
];

// Subnav menu items for Manage, const name "manage" should correspond to slug of mainNavList
const manage = ffClient => {

    const isCommerceEnabled = getFeatureFlagValue(ffClient, COMMERCE_FEATURES);
    const isVACFeatureEnabled = getFeatureFlagValue(ffClient, VAC_CLIENT);

    const manageMenuItems = isVACFeatureEnabled ? 
        [
            {
                displayname: "Marketplace Catalog",
                slug: "marketplacecatalog",
                roles: [USER_TYPES.USER],
                permissions: [PERMISSIONS.GENERAL_PERMISSION],
                classes: "heading-subnav-button",
                type: "header"
            },
            {
                displayname: isCommerceEnabled ? "Deployments" : "Subscriptions",
                slug: isCommerceEnabled ? "deployments" : "subscriptions",
                roles: [USER_TYPES.USER],
                permissions: [PERMISSIONS.GENERAL_PERMISSION],
                route: isCommerceEnabled ? "/deployment/list" : "/subscription/list"
            },
            {
                displayname: "Subscriptions",
                slug: "subscriptions",
                roles: [USER_TYPES.USER],
                permissions: [PERMISSIONS.GENERAL_PERMISSION],
                featureFlags: [COMMERCE_FEATURES],
                route: "/subscriptions/list"
            },
            {
                displayname: "Service Management",
                slug: "servicemanagement",
                roles: [USER_TYPES.ADMIN],
                permissions: [PERMISSIONS.GENERAL_PERMISSION],
                route: "/admin/services/management"
            },
            {
                displayname: "Service Management (Test)",
                slug: "servicemanagement",
                roles: [USER_TYPES.ADMIN],
                permissions: [PERMISSIONS.GENERAL_PERMISSION],
                route: "/admin/services/test-management"
            },
            {
                displayname: "User Management",
                slug: "usermanagement",
                roles: [USER_TYPES.ADMIN],
                permissions: [PERMISSIONS.GENERAL_PERMISSION],
                route: "/admin/usermanagement"
            },
            {
                displayname: "Org Management",
                slug: "orgmanagement",
                roles: [USER_TYPES.ADMIN],
                permissions: [PERMISSIONS.GENERAL_PERMISSION],
                route: "/admin/orgmanagement"
            },
            {
                displayname: "Peripheral Management",
                slug: "peripheralmanagement",
                roles: [USER_TYPES.ADMIN],
                permissions: [PERMISSIONS.GENERAL_PERMISSION],
                route: "/admin/peripheralmanagement"
            },
            {
                displayname: "Data Management",
                slug: "datamanagement",
                roles: [USER_TYPES.ADMIN],
                permissions: [PERMISSIONS.GENERAL_PERMISSION],
                route: "/admin/datamanagement"
            },
            {
                displayname: "EULA Management",
                slug: "eulamanagement",
                roles: [USER_TYPES.ADMIN],
                permissions: [PERMISSIONS.GENERAL_PERMISSION],
                route: "/eulamanagement"
            },
            {
                displayname: "Marketing Assets",
                slug: "marketingassets",
                roles: [USER_TYPES.ADMIN],
                permissions: [PERMISSIONS.GENERAL_PERMISSION],
                route: "/admin/marketingassets"
            },
            {
                displayname: "SKU Management",
                slug: "skumanagement",
                roles: [USER_TYPES.ADMIN],
                permissions: [PERMISSIONS.COMMERCE_ADMIN],
                featureFlags: [COMMERCE_FEATURES],
                route: "/admin/skumanagement"
            },
            {
                displayname: "Subscription Management",
                slug: "subscriptionmanagement",
                roles: [USER_TYPES.ADMIN],
                permissions: [PERMISSIONS.COMMERCE_ADMIN],
                featureFlags: [COMMERCE_FEATURES],
                route: "/admin/subscriptionmanagement"
            }
        ]:
        [
            {
                displayname: "Notifications",
                slug: "notifications",
                roles: [USER_TYPES.USER],
                permissions: [PERMISSIONS.GENERAL_PERMISSION],
                route: "/user/notifications"
            },
            {
                displayname: isCommerceEnabled ? "Deployments" : "Subscriptions",
                slug: isCommerceEnabled ? "deployments" : "subscriptions",
                roles: [USER_TYPES.USER],
                permissions: [PERMISSIONS.GENERAL_PERMISSION],
                route: isCommerceEnabled ? "/deployment/list" : "/subscription/list"
            },
            {
                displayname: "Subscriptions",
                slug: "subscriptions",
                roles: [USER_TYPES.USER],
                permissions: [PERMISSIONS.GENERAL_PERMISSION],
                featureFlags: [COMMERCE_FEATURES],
                route: "/subscriptions/list"
            },
            {
                displayname: "Users",
                slug: "users",
                roles: [USER_TYPES.USER],
                permissions: [PERMISSIONS.GENERAL_PERMISSION],
                route: "/user/list"
            },
            {
                displayname: "Service Management",
                slug: "servicemanagement",
                roles: [USER_TYPES.ADMIN],
                permissions: [PERMISSIONS.GENERAL_PERMISSION],
                route: "/admin/services/management"
            },
            {
                displayname: "Service Management (Test)",
                slug: "servicemanagementtest",
                roles: [USER_TYPES.ADMIN],
                permissions: [PERMISSIONS.GENERAL_PERMISSION],
                route: "/admin/services/test-management"
            },
            {
                displayname: "User Management",
                slug: "usermanagement",
                roles: [USER_TYPES.ADMIN],
                permissions: [PERMISSIONS.GENERAL_PERMISSION],
                route: "/admin/usermanagement"
            },
            {
                displayname: "Org Management",
                slug: "orgmanagement",
                roles: [USER_TYPES.ADMIN],
                permissions: [PERMISSIONS.GENERAL_PERMISSION],
                route: "/admin/orgmanagement"
            },
            {
                displayname: "Peripheral Management",
                slug: "peripheralmanagement",
                roles: [USER_TYPES.ADMIN],
                permissions: [PERMISSIONS.GENERAL_PERMISSION],
                route: "/admin/peripheralmanagement"
            },
            {
                displayname: "Data Management",
                slug: "datamanagement",
                roles: [USER_TYPES.ADMIN],
                permissions: [PERMISSIONS.GENERAL_PERMISSION],
                route: "/admin/datamanagement"
            },
            {
                displayname: "EULA Management",
                slug: "eulamanagement",
                roles: [USER_TYPES.ADMIN],
                permissions: [PERMISSIONS.GENERAL_PERMISSION],
                route: "/eulamanagement"
            },
            {
                displayname: "Marketing Assets",
                slug: "marketingassets",
                roles: [USER_TYPES.ADMIN],
                permissions: [PERMISSIONS.GENERAL_PERMISSION],
                route: "/admin/marketingassets"
            },
            {
                displayname: "SKU Management",
                slug: "skumanagement",
                roles: [USER_TYPES.ADMIN],
                permissions: [PERMISSIONS.COMMERCE_ADMIN],
                featureFlags: [COMMERCE_FEATURES],
                route: "/admin/skumanagement"
            },
            {
                displayname: "Subscription Management",
                slug: "subscriptionmanagement",
                roles: [USER_TYPES.ADMIN],
                permissions: [PERMISSIONS.COMMERCE_ADMIN],
                featureFlags: [COMMERCE_FEATURES],
                route: "/admin/subscriptionmanagement"
            }
        ];

    return manageMenuItems;
};

// Subnav menu items for View, const name "view" should correspond to slug of mainNavList
const settings = [
    {
        displayname: "Egress IPs",
        slug: "egressIPs",
        roles: [
            USER_TYPES.USER,
            USER_TYPES.ADMIN
        ],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        route: "/egress-info"
    },
    {
        displayname: "Legal Agreement",
        slug: "legalAgreement",
        roles: [USER_TYPES.USER],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        route: "/legal-agreement"
    },
    {
        displayname: "Legal Agreement List",
        slug: "legalAgreement",
        roles: [USER_TYPES.ADMIN],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        route: "/admin/legal-agreement"
    },
    {
        displayname: "Notifications",
        slug: "notifications",
        roles: [
            USER_TYPES.USER,
            USER_TYPES.ADMIN
        ],
        featureFlags: [VAC_CLIENT],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        route: "/user/notifications"
    },
    {
        displayname: "Distribution Registry",
        slug: "distributionRegistry",
        roles: [USER_TYPES.USER],
        featureFlags: [
            TRANSIENT_REPO_CLIENT,
            SERVICE_CATALOG_CLIENT
        ],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        route: "/distribution-registry"
    },
    {
        displayname: "Users",
        slug: "users",
        roles: [
            USER_TYPES.USER,
            USER_TYPES.ADMIN
        ],
        featureFlags: [VAC_CLIENT],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        route: "/user/list"
    }
];

// Subnav menu items for Analytics, const name "analytics" should correspond to slug of mainNavList
const analytics = [
    {
        displayname: "User Growth",
        slug: "usergrowth",
        roles: [USER_TYPES.ADMIN],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        route: "/admin/analytics/users"
    },
    {
        displayname: "Org Growth",
        slug: "orggrowth",
        roles: [USER_TYPES.ADMIN],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        route: "/admin/analytics/orgs"
    },
    {
        displayname: "Subscription Growth",
        slug: "subgrowth",
        roles: [USER_TYPES.ADMIN],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        route: "/admin/analytics/subs"
    },
    {
        displayname: "All Subscriptions",
        slug: "allsubscriptions",
        roles: [USER_TYPES.ADMIN],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        route: "/admin/analytics/subscriptions-list"
    }
];

const displayNavMenuVAC = isLoggedIn =>[
    {
        displayname: "Explore",
        airaLabelText: "Explore",
        slug: "explore",
        roles: [
            USER_TYPES.USER,
            USER_TYPES.ADMIN
        ],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        show: () => true // for all kind of users this will be visible
    },
    {
        displayname: "Catalog",
        airaLabelText: "Catalog",
        slug: "catalog",
        roles: [
            USER_TYPES.USER,
            USER_TYPES.ADMIN
        ],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        featureFlags: [SERVICE_CATALOG_CLIENT_V2]
    },
    {
        displayname: "Manage",
        airaLabelText: "Manage",
        slug: "manage",
        roles: [
            USER_TYPES.USER,
            USER_TYPES.ADMIN
        ],
        permissions: [PERMISSIONS.GENERAL_PERMISSION]
    },
    {
        displayname: "Partners",
        airaLabelText: "Partners",
        slug: "partners",
        roles: [USER_TYPES.USER],
        permissions: [PERMISSIONS.GENERAL_PERMISSION]
    },
    {
        displayname: "Insights",
        airaLabelText: "Insights",
        slug: "insights",
        roles: [
            USER_TYPES.USER,
            USER_TYPES.ADMIN
        ],
        permissions: [PERMISSIONS.GENERAL_PERMISSION]
    },
    {
        displayname: "Settings",
        airaLabelText: "Settings",
        slug: "settings",
        roles: [
            USER_TYPES.USER,
            USER_TYPES.ADMIN
        ],
        permissions: [PERMISSIONS.GENERAL_PERMISSION]
    }
];

// Subnav menu items for View, const name "view" should correspond to slug of mainNavList
const explore = [
    {
        displayname: "VMware Marketplace",
        slug: "marketplace",
        roles: [
            USER_TYPES.USER,
            USER_TYPES.ADMIN
        ],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        classes: "heading-subnav-button",
        type: "header",
        show: ()=>true // for all kind of users this will be visible
    },
    {
        displayname: "Solutions",
        slug: "solutions",
        roles: [USER_TYPES.USER],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        route: "/services",
        show: ()=> true // for all kind of users this will be visible
    }
];

// Subnav menu items for Partners, const name "partners" should correspond to slug of mainNavList
const partners = [
    {
        displayname: "Enroll",
        slug: "enroll",
        roles: [USER_TYPES.USER],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        classes: "heading-subnav-button",
        type: "header"
    },
    {
        displayname: "Become a Partner",
        slug: "becomeaPartner",
        roles: [USER_TYPES.USER],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        route: "/publisher-onboarding",
        show: ()=> !(get(user, "haspublisherpem", false) && get(user, "orgglobalpublish", false))
    },
    {
        displayname: <div className="enrolled-section-wrapper"><CdsIcon shape="check-circle" size="lg" status="success" /><span>Enrolled in Technology Alliance Partner Program</span></div>,
        airaLabelText: "Enrolled in Technology Alliance Partner Program",
        slug: "enrolled",
        roles: [USER_TYPES.USER],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        show: ()=> get(user, "haspublisherpem", false) && get(user, "orgglobalpublish", false),
        classes: "display-item-subnav enroll-element-wrapper"
    },
    {
        displayname: "Publish",
        slug: "publish",
        roles: [USER_TYPES.USER],
        permissions: [PERMISSIONS.ORG_PUBLISH_ENABLED],
        classes: "heading-subnav-button",
        type: "header"
    },
    {
        displayname: "Solutions",
        slug: "solutions",
        roles: [USER_TYPES.USER],
        permissions: [PERMISSIONS.ORG_PUBLISH_ENABLED],
        route: "/services/management"
    },
    {
        displayname: "Edit Publisher Name",
        slug: "editPublisherName",
        roles: [USER_TYPES.USER],
        permissions: [PERMISSIONS.ORG_PUBLISH_ENABLED],
        route: "/user/org/settings"
    }
];

const insights = [
    {
        displayname: "Analytics",
        slug: "analytics",
        roles: [
            USER_TYPES.USER,
            USER_TYPES.ADMIN
        ],
        route: checkIsAdminRole() ? null : "/analytics/subscription",
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        classes:  checkIsAdminRole() ? "heading-subnav-button" : null
    },
    {
        displayname: "User Growth",
        slug: "usergrowth",
        roles: [USER_TYPES.ADMIN],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        route: "/admin/analytics/users"
    },
    {
        displayname: "Org Growth",
        slug: "orggrowth",
        roles: [USER_TYPES.ADMIN],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        route: "/admin/analytics/orgs"
    },
    {
        displayname: "Subscription Growth",
        slug: "subgrowth",
        roles: [USER_TYPES.ADMIN],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        route: "/admin/analytics/subs"
    },
    {
        displayname: "All Subscriptions",
        slug: "allsubscriptions",
        roles: [USER_TYPES.ADMIN],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        route: "/admin/analytics/subscriptions-list"
    },
    {
        displayname: "Solution Downloads",
        slug: "solutiondownloads",
        roles: [USER_TYPES.ADMIN],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        route: "/admin/analytics/solution-reports"
    },
    {
        displayname: "Leads",
        slug: "leads",
        roles: [USER_TYPES.ADMIN],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        classes: "heading-subnav-button",
        type: "header"
    },
    {
        displayname: "Leads",
        slug: "leads",
        roles: [
            USER_TYPES.USER,
            USER_TYPES.ADMIN
        ],
        permissions: [PERMISSIONS.ORG_PUBLISH_ENABLED],
        route: "/leads" 
    }
];

const catalog = ffClient => {
    const isSvcCatalogEnabled = getFeatureFlagValue(ffClient, SERVICE_CATALOG_CLIENT_V2);
    
    return [
        {
            displayname: "VMware Marketplace",
            slug: "vmwareMarketplace",
            roles: [USER_TYPES.USER],
            permissions: [PERMISSIONS.GENERAL_PERMISSION],
            show: () => isSvcCatalogEnabled,
            classes: "heading-subnav-button",
            type: "header"
        },
        {
            displayname: "Service Catalog",
            slug: "servicecatalog",
            roles: [USER_TYPES.USER],
            permissions: [PERMISSIONS.GENERAL_PERMISSION],
            show: () => isSvcCatalogEnabled,
            route: "/servicecatalog"
        }
    // TODO: Not needed for now, untill further confirmation.
    // {
    //     displayname: "Automation Center",
    //     slug: "automationcenter",
    //     roles: [USER_TYPES.USER],
    //     permissions: [PERMISSIONS.ORG_PUBLISH_ENABLED],
    //     route: "/services/management"
    // }
    ];
};

//TODO: configure pages need to be routed
const configure = (ffClient, isStandaloneCustomer, isAdvancedCustomer) => [
    {
        displayname: "VMware Application Catalog",
        slug: "vmwareapplicationcatalog",
        roles: [
            USER_TYPES.USER,
            USER_TYPES.ADMIN
        ],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        classes: "heading-subnav-button",
        type: "header"
    },
    {
        displayname: "Registries",
        slug: "registries",
        roles: [
            USER_TYPES.USER,
            USER_TYPES.ADMIN
        ],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        show: () => isStandaloneCustomer || isAdvancedCustomer,
        route: "/registries"
    },
    {
        displayname: "Base Images",
        slug: "baseImages",
        roles: [
            USER_TYPES.USER,
            USER_TYPES.ADMIN
        ],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        route: "/base-images"
    },
    {
        displayname: "Build a Catalog",
        slug: "build a catalog",
        roles: [
            USER_TYPES.USER,
            USER_TYPES.ADMIN
        ],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        classes: "heading-subnav-button",
        type: "header",
        show: () => isStandaloneCustomer
    },
    {
        displayname: "Request Applications",
        slug: "build a catalog",
        roles: [
            USER_TYPES.USER,
            USER_TYPES.ADMIN
        ],
        permissions: [PERMISSIONS.GENERAL_PERMISSION],
        show: () => isStandaloneCustomer,
        route: "/applicationcatalog/requests"
    }
];

const needExactRouteCheck=["/services/management"];

export {
    mainNavList,
    view,
    publish,
    manage,
    settings,
    analytics,
    displayNavMenuVAC,
    explore,
    partners,
    insights,
    needExactRouteCheck,
    configure,
    catalog
};
