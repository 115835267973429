import { tokenHelper } from "../services/helpers/tokenHelper";
import { authHelper } from "../helpers/authHelper";
import {get} from "../helpers";
import { getUserType } from "../services/helpers/httpHelpers";
import { checkOrgOwner, userHasVACAccess } from "../services/helpers";
import { CspFeatureFlagClient } from "@vmw/csp-ff-sdk";
const config = require(`../config/config-files/${process.env.REACT_APP_CONFIG_ENV}.json`);
const featureFlagSdkKey = get(config, "featureFlagSDKKey", "");

class AppAuthManager {

    constructor(){
        this.authenticated = false;
        this.authCheckInProgress = true;
        this.isAdmin = false;
        this.isOrgOwner = false;
        window.addEventListener("storage", this.sessionStorageTransfer, false);
        this.featureFlagClientObject = null;
        this.sessionTokensPromise = null;
        this.hasVACAccess = false;
        this.initGetData();
    }

    initGetData = ()=> (
        this.setSessionTokens().then(this.tokenTransferComplete)
    );

    getFeatureFlagObject = ()=> {
        const CSP_FF_SDK_KEY = featureFlagSdkKey;
        if(this.featureFlagClientObject !== null) Promise.resolve(this.featureFlagClientObject);
        return CspFeatureFlagClient.initialize({
            sdkKey: CSP_FF_SDK_KEY,
            token: tokenHelper.getTokenByName("csp-auth-token")
        }).then( res => {
            this.featureFlagClientObject = res;
            return this.featureFlagClientObject;
        });
    }

    tokenTransferComplete = ()=> {
        this.validateUser();
        return {
            authenticated: this.authenticated,
            isAdmin: this.isAdmin,
            isOrgOwner: this.isOrgOwner,
            featureFlagClientObject: this.featureFlagClientObject,
            hasVACAccess: this.hasVACAccess
        };
    }

    validateUser = () => {
        const noAccessPath = this.checkNoAccessPath();
        const validUser = authHelper.validateUser(tokenHelper);
        this.authenticated = (validUser && !noAccessPath);
        this.isAdmin = (validUser && getUserType() === "marketplaceadmin");
        this.isOrgOwner = checkOrgOwner();
        this.hasVACAccess = userHasVACAccess();
    }

    checkNoAccessPath = () => {
        const path = window.location.href;
        if (path.includes("no-access")) return true;

        return false;
    }


    // Method to transfer session storage between tabs.
    sessionStorageTransfer = event => {
        if (!event.newValue) return;          // do nothing if no value to work with
        const hasAuthToken = sessionStorage.getItem("csp-auth-token");
        if (event.key === "getSessionStorage") {
            // another tab asked for the sessionStorage -> send it only if token is not expired
            if (tokenHelper.isTokenExpired()) {
                return;
            }
            localStorage.setItem("sessionStorage", JSON.stringify(sessionStorage));
            // the other tab should now have it, so we're done with it.
            setTimeout(() => {
                localStorage.removeItem("sessionStorage");
            }, 10);
        } else if (event.key === "sessionStorage" && !hasAuthToken) {
            // another tab sent data <- get it
            const data = JSON.parse(event.newValue);
            for (const key in data) {
                sessionStorage.setItem(key, data[key]);
            }
            this.getFeatureFlagObject(this.sessionTokensPromise);
        }
    }

    setSessionTokens = () => new Promise((resolve, reject) => {
        try {
            const hasAuthToken = sessionStorage.getItem("csp-auth-token");
            if (hasAuthToken || !(localStorage && localStorage.length)) {
                this.getFeatureFlagObject().then(resolve);
            } else {
                localStorage.setItem("getSessionStorage", "tokentData");
                localStorage.removeItem("getSessionStorage", "tokentData");

                // Resolve after 200 milliseconds to avoid any blocking.
                this.sessionTokensPromise = resolve;
                setTimeout(() => {
                    this.getFeatureFlagObject().then(resolve);
                }, 200);
            }
        } catch(e) {
            reject(e);
        }
    })
}

export default AppAuthManager;
