import { ADD_TOP_ALERT, CLOSE_TOP_ALERT, FLUSH_TOP_ALERTS } from "./actions";
import initialState from "./initialState";

const alertReducer = (state= initialState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
    case ADD_TOP_ALERT: {
        const currTime = Date.now();
        /**
         * An alert will be pushed if 
         *  - It is allowed to show the alert multiple time.  Or
         *  - It is not duplicate
         */
        return payload.allowMultiple || !state.alerts.some(item => (item.message === payload.message && item.type === payload.type)) ?
            {
                ...state,
                alerts: [
                    {
                        ...payload, 
                        id: `top_alert_${currTime}`,
                        type: payload.type || "info",
                        closable: payload.closable === true,
                        createdAt : currTime
                    },
                    ...state.alerts
                ]
            } :
            state;
    }
    case CLOSE_TOP_ALERT: {
        const {alerts} = {...state};
        const filteredAlert = alerts.filter(alert => alert.id !== payload);
        return {
            ...state,
            alerts: filteredAlert
        };
    }
    
    case FLUSH_TOP_ALERTS: {
        return {
            ...state,
            alerts: []
        };
    }
    default:
        return state;
    }
};

export default alertReducer;