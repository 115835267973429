import LocalStorageHelper from "../../../services/helpers/LocalStorageHelper";

function getAcceptLaterKey(orgType) {
    const currentOrgId = LocalStorageHelper.get("current_org");
    return `acceptLater:${currentOrgId}:${orgType}`;
}

export const getAcceptLaterStatus = orgType => !!LocalStorageHelper.get(getAcceptLaterKey(orgType));

export const removeAcceptLater = orgType => {
    LocalStorageHelper.remove(getAcceptLaterKey(orgType));
};

export const addAcceptLater = orgType => {
    LocalStorageHelper.save(getAcceptLaterKey(orgType), true);
};

