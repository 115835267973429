import React, {useState} from "react";
import PropTypes from "prop-types";
import {Dropdown, DropdownToggle, DropdownMenu} from "@vmware-react/clarity/dist/dropdown";

DropDownMain.propTypes = {
    displayName: PropTypes.string.isRequired
};

/*
    DropDownMain component for rendering toplevel nav items and handling top level open and close state.
    It accepts two props:
    displayName: the text to be displayed on top level nav item.
    children: the sub list which will be shown when you click on menu item.
    We will iterate the children and attach one prop: onToggleClick, 
    so when we click on any subNav item, we will close the main menu. 
*/
function DropDownMain(props) {
    const {children, displayName, displayClassName} = props;

    const [
        dropdownState,
        setDropdownState
    ] = useState("closed");

    const onToggleClick = state => setDropdownState(state === "closed" ? "open" : "closed");
    const onDismiss = () => setDropdownState("closed");

    return (
        <Dropdown
            state={dropdownState}
            onToggleClick={onToggleClick}
            onDismissDropdown={onDismiss}
        >
            <DropdownToggle state={dropdownState} aria-label={displayName}>
                <div className={displayClassName}>{displayName}</div>
                <clr-icon shape="caret down" />
            </DropdownToggle>
            <DropdownMenu>
                {
                    React.Children.map(children, child => React.cloneElement(child, {onToggleClick}))
                }
            </DropdownMenu>
        </Dropdown>
    );
}

export default DropDownMain;