const urlConfig = {
    vmwareUrl: "https://vmware.com",
    cookieUsageUrl: "https://www.vmware.com/help/privacy.html",
    accessUrl: "https://console.cloud.vmware.com",
    knowlegdeBaseUrl: "https://kb.vmware.com/s/article/83847",
    landingPageUrl: "https://cloud.vmware.com/cloud-marketplace",
    peripheralUrl: "https://code.vmware.com/programs/vmware-horizon-partner-peripherals-program",
    awsSecurityOverviewUrl: "https://www.vmware.com/content/dam/digitalmarketing/vmware/en/pdf/products/cloud-services/vmware-cloud-services-on-aws-security-overview-white-paper.pdf",
    encryptionGuidancePolicyUrl: "https://www.bis.doc.gov/index.php/policy-guidance/encryption" 
};

export default urlConfig;