import React, { Component } from "react";
import ErrorPage from "./ErrorPage";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);

        this.state = { hasError: false };
    }

    componentDidCatch(err, info) {
        // Display fallback UI
        this.setState({ hasError: true });
        console.error(err, info);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <ErrorPage
                code="500"
                title="Something is broken."
                description="Something is not right please refresh and try again"
            />;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
