import { isEmpty } from "../helpers";
import APP_CONFIG from "../config/app";
import LocalStorageHelper from "../services/helpers/LocalStorageHelper";

const CURRENT_ENV = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : "prod";
const CURRENT_CONFIG = APP_CONFIG.SSO[CURRENT_ENV.toLocaleUpperCase()];
class authHelperClass {

    validateOrg(orgLink, currentOrgId) {
        return (orgLink.includes(currentOrgId));
    }

    //TODO: check if tokens are even passes. Else throw error.
    isSsoRequired(authToken, refreshToken) {

        if (isEmpty(authToken)) {
            if (isEmpty(refreshToken)) {
                return true;
            }
        }

        return false;
    }

    redirectToSSO(redirectUrl = null) {

        const { CSP_HOST_STG, DISCOVERY_URL, CLIENT_ID, REDIRECT_URL} = CURRENT_CONFIG;
        const params = (new URL(document.location)).searchParams;
        const redirectBaseUrl = redirectUrl ? redirectUrl : window.location.pathname;
        const currentUrl = isEmpty(window.location.search) ? redirectBaseUrl : `${redirectBaseUrl}${window.location.search}`;
        let orgLink = params.get("orgLink");
        let orgId = params.get("orgId");
        LocalStorageHelper.save("redirectUrl", currentUrl);

        if (orgLink && orgLink.length > 0) {
            orgLink = "&orgLink=" + orgLink;
        }

        if(orgLink === null) {
            orgLink = "";

            if(!isEmpty(orgId)) {
                orgLink = `&orgLink=/csp/gateway/am/api/orgs/${orgId}`;
            }
        }

        window.location = `${CSP_HOST_STG}${DISCOVERY_URL}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URL}${orgLink}&state=${currentUrl}`;
    }

    redirectToSSOWithOrgId(orgId) {
        const { CSP_HOST_STG, DISCOVERY_URL, CLIENT_ID, REDIRECT_URL} = CURRENT_CONFIG;
        const orgLink = `&orgLink=/csp/gateway/am/api/orgs/${orgId}`;
        const redirectBaseUrl = window.location.pathname;
        let currentUrl = isEmpty(window.location.search) ? redirectBaseUrl : `${redirectBaseUrl}${window.location.search}`;
        const redirectUrl = LocalStorageHelper.get("redirectUrl");
        currentUrl = redirectUrl ? redirectUrl : currentUrl;

        window.location = `${CSP_HOST_STG}${DISCOVERY_URL}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URL}${orgLink}&state=${currentUrl}`;
    }

    redirectUser(primary, slug="/", options = "") {

        let params = "";

        if(options !== "") {
            params = `?=options=${options}`;
        }
        window.location = `${primary}${slug}${params}`;
    }

    redirectUserToLogout(primary) {
        window.location = `${primary}/no-access?passon=true`;
    }

    validateUser(tokenHelper) {
        const refreshToken = tokenHelper.getRefreshToken();
        const authToken = tokenHelper.getAuthToken();
        const tokenExpired = tokenHelper.isTokenExpired();
        const userData = tokenHelper.getTokenByName("user");
        const ssoRequired = authHelper.isSsoRequired(authToken, refreshToken);
        // const role = tokenHelper.getPermission(authToken, config.SERVICE_ID);

        //JSON.parse throw expection if passed string is not a valid JSON.
        //This will verify if User data is a valid JSON and return false if not.
        try {
            JSON.parse(userData);
        } catch(e) {
            return false;
        }

        if (ssoRequired) {
            return false;
        } else {
            if (tokenExpired) {
                return false;
            } else {
                return true;
            }
        }
    }
}

const authHelper = new authHelperClass();
export { authHelper };

