import React from "react";
import PropTypes from "prop-types";
import Empty from "../Empty";
import Button from "../Button";
import { warningStandardIcon } from "@cds/core/icon";

const ErrorMessagePlaceholder = ({
    id,
    children,
    title,
    buttonText,
    onClickHandler
}) => {
    const titleId = `${id}-title`;

    return (
        <Empty
            labelledBy={titleId}
            icon="warning-standard"
            iconShape={warningStandardIcon}
            small
        >
            <h4 id={titleId}>{title}</h4>
            {children}
            {onClickHandler ? (
                <p>
                    <Button onClick={onClickHandler}>{buttonText}</Button>
                </p>
            ) : null}
        </Empty>
    );
};

ErrorMessagePlaceholder.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    onClickHandler: PropTypes.func
};

ErrorMessagePlaceholder.defaultProps = {
    buttonText: "Reload",
    onClickHandler: null
};

export default ErrorMessagePlaceholder;
