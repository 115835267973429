/* eslint-disable eqeqeq */
import { createSelector } from "reselect";
import createCachedSelector from "re-reselect";

import {
    BECS_PERMISSIONS_USER,
    CSP_PERMISSIONS_OWNER
} from "../common/constants/permissions";

import {
    AND_OPERATOR,
    OR_OPERATOR,
    TAC_ADV_PRODUCT_ID,
    TAC_STANDALONE_ORG_PRODUCT_ID,
    TAC_STANDALONE_TEAM_PRODUCT_ID,
    VAC_FLEXIBLE_PRODUCT_ID
} from "../constants/user";

const profile = state => state.profile;
const userFirstName = state => state.profile.first_name;
const userLastName = state => state.profile.last_name;
const sessionToken = state => state.session.token;
const refreshToken = state => state.session.refreshToken;
const tokenSignedInAt = state => state.session.signedInAt;
const permissions = state => state.context.perms;
const currentOrg = state => state.context.org_id;
const serviceId = state => state.context.service_id;
const orgs = state => state.profile.orgs;
const loading = state => state.loading;
const subscriptions = state => state.subscriptions;
const subscriptionsConsumption = state => state.subscriptions_consumption;
const flags = state => state.flags;
const processingOnboarding = state => state.processing_onboarding;
const loggedOut = state => state.session.loggedOut;

export const isAuthenticated = createSelector(
    sessionToken,
    token => !!token
);

export const isLoggedOut = createSelector(
    loggedOut,
    loggedOut => loggedOut === true
);

export const isLoading = createSelector(
    loading,
    loading => loading
);

export const isProcessingOnboarding = createSelector(
    processingOnboarding,
    processingOnboarding => processingOnboarding === true
);

export const getSessionToken = createSelector(
    sessionToken,
    token => token
);

export const getRefreshToken = createSelector(
    refreshToken,
    token => token
);

export const getProfile = createSelector(
    profile,
    profile => profile
);

export const getProfileDisplayName = createSelector(
    userFirstName,
    userLastName,
    (userFirstName, userLastName) => {
        if (!userFirstName) {
            return null;
        }

        return userLastName == null
            ? userFirstName
            : `${userFirstName} ${userLastName}`;
    }
);

export const getCurrentOrg = createSelector(
    currentOrg,
    orgs,
    (currentOrg, orgs) => {
        if (!orgs) {
            return null;
        }

        return orgs.find(org => org.org_id === currentOrg) || null;
    }
);

export const getCurrentSubscriptionsConsumption = createSelector(
    subscriptionsConsumption,
    subscriptionsConsumption => subscriptionsConsumption
);

export const isCurrentOrganizationPresent = createSelector(
    getCurrentOrg,
    org => !!org
);

// We need to use createCachedSelector because we're caching based on a parameter
// we pass to the selector: permission.
const _hasPermissions = createCachedSelector(
    permissions,
    isCurrentOrganizationPresent,
    serviceId,
    (_state, permission) => permission,
    (permissions, isOrganizationPresent, serviceId, permission) =>
        permissions &&
    permissions.some(e =>
        e.startsWith("csp:")
            ? e.includes(permission)
            : new RegExp(`external/${serviceId}/${permission}$`).test(e)
    ) &&
    isOrganizationPresent
)((_state, permission) => permission);

/**
 * Checks if the permissions to check are matched using the given operation in the roles
 * stored in the state.
 * @param state Redux's state
 * @param guardPermissions List of permissions that needs to match within the state's permissions
 #        for the given operator
 * @param operator The logic operation to be applied with the state's permissions and
 #        guardPermissions. Available operators: AND or OR, default AND.
 * @returns {boolean} True if operation successful, false otherwise
 */
export const hasPermissions = (
    state,
    guardPermissions = [],
    operator = AND_OPERATOR
) => {
    if (!guardPermissions.length) {
        return false;
    }
    switch (operator) {
    case OR_OPERATOR:
        return guardPermissions.some(perm => _hasPermissions(state, perm));
    default:
        return guardPermissions.every(perm => _hasPermissions(state, perm));
    }
};

// Reuse hasPermissions
export const isGalleonUser = state =>
    hasPermissions(state, [BECS_PERMISSIONS_USER]);

export const getServiceId = createSelector(
    serviceId,
    sid => sid
);

export const getCurrentOrgName = createSelector(
    getCurrentOrg,
    currentOrg => currentOrg ? currentOrg.display_name : null
);

export const getOrganizations = createSelector(
    orgs,
    orgs => orgs || []
);

/**
 * Checks if the current signed token is expired. Meaning 30 min have passed since its
 * signing.
 */
export const isTokenExpired = createSelector(
    tokenSignedInAt,
    signedInAt => {
        if (signedInAt === null) {
            return true;
        }
        // 1800 * 1000 = 30 min in ms
        // Add offset of 2 seconds at least for Date.now()
        return !(
            Date.parse(signedInAt).valueOf() + 1800 * 1000 >
      Date.now() + 2000
        );
    }
);

/**
 * Checks whether the current org has a TAC Adv subscription
 * Note that this is not yet checking if the subscription is ACTIVE or not
 */
export const isTACForAdvCustomer = createSelector(
    subscriptions,
    subscriptions =>
        subscriptions &&
    subscriptions.some(s => s.product_id == TAC_ADV_PRODUCT_ID && s.id != null)
);

/**
 * Checks whether the current org has a TAC Adv subscription
 * Note that this is not yet checking if the subscription is ACTIVE or not
 */
export const isTACStandaloneCustomer = createSelector(
    subscriptions,
    subscriptions =>
        subscriptions &&
    subscriptions.some(
        s =>
            s.product_id === TAC_STANDALONE_TEAM_PRODUCT_ID ||
        s.product_id === TAC_STANDALONE_ORG_PRODUCT_ID ||
        s.product_id === VAC_FLEXIBLE_PRODUCT_ID
    )
);

/**
 * Checks whether the current org has a TAC Adv subscription
 * Note that this is not yet checking if the subscription is ACTIVE or not
 */
export const isTACForAdvTrial = createSelector(
    subscriptions,
    subscriptions =>
        subscriptions &&
    subscriptions.some(s => s.product_id === TAC_ADV_PRODUCT_ID && s.id == null)
);

/**
 * Checks whether the current org is a TAC for ADV customer or is on a trial
 */
export const isTACForAdvUser = createSelector(
    isTACForAdvCustomer,
    isTACForAdvTrial,
    (customer, trial) => customer || trial
);

export const isReturningUser = createSelector(
    flags,
    flags => flags && flags.returning_user
);

/**
 * Checks if the organization has bandwidth to add more ActiveArtifacts
 * to the subscriptions. It also can check the new bandwidth with prospective
 * new ActiveArtifacts by passing a newCurrent parameter
 */
export const hasActiveArtifactsAvailable = createCachedSelector(
    subscriptionsConsumption,
    (_state, newCurrent = 0) => newCurrent,
    ({ limit, current }, newCurrent) => {
        if ((limit === 0 && current === 0) || newCurrent < 0) {
            return false;
        }

        return limit - (current + newCurrent) >= 0;
    }
)((_state, newCurrent = 0) => newCurrent);

/**
 * Checks if user has csp:org_owner permissions or not.
 */
export const userHasOrgOwnerPermissions = state =>
    hasPermissions(state, [CSP_PERMISSIONS_OWNER]);
