import propTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router";
import {CdsDivider} from "@cds/react/divider";
import { BaseComponent } from "../../framework/BaseComponent";
import { get, isEmpty } from "../../helpers";
import { getStaticMediaContentCDN } from "../../helpers/uiHelper";
import "./ProductSuggestionCard-dark.css";
import "./ProductSuggestionCard.css";


export class ProductSuggestionCard extends BaseComponent {

    static propTypes = {
        data: propTypes.object
    };

    constructor(props) {
        super(props);
        this.itemRef = React.createRef();
        this.onCardClick = this.onCardClick.bind(this);
    }

    componentDidUpdate(){
        if(this.props.active){
            this.itemRef.current.focus();
        }
    }

    onCardClick() {
        const to = `/services/details/${this.getProp("data.slug", "")}?slug=true`;
        this.props.onItemClick();
        this.props.history.push(to);
    }

    handleVSXProductClick() {
        const slug = get(this.props, "data.slug", "");
        const to = `https://marketplace.vmware.com/vsx/solutions/${slug}`;
        const win = window.open(to, "_blank");
        win.focus();
    }


    //We can use latestversion field for showing version
    //For Deployable solutions : This field will always have value if versions are defined.
    //For Listing solutions : Field will always be blank, which will lead to hiding version in search result.
    getVersion = () => {
        const version = this.getProp("data.latestversion", "");
        const isListingProduct = this.getProp("data.islistingproduct", false);

        return isListingProduct ? null : version;
    }

    renderProductDetails() {
        const orgname = this.getProp("data.publisherdetails.orgdisplayname");
        const version = this.getVersion();

        return (
            <p cds-text="caption" cds-layout="horizontal gap:md">
                { version && <p 
                    cds-text="caption" 
                    aria-label={`Version: ${version}`}
                > 
                    Version : {version}
                </p>}
                {
                    version && <CdsDivider className="details-divider" orientation="vertical"></CdsDivider>
                }
                <p 
                    cds-text="caption" 
                    aria-label={`Published by : ${orgname}.`}
                >
                    By: {orgname}
                </p>
            </p>
        );
    }

    renderDeploymentPlatformsIcons() {
        const platformsList = this.getProp("data.deploymentplatformsList", []);

        if (platformsList.length < 1) return null;

        const icons = platformsList.map(this.renderDeploymentIcon);
        const supportedPlatforms = platformsList.map(item => item.type).join(", ");
        return (<div aria-label={`Supported deployments on ${supportedPlatforms}.`}>{icons}</div>);
    }

    renderDeploymentIcon(element, index) {
        return (
            <img 
                alt={element.type}
                key={index}
                src={getStaticMediaContentCDN(`${element.type}_MINI.svg`)}
                className="deployment-platform-icon"
            />
        );
    }

    shouldRenderTape() {
        const pricing = this.getProp("data.productpricingList", []);

        if (pricing.length && pricing[0].subscriptiontype === "free") {
            return (
                <div className="product-card-tape">
                    <span className="label label-success">Free</span>
                </div>
            );
        }

        return null;
    }

    render() {
        const { data: { productlogo }, active, isLastItem, onKeyBoardNavigation } = this.props;
        const productLogoURL = get(productlogo, "url", null);
        const keyNavigation = e => {
            if (e.key === "Enter") {
                e.preventDefault();
                this.onCardClick();
            }else if ((isLastItem && !e.shiftKey ) || e.key==="Escape") {
                onKeyBoardNavigation(e);
            }
        };

        return (
            <div 
                tabIndex={0}
                className={`suggestion-container ${active? "suggestion-container-active": ""}`} 
                onClick={this.onCardClick}
                ref= {this.itemRef}
                onKeyDown={keyNavigation}
            >
                <div className="suggestion-logo-container">
                    <img
                        aria-hidden={true}
                        alt=" Suggestion Logo"
                        className="suggestion-logo"
                        src={!isEmpty(productLogoURL) ? productLogoURL : getStaticMediaContentCDN("product-logo-placeholder.png")}
                    />
                </div>
                <div className="suggestion-content-container" cds-layout="vertical gap:md">
                    <p cds-text="secondary" className="suggestion-product-name" aria-label={`Selected item is ${this.getProp("data.displayname")}.`}>
                        {this.getProp("data.displayname")}
                    </p>
                    {this.renderProductDetails()}
                    {this.renderDeploymentPlatformsIcons()}
                    {/* {this.shouldRenderTape()} */}
                </div>
            </div>
        );
    }

}

export default withRouter(ProductSuggestionCard);
