export const categories = [
    {
        label: "Identity and Access Management",
        value: "Identity and Access Management"
    },
    {
        label: "Publish Process",
        value: "Publish Process"
    },
    {
        label: "Platform Validation",
        value: "Platform Validation"
    },
    {
        label: "Marketplace Catalog and Solutions",
        value: "Marketplace Catalog and Solutions"
    },
    {
        label: "Commerce",
        value: "Commerce"
    },
    {
        label: "Others",
        value: "Others"
    }
];

export const typeOfFeedback = [
    {
        label: "Request to add a new solution",
        value: "Request to add a new solution"
    },
    {
        label: "Process improvement",
        value: "Process improvement"
    },
    {
        label: "User Interface improvement",
        value: "User Interface improvement"
    },
    {
        label: "API related",
        value: "API related"
    },
    {
        label: "Documentation",
        value: "Documentation"
    },
    {
        label: "Others",
        value: "Others"
    }
];
