// User permissions
export const BECS_PERMISSIONS_USER = "becs:user";
// TAC Admin base permission
export const BECS_ADMIN_BASE = "becs:admin";
// TAC Read Only Operator permission
export const BECS_PERMISSIONS_OPERATOR_READ_ONLY = BECS_ADMIN_BASE;
// TAC Billing Operator permission
export const BECS_PERMISSIONS_OPERATOR_BILLING = `${BECS_ADMIN_BASE}-billing`;
// TAC Catalog Operator permission
export const BECS_PERMISSIONS_OPERATOR_CATALOG = `${BECS_ADMIN_BASE}-catalog`;
// CSP Org owner permissions
export const CSP_PERMISSIONS_OWNER = "csp:org_owner";
