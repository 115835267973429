import React, { useRef, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Portal from "../Portal";
import { get } from "../../helpers";
import "./Tooltip.css";
import { isKeyboardActionTrigger } from "../../helpers/uiHelper";
export function Tooltip(props) {

    const { content, children, className, fixedPosition=false, offsetX = 0, offsetY = 0, disableTabIndex = false, onButtonClick, isSubscribe= false } = props;
    const [
        position,
        setPosition
    ] = useState(get(props, "position", "top-right"));

    const [
        showTooltip,
        setShowTooltip
    ] = useState(false);

    const tooltip = useRef();
    const parentRef = useRef();

    const [
        tooltipPos,
        setTooltipPos
    ] = useState({
        position: "absolute",
        top: 0,
        left: 0
    });
    
    const handlePosition = useCallback((posX = 0, posY = 0) => {
        if (fixedPosition) return;
        if(!tooltip.current) return;
        const rect = tooltip.current.getBoundingClientRect();
        let [
            y,
            x
        ] = position.split("-");

        if (y === "top" && rect.height < 100) {
            y = "bottom";
        }

        if (x === "left") {
            if (posX < 220) {
                x = "right";
            } else {
                x = "left";
            }
        } else if (x === "right") {
            if ((posX + rect.width) > window.innerWidth) {
                x = "left";
            } else {
                x = "right";
            }
        }

        const result = `${y}-${x}`;

        if (result !== position) {
            setPosition(result);
        }
    }, [
        fixedPosition,
        position
    ]);

    useEffect(() => {
        if(parentRef.current && showTooltip) {
            const {top, left, height, width} = parentRef.current.getBoundingClientRect();
            const positionX = Math.floor(left + width/2) + offsetX;
            const positionY = Math.floor(top + height) + offsetY;
            handlePosition(positionX, positionY);
            setTooltipPos({
                position: "absolute",
                top: positionY,
                left: positionX
            });
        }

        window.addEventListener("resize", handlePosition, true);
        return () => {
            window.removeEventListener("resize", handlePosition, true);
        };
    }, [
        handlePosition,
        showTooltip,
        offsetX,
        offsetY
    ]);


    const getClassName = (condition, property) => {
        if (condition) {
            return property;
        }

        return "";
    };

    const getBlock = data => {
        if (typeof data === "function") {
            return data();
        }

        return data;
    };

    const onScrollEventHandler = ()=> {
        setShowTooltip(false);
        document.removeEventListener("scroll", onScrollEventHandler, true);
    };

    const onShowTooltip = ()=> {
        setShowTooltip(true);
        document.addEventListener("scroll", onScrollEventHandler, true);
    };

    const onHideTooltip = ()=> {
        setShowTooltip(false);
        document.removeEventListener("scroll", onScrollEventHandler, true);
    };
    const handleKeyboardTooltip = e => {
        e.key=== "Escape" && setShowTooltip(false);
        if(isSubscribe && isKeyboardActionTrigger(e)) {
            onButtonClick();
        }
    };
    return (
        <span aria-label={`Tooltip, ${content}`} onBlur={() => !disableTabIndex && onHideTooltip()} tabIndex={disableTabIndex ? -1 : 0}  onFocus={() => !disableTabIndex && onShowTooltip()}  className="tool-tip-container" onMouseEnter={onShowTooltip} onMouseLeave={onHideTooltip} onKeyDown={handleKeyboardTooltip} ref={parentRef}>
            {children}
            {
                showTooltip &&
                <Portal>
                    <span style={tooltipPos} className={`tooltip ${getClassName(className, className)} tooltip-${position}`}>
                        <span ref={tooltip} className="tooltip-content custom-tooltip">
                            {getBlock(content)}
                        </span>
                    </span>
                </Portal>
            }
        </span>
    );
}

Tooltip.propTypes = {
    /**
     * Content for the tooltip
    */
    content: PropTypes.any,
    children: PropTypes.node,
    position: PropTypes.string,
    className: PropTypes.string,
    fixedPosition: PropTypes.bool
};

