import React from "react";
import PropTypes from "prop-types";
import SearchResultItem from "./SearchResultItem";
import { get } from "../../helpers";
import { CdsButton } from "@cds/react/button";

SearchResultView.propTypes = {
    list: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired
};

function SearchResultView(props) {
    const {list = [], count = 0, type, showAllClick, focusedItem, setFocusedItem, onKeyBoardNavigation} = props;
    
    return (
        <div className="search-result-wrapper">
            <div className="search-result-header">
                <div className="header-text" tabIndex="0" >Products ({count})</div>
                <CdsButton 
                    action="flat-inline" 
                    onClick={showAllClick} 
                    className="header-link"
                >
                    Show All
                </CdsButton>
            </div>
            <ul className="list-view" role="listbox">
                {
                    list.map((product, index) => (<li 
                        key={get(product, "productid", "")} 
                        role="option" 
                        aria-selected={focusedItem === index}
                        onFocus={() => setFocusedItem(index)}
                    >
                        <SearchResultItem 
                            data={product} type={type} isLastItem={index===list.length-1}
                            active={ focusedItem === index } 
                            onKeyBoardNavigation={onKeyBoardNavigation}
                        />
                    </li>))
                }
            </ul>
        </div>
    );
}

export default SearchResultView;