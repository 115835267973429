import LocalStorageHelper from "./LocalStorageHelper";
import { tokenHelper } from "./tokenHelper";
import { get } from "../../helpers";

const OWNER = "csp:org_owner";
const ROLE_ADMIN = "marketplaceadmin";

export const setCookieFromArray = cookieArray => {
    for (const cookie of cookieArray) {
        document.cookie = `${cookie.name}=${cookie.value}`;
    }
};

export const setLocalStorage = (name, value) => {
    localStorage.setItem(name, value);
};

export const getLocalStorageItem = name => localStorage.getItem(name);

export const getRole = () => getLocalStorageItem("current_permission");

export const getTheme = () => getLocalStorageItem("theme");

export const CheckLegalAgreementAccepted = key => {
    try {
        return JSON.parse(getLocalStorageItem(key));
    } catch {
        return null;
    }
};

export const checkRole = role => getRole() === role;

export const checkOrgOwner = () => tokenHelper.getOrgRolesFromAuthToken().includes(OWNER);

export const checkAgreementRequired = () =>  LocalStorageHelper.getFromObj("user", "agreementrequired");

export const checkIsAdminRole = () => checkRole(ROLE_ADMIN);

export const checkIsPublisher = productOrgId => {
    const currentOrgId = LocalStorageHelper.get("current_org");
    return currentOrgId === productOrgId;
};

export const setLocalStorageFromArray = data => {
    for (const storageData of data) {
        setLocalStorage(storageData.name, storageData.value);
    }
};

export const isServiceMgmtPage = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return !!(urlParams && urlParams.get("serviceMgmt"));
};
export const checkIfPublisherOrAdmin = orgid => (checkIsAdminRole() || checkIsPublisher(orgid)) && isServiceMgmtPage();

export const serialize = obj => {
    let str = [];
    for (let p in obj)
        if (Object.prototype.hasOwnProperty.call(obj, p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
};

/**
 * @param  {*} object
 * @return {boolean}
 */
export function isObject(object) {
    return object && !isArray(object) && typeof object === "object";
}

/**
 * @param  {*} array
 * @return {boolean}
 */
export function isArray(array) {
    return array && typeof array === "object" && Array.isArray(array);
}

export function isEmpty(value) {
    return value === null ||
        value === undefined ||
        value === "" ||
        (isArray(value) && !value.length) ||
        (isObject(value) && !Object.keys(value).length);
}

export function isGlobalPublishAllowed() {
    const userData = LocalStorageHelper.get("user");
    return (checkIsAdminRole() || get(userData, "orgglobalpublish", false));
}

export function isPublisher() {
    const userObj = window.localStorage.getItem("user");
    let user = null;
    // Need to add try/catch to handle invalid JSON object
    try {
        user = JSON.parse(userObj);
    } catch(e) {
        console.error(e);
        return false;
    }

    return get(user, "haspublisherpem", false);
}

export const isSuperAdminUser = () => LocalStorageHelper.getFromObj("user", "issuperadminuser");

export const hasTapsfdcid = () => LocalStorageHelper.getFromObj("user", "tapsfdcid");

export const hasPermission = permSlug => {
    const permissionsList = LocalStorageHelper.getFromObj("user", "permissionsList");

    return !isEmpty(permissionsList) && permissionsList.some(item => item.slug === permSlug);
};

export const hasAccessToPublish = () => {
    const userObj = window.localStorage.getItem("user");
    let user = {};
    try {
        user = userObj ? JSON.parse(userObj) : {};
    } catch(e) {
        user = {};
    }

    return isPublisher() && get(user, "orgglobalpublish", false);
};

export const userHasVACAccess = () =>  {
    const hasVACAccess = tokenHelper.getTokenFromStorageByName("hasVACAccess");
    //Had to do this conditional check becasue getTokenFromStorageByName return string value.
    if(typeof hasVACAccess === "boolean"){
        return hasVACAccess;
    }
    return (typeof hasVACAccess === "string" && hasVACAccess === "true");
};