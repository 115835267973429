import React, { Component } from "react";
import SubNav from "../components/SubNav";
import { authHelper } from "../helpers/authHelper";
import H from "../components/CSPHeader";
import Mainbody from "./Mainbody.js";
import { tokenHelper } from "../services/helpers/tokenHelper";
import PageLoader from "../components/PageLoader";
import AppAuthManager from "./AppAuthManager";
import Footer from "../components/Footer";
import {get, getFeatureFlagValue, isEmpty} from "../helpers";
import LocalStorageHelper from "../services/helpers/LocalStorageHelper.js";
import AutoScrollToTop from "../components/AutoScrollToTop";
import { vacContext, vacInitState } from "../appContext";
import { AlertContextProvider, initialState } from "../context/alerts";
import AlertManager from "../components/AlertManager/AlertManager";
import FeatureFlagsProvider from "./VACView/contexts/FeatureFlagsContext";
import BrandSeparator from "../components/BrandSeparator/BrandSeparator";
import { getInitialAlertState } from "../context/alerts/initialState.js";

const config = require(`../config/config-files/${process.env.REACT_APP_CONFIG_ENV}.json`);
const HOME_PAGE_V2_FEATURE_FLAG = get(config, "CSP_FEATURE_FLAGS.HOME_PAGE_V2", "");
class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            authenticated : false,
            authCheckInProgress : true,
            isAdmin : false,
            isOrgOwner : false,
            footerVisible: true,
            navInlineSearchVisible: false,
            featureFlagClientObject: null,
            hasVACAccess: false,
            appAuth: new AppAuthManager(),
            vacStore: vacInitState
        };
        this.headerRef = React.createRef();
    }

    checkNoAccessPath = () => {
        const path = window.location.href;

        if (path.includes("no-access")) return true;

        return false;
    }

    checkOrgLink = orgLink => {
        if (!isEmpty(orgLink)) {
            const orgId = tokenHelper.getDataFromAuthToken("org");
            let isOrgValid = authHelper.validateOrg(orgLink, orgId);

            if (!isOrgValid) {
                tokenHelper.clearAllTokens();
                this.attemptSSO();
            }
        }
    }

    attemptSSO = () => {
        authHelper.redirectToSSO();
    }

    getUrlParams = () => {
        const params = (new URL(document.location)).searchParams;
        const code = params.get("code");
        const passOn = params.get("passon");
        const orgLink = params.get("orgLink") || params.get("org_link");
        const orgId = params.get("orgId");
        const contextId = params.get("onboardingContextId");

        return {
            code,
            passOn,
            orgLink,
            orgId,
            contextId
        };
    }

    checkContextId = (contextId, orgId) => {
        if (!isEmpty(contextId) && !isEmpty(orgId)) {
            tokenHelper.clearAllTokens();
            this.attemptSSO();
        }
    }

    componentDidMount() {
        const {appAuth} = this.state;
        let isOnboardingFlow = false;
        const { code, passOn, orgLink, orgId, contextId } = this.getUrlParams();
        //If user is coming from CSP Onboarding flow redirect them for SSO
        //TODO: build way to passon org ID
        this.checkContextId(contextId, orgId);

        if (!isEmpty(code) || !isEmpty(passOn)) {
            this.setState({
                authCheckInProgress: false
            });
            isOnboardingFlow = true;
        }
        window.localStorage.setItem("orgName", LocalStorageHelper.getFromObj("user", "orgname"));
        if(!isOnboardingFlow){
            appAuth.initGetData().then(initAuthData => {
                this.setState({
                    ...initAuthData,
                    authCheckInProgress: false
                });
            });
        }

        this.checkOrgLink(orgLink);
    }

    setFooterVisibility = visible => {
        this.setState({footerVisible: visible});
    }
    // get height for main content = full viewport height - top header section height
    get containerHeight() {
        let mainBodyHeight = "calc(100vh - 96px)";
        if(this.headerRef.current){
            mainBodyHeight = `calc(100vh - ${this.headerRef.current.offsetHeight}px)`;
        }

        return mainBodyHeight;
    }

    setNavInlineSearchVisibility = visible => this.setState({navInlineSearchVisible: visible});

    updateInfo = updatedState => {
        const { vacStore } = this.state;
        this.setState({
            vacStore: {
                ...vacStore,
                ...updatedState
            }
        });
    };

    render () {

        const { authenticated, authCheckInProgress, isAdmin, isOrgOwner, footerVisible, featureFlagClientObject, navInlineSearchVisible, hasVACAccess, vacStore } = this.state;
        const isAuthValid = authenticated && !authCheckInProgress;

        if(authCheckInProgress) {
            return (
                <PageLoader />
            );
        }
        const isNewHomePage = getFeatureFlagValue(featureFlagClientObject, HOME_PAGE_V2_FEATURE_FLAG);
        return (
            <vacContext.Provider value={{store: vacStore, updateInfo: this.updateInfo  }}>
                <FeatureFlagsProvider>
                    <AlertContextProvider initialState={getInitialAlertState(featureFlagClientObject)}>
                        <div role="banner" className="sticky-top" ref={this.headerRef}>
                            <AlertManager/>
                            {
                                !authCheckInProgress ?
                                    <H isAuthValid={isAuthValid} /> :
                                    null
                            }
                            { isNewHomePage && <BrandSeparator /> }
                            <SubNav isLoggedIn={isAuthValid} navInlineSearchVisible={navInlineSearchVisible} ffClient={featureFlagClientObject} />
                        </div>
                        <AutoScrollToTop targetId="main-content-area" />
                        <main className="main-container" id="main-content-area" style={{height: this.containerHeight}}>
                            <Mainbody 
                                headerRef={this.headerRef} 
                                showNavInlineSearch={this.setNavInlineSearchVisibility}
                                showFooter={this.setFooterVisibility} 
                                userTokenValid={isAuthValid} 
                                ffClient={featureFlagClientObject}
                                isAdmin={isAdmin} isOrgOwner={isOrgOwner} 
                                hasVACAccess={hasVACAccess} 
                            />
                            {footerVisible && (
                                <footer role={"contentinfo"}>
                                    <Footer isAuthValid={isAuthValid}/>
                                </footer>
                            )}
                        </main>
                    </AlertContextProvider>
                </FeatureFlagsProvider>
            </vacContext.Provider>
        );
    }
}

export default Main;
