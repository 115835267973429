import React, {useContext} from "react";
import { Route, Redirect } from "react-router-dom";
import ErrorPage from "../../containers/ErrorPage";
import { getUserType } from "../../services/helpers/httpHelpers";
import { getFeatureFlagValue, isEmpty } from "../../helpers";

import {appContext} from "../../appContext";

const isAccessAllowed = (accessAllowed, adminRequired) => {
    if(adminRequired) {
        return accessAllowed && (getUserType() === "marketplaceadmin");
    }

    return accessAllowed;
};

const shouldShowNotFound = (showNotFound, featureFlags = [], ffClient = null) => {
    if(showNotFound) return true;
    if(!ffClient || isEmpty(featureFlags)) return false;
    return !featureFlags.some(item => getFeatureFlagValue(ffClient, item));
};

export const PrivateRoute = ({ component: Component, accessAllowed, showNotFound = false, adminRequired = false, featureFlags = [], ...rest }) => {
    const {ffClient = null} = useContext(appContext);
    
    return (
        <Route {...rest} render = {props => (
            isAccessAllowed(accessAllowed, adminRequired)
                ? shouldShowNotFound(showNotFound, featureFlags, ffClient) ? <ErrorPage /> : <Component {...props} ffClient={ffClient} {...rest} accessAllowed={accessAllowed} />
                : <Redirect to={{ pathname: "/no-access?passon=true", state: { from: props.location } }} />
        )} />
    );
};
