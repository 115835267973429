import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "../Modal";
import FeedbackForm from "./FeedbackForm";
import { submitFeeedback } from "../../services/api/feedback";
import getPayloadForFeedback from "./utils/getPayloadForFeedback";
import FeedbackSuccesModal from "./FeedbackSuccesModal";
import LocalStorageHelper from "../../services/helpers/LocalStorageHelper";

const FeedBack = ({ children, isAuthValid }) => {
    const feedbackFormRef = useRef(null);
    const [
        showFeedbackModal,
        setShowFeedbackModal
    ] = useState(false);

    const [
        isDisabled,
        setIsDisabled
    ] = useState(true);

    const handleFeedback = () => {
        setShowFeedbackModal(true);
    };

    const [
        captchaAlert,
        setCaptchaAlert
    ] = useState(null);

    const [
        showSuccessModal,
        setShowSuccessModal
    ] = useState(false);

    const [
        loading,
        setLoading
    ] = useState(false);

    const handleModalAction = type => {
        if(type === "submit") {
            onSubmit();
        }else {
            onClose();
        }
    };

    const onSubmit = () => {
        const formData= feedbackFormRef.current.getFormValue();
        const userID = LocalStorageHelper.get("userId");
        const  lumos = window["lumos"];
        const  _paq = lumos._paq = lumos._paq || [];
        if (userID){
            lumos.identify(userID);
        }
        const payload = getPayloadForFeedback(formData, isAuthValid);
        setLoading(true);
        submitFeeedback({payload, captchaDetails: formData.captchaDetails})
            .then(res => {
                setLoading(false);
                onClose();
                setShowSuccessModal(true);
                _paq.push([
                    "trackEvent",
                    "Customer Feedback",
                    "Feedback Submitted"
                ]);
            })
            .catch(error => {
                const {type, message} = error.response.data;
                setLoading(false);
                if (type === "captcha") {
                    setCaptchaAlert(message);
                }
            });
    };

    const onClose = () => {
        setShowFeedbackModal(false);
        captchaAlert && setCaptchaAlert(null);
        setShowSuccessModal(false);
    };

    const feedbackModal = () => (
        <Modal
            title="Tell us about the VMware Marketplace"
            show={showFeedbackModal}
            actions={[
                { label: "CLOSE", type: "cancel", className: "btn btn-outline modal-btn" },
                { label: loading ? "SUBMITTING.." : "SUBMIT", type: "submit", className: "btn btn-primary modal-btn", disabled: isDisabled || loading}
            ]}
            onAction={handleModalAction}
            onClose={handleModalAction}
            modalStaticBackdrop={true}
        >

            <FeedbackForm setIsDisabled={setIsDisabled} isAuthValid={isAuthValid} ref={feedbackFormRef} captchaAlert={captchaAlert} />
        </Modal>
    );

    return (
        <>
            <div className="footer-link-text" onClick={handleFeedback}>{children}</div>
            {showFeedbackModal && feedbackModal()}
            {showSuccessModal && <FeedbackSuccesModal showSuccessModal={showSuccessModal} setShowSuccessModal={setShowSuccessModal} />}
        </>
    );
};

FeedBack.propTypes = {
    children: PropTypes.element
};

FeedBack.defaultProps = {
    children: null
};
export default FeedBack;