/* eslint-disable no-extend-native */
if (!String.prototype.includes) {
    String.prototype.includes = function(search, start) {
        
        if (typeof start !== "number") {
            start = 0;
        }
    
        if (start + search.length > this.length) {
            return false;
        } else {
            return this.indexOf(search, start) !== -1;
        }
    };
}

if (!String.prototype.replaceAll) {
    String.prototype.replaceAll = function(str, find, rep = "") {
        return str.split(find).join(rep);
    };
}
