import React, {Component, Fragment} from "react";
import { getCurrentTime, convertMillisecToSec } from "../../helpers/index";
import  { addAcceptLater } from "./helpers";
import { Modal } from "../../components/Modal";
import { downloadPDF } from "../../helpers/downloadPDF";
import AgreementNotification from "./AgreementNotification";
import Loader from "../../components/Loader/Loader";
import { get } from "../../helpers";
import { getAgreementDetails, postAgreementAccept, checkLatestAgreementSign } from "../../services/api/legalAgreement";
import LocalStorageHelper from "../../services/helpers/LocalStorageHelper";
import { getAcceptLaterStatus, removeAcceptLater } from "../LegalAgreement/helpers";
import { CheckLegalAgreementAccepted, checkOrgOwner } from "../../services/helpers";
import PDFRender from "./PDFRender";
import Logger from "../../helpers/logger";
import "./legal-agreement-modal.css";


const pathMapping = [
    {
        path: "/services/details", type: "CONSUMER"
    },
    {
        path: "/services", type: "CONSUMER"
    },
    {
        path: "/", type: "CONSUMER"
    }
    
];
class LegalAgreementModal extends Component {

    state = {
        isAcceptDisabled: true,
        showLoader: true,
        startReadingTime: 0,
        pdfLoadError: false,
        loading: true,
        showAgreementModal: false,
        agreementDetails: {},
        fetchDataError: false
    }

    componentDidMount() {
        const { currentUrl, loggedIn } = this.props;
        const matchingPath = currentUrl && pathMapping.find(each => currentUrl.startsWith(each.path));
        const newOrgType = matchingPath && matchingPath.type;
        loggedIn && newOrgType && currentUrl && this.getLicenseAgreementDetails(newOrgType);
    }
    
    componentDidUpdate(prevProps) {
        const { currentUrl, loggedIn } = this.props;
        const { orgType } = this.state;
        const matchingPath = currentUrl && pathMapping.find(each => currentUrl.startsWith(each.path));
        const newOrgType = matchingPath && matchingPath.type;
        
        if (loggedIn && prevProps.currentUrl !== currentUrl && matchingPath && orgType !== newOrgType) {
            this.getLicenseAgreementDetails(newOrgType);
        }
    }

    getLicenseAgreementDetails = orgType => {
        checkLatestAgreementSign(orgType).then(res => {
            const agreementAccepted = !get(res, "data.response.needssign", true);
            LocalStorageHelper.save(`legal_agreement_accepted_${orgType}`, agreementAccepted);
    
            if(!agreementAccepted) {
                this.fetchAgreement(orgType);
            }
        })
            .catch(err => {
                const errorMessage = get(err, "response.data.response.message", "Error while fetching data.");
                this.setState({loading: false, fetchDataError: true, errorMessage});
                console.log("err", errorMessage);
            }
            );
 
    }

    checkAgreementModal = () => {
        const { agreementDetails: {acceptLater}, orgType } = this.state;
        if (!CheckLegalAgreementAccepted(`legal_agreement_accepted_${orgType}`) && checkOrgOwner() && !acceptLater) {
            this.showAgreementModal();
        }
    }

    showAgreementModal = () => {
        this.setState({
            showAgreementModal: true
        });
    }

    fetchAgreement = orgType => {
        this.setState({ loading: true, message: "", orgType });

        getAgreementDetails(orgType)
            .then(res => {
                const { link, signedby, signedon, version, needssign } = get(res, "data.response.agreement", {});
                this.setState({
                    loading: false,
                    agreementDetails: {
                        url: link,
                        signedBy: signedby,
                        signedOn: signedon,
                        version,
                        needsSign: needssign,
                        acceptLater: getAcceptLaterStatus(orgType)
                    }
                }, () => {
                    this.checkAgreementModal();
                });
            })
            .catch(error => {
                const errorMessage = get(error, "response.data.response.message", "Error while fetching data.");
                this.setState({loading: false, fetchDataError: true, errorMessage});
                Logger.error("Error: " + errorMessage);
            });
    }

    getTimeToRead = () => {
        const endReadingTime = getCurrentTime();
        return convertMillisecToSec(endReadingTime - this.state.startReadingTime);
    }

    getAcceptPayload = () => ({
        timeToRead: this.getTimeToRead(),
        browserDetails: navigator.userAgent
    });

    onAcceptLaterClick = () => {
        const { orgType } = this.state;
        const { onAcceptLater } = this.props;
        this.hideModal();
        onAcceptLater ? addAcceptLater("PUBLISHER") : addAcceptLater(orgType);
        onAcceptLater && onAcceptLater();
    }

    onAcceptClick = () => {
        const { orgType } = this.state;
        const { onAccept, isServiceMgmt } = this.props;
        this.setState({
            showAgreementModal: false
        });
        const payload = this.getAcceptPayload();
        isServiceMgmt ? onAccept(payload) :
            postAgreementAccept({...payload, orgType })
                .then(res => {
                    const { message } = get(res, "data.response", {});
                    this.setState({
                        loading: true,
                        agreementDetails: {
                            ...this.state.agreementDetails,
                            needsSign: false,
                            isShowAlert: true,
                            isSuccessAccept: true,
                            message
                        }
                    });
                    LocalStorageHelper.save(`legal_agreement_accepted_${orgType}`, true);
                    removeAcceptLater(orgType);
                    this.hideModal("accepted");
                    typeof onAccept === "function" && onAccept(payload);
                })
                .catch(error => {
                    console.log("error", get(error, "response.data.response.message"));
                    this.setState({
                        agreementDetails: {
                            ...this.state.agreementDetails,
                            isShowAlert: true,
                            isSuccessAccept: false,
                            message: get(error, "response.data.response.message", "Error while fetching data.")
                        }
                    });
                    Logger.error("Error: " + error);
                });
    }

    hideModal = accepted => {
        const {onClose} = this.props;
        this.setState({
            isAcceptDisabled: true,
            showLoader: true,
            showAgreementModal: false
        }, () => {
            if(!accepted && typeof onClose === "function")  {
                onClose();
            }
        });
    }

    get modalConfig() {
        const { isAcceptDisabled, showLoader, showAgreementModal, agreementDetails: { url } } = this.state;
        const { show, hideAcceptLater = false, disablePublisherAcceptBtn } = this.props;
        const modal = {
            show: typeof show === "boolean" ? show : showAgreementModal,
            size: "large",
            className: "legal-agreement-modal la-modal",
            title: "Legal Agreement",
            closeable: hideAcceptLater,
            backdropHide: false,
            onClose: this.hideModal,
            footer: (
                <Fragment>
                    <div className="la-modal-footer">
                        <div className="la-modal-col">
                            {
                                !hideAcceptLater &&
                        <button className="btn"
                            type="button"
                            disabled={showLoader}
                            onClick={this.onAcceptLaterClick}
                        >
                                Accept later
                        </button>
                            }
                        </div>
                        <div className="la-modal-col">
                            <button className={`btn btn-link btn-icon ${showLoader ? "disabled" : ""}`}
                                
                                onClick={() => downloadPDF(this.props.url || url)
                                }
                            > 
                                <clr-icon shape="download"/>
                                Download
                            </button>
                            <button className="btn btn-primary"
                                type="button"
                                disabled={isAcceptDisabled || showLoader || disablePublisherAcceptBtn}
                                onClick={this.onAcceptClick}
                            >
                                Accept
                            </button>
                        </div>

                    </div>
                </Fragment>
            )
        };
        return modal;
    }

    successContentLoading = () => {
        const startReadingTime = getCurrentTime();
        this.setState({
            showLoader: false,
            isAcceptDisabled: true,
            startReadingTime
        });
    }

    failedContentLoading = () => {
        this.setState({
            showLoader: false,
            pdfLoadError: true
        });
    }

    getNotifications = () => {
        const alertProps = {
            show: this.state.pdfLoadError,
            type: "alert-danger",
            message: "PDF file not found. Please try again later."
        };
        return <AgreementNotification {...alertProps} />;
    }

    enableAcceptButton = () => {
        this.setState({
            isAcceptDisabled: false
        });
    }

    render() {
        const { showLoader, agreementDetails: { url }, fetchDataError, errorMessage, loading } = this.state;
        const pdfURL = this.props.url || url;
        return (
            <div className="legal-agreement-modal">
                <Modal {...this.modalConfig}>
                    { showLoader && loading ?
                        <div className="la-modal-loader">
                            <Loader size="inline"/>
                        </div>
                        : null
                    }

                    {this.getNotifications()}
                    {
                        fetchDataError ?
                            <AgreementNotification
                                show={fetchDataError}
                                type="alert-danger"
                                message={errorMessage}
                                closeButton={false}
                            />
                            :
                            pdfURL && <PDFRender
                                url={pdfURL}
                                onSuccessLoad={this.successContentLoading}
                                onFailedLoad={this.failedContentLoading}
                                enableButton={this.enableAcceptButton}
                            />}

                </Modal>
            </div>);
    }
}

export default LegalAgreementModal;