const KEYS = {
    ACCESS_TOKEN : "csp-auth-token",
    REFRESH_TOKEN : "refresh_token",
    TOKEN_KEYS : {
        "org" : "context_name",
        "permissions" : "perms",
        "expiry" : "exp",
        "username" : "username",
        "account" : "acct"
    },
    HAS_VAC_ACCESS : "hasVACAccess"
};

export default KEYS;
