import LocalStorageHelper from "../../services/helpers/LocalStorageHelper";
import PERMISSIONS from "../../config/permissions";
import {checkIsAdminRole, hasTapsfdcid} from "../../services/helpers";
import {get, getFeatureFlagValue, isEmpty, appConfig} from "../../helpers";
import * as NavDataListObj from "./navDataList";
/*
    NavDataManager Class: Will handle all the logic of which nav items should be visible,
    based on if user is loggedIn or not and current loggedIn user's role and permissions.
*/
const CSP_FEATURE_FLAGS = get(appConfig, "CSP_FEATURE_FLAGS", "");
const { VAC_CLIENT } = CSP_FEATURE_FLAGS;

class NavDataManager {
    
    constructor(isLoggedIn = false, ffClient, isStandaloneCustomer, isAdvancedCustomer) {
        this.isLoggedIn = isLoggedIn;
        this.ffClient = ffClient;

        //isVACFeatureEnabled -feature flag which indicates if VAC integration is applied for this org
        this.isVACFeatureEnabled = getFeatureFlagValue(ffClient, VAC_CLIENT) ; 
        this.role = isLoggedIn ? this.getRole() : null;
        this.isAdmin = checkIsAdminRole();
        this.hasTapsfdcid = hasTapsfdcid();
        this.isPublisher = this.hasPublisherPermission();
        this.permissionsSet = isLoggedIn ? this.getPermissionsSet() : new Set([]);
        this.isStandaloneCustomer = isStandaloneCustomer;
        this.isAdvancedCustomer = isAdvancedCustomer;
    }

    getRole = ()=> LocalStorageHelper.get("current_permission");
    
    //Will return permission set of loggedIn user, extracted from localstorage.
    getPermissionsSet = ()=> {
        const list = LocalStorageHelper.getFromObj("user", "permissionsList") || [];
        const ifVACPurchased= LocalStorageHelper.getFromObj("user", "VAC_purchased") || true;
        if(this.isLoggedIn) {
            list.push({slug: PERMISSIONS.GENERAL_PERMISSION});
        }

        if(this.isPublisher) {

            if(ifVACPurchased){
                list.push({slug: PERMISSIONS.ORG_PUBLISH_ENABLED});
            }else{
                list.push({slug: PERMISSIONS.ORG_PUBLISH_ENABLED});
            }
        }else{
            if(ifVACPurchased){
                list.push({slug: PERMISSIONS.GENERAL_PERMISSION});
            }else{
                list.push({slug: PERMISSIONS.GENERAL_PERMISSION});
            }
        }

        return new Set(list.map(item => item.slug));
    }

    hasPublisherPermission = ()=> {
        const userObj = window.localStorage.getItem("user");
        // Need try/catch bolck here, as JSON.parse throws error if invalid JSON string is supplied.
        try {
            const user = JSON.parse(userObj);

            if (get(user, "haspublisherpem", false) && get(user, "orgglobalpublish", false)) {
                return true;
            }
        } catch(err) {
            return false;
        }

        return false;
    }

    getMainNavItems = ()=> {
        const display = this.isVACFeatureEnabled ?  NavDataListObj.displayNavMenuVAC(this.ffClient, this.isLoggedIn) : NavDataListObj.mainNavList(this.ffClient, this.isLoggedIn);
        return display.filter(this.hasPermission);
    }
    getNavMenu = mainItem => {
        const list = typeof NavDataListObj[mainItem] === "function" ? NavDataListObj[mainItem](this.ffClient, this.isStandaloneCustomer, this.isAdvancedCustomer) : NavDataListObj[mainItem];
        return isEmpty(list) ? [] : list.filter(this.hasPermission);
    }

    hasPermission = item => {
        const roles = new Set(get(item, "roles", []));
        const permissions = get(item, "permissions", []);
        const featureFlags = get(item, "featureFlags", []);

        let permission = true;

        permission = permissions.some(item => this.permissionsSet.has(item));

        if(this.role && !roles.has(this.role)) {
            permission = false;
        }

        if(permission && !isEmpty(featureFlags)) {
            permission = featureFlags.some(item => getFeatureFlagValue(this.ffClient, item));
        }
        if(typeof item.show === "function"){
            /* to do revisit the way navdatalist is getting used, as the values are not getting changed as we are intantiating and then using it.Which is
            retaining the old states from the sessions*/
            const showValue = item.show();
            permission = typeof showValue === "string" ? showValue !== "false" : !!showValue;
        }

        return permission;

    }
}

export default NavDataManager;