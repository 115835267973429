import {createContext} from "react";
import { tokenHelper } from "./services/helpers/tokenHelper";

const token = tokenHelper.getTokenByName("csp-auth-token");
const refreshToken = tokenHelper.getTokenByName("refresh_token");

export const vacInitState = {
    loading: true,
    profile: {},
    session: {
        token,
        refreshToken,
        signedInAt: token !== "" ? new Date() : null,
        loggedOut: false
    },
    context: {
        domain: "",
        org_id: "",
        perms: [],
        username: "",
        service_id: ""
    },
    subscriptions: [],
    subscriptions_consumption: {
        limit: 0,
        current: 0
    }
};
export const appContext = createContext(null);

export const vacContext = createContext(null);
