import React, {useMemo} from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import "./Backdrop.css";


const Backdrop = props => {
    const {className, children, onBackdropClick, show = true, targetNode = null} = props;
    const backdropEl = useMemo(() => document.createElement("div"), []);

    React.useEffect(() => {
        const target = targetNode ? targetNode : document.body;
        const layoutWrapper = document.getElementById("main-content-area");
        const classList = ["app-backdrop"];

        if (className) className.split(" ").forEach(item => classList.push(item));
        classList.forEach(item => backdropEl.classList.add(item));

        if(show){
            target.appendChild(backdropEl);
            layoutWrapper.classList.add("no-scroll");
        }

        backdropEl.addEventListener("click", onBackdropClick);

        return () => {
            backdropEl.remove();
            if(layoutWrapper) {
                layoutWrapper.classList.remove("no-scroll");
            }
            backdropEl.removeEventListener("click", onBackdropClick);
        };
    }, [
        backdropEl,
        className,
        onBackdropClick,
        show,
        targetNode
    ]);

    if(!show) return null;

    return ReactDOM.createPortal(children, backdropEl);
};

Backdrop.propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    onBackdropClick: PropTypes.func,
    show: PropTypes.bool
};

export default Backdrop;