import { isEmpty, trim } from "../../helpers";

const STATUS_CLASS = {
    "published": "success",
    "rejected": "danger",
    "archived": "danger",
    "pending": "info",
    "success": "success",
    "failed": "danger",
    "subscribed" : "success",
    "unsubscribed": "warning"
};

const getStatus = rawstatus => {
    if (typeof rawstatus !== "string" || isEmpty(trim(rawstatus))) return "";

    return rawstatus.split("_").join(" ").toUpperCase();
};

//Return class name for Status span
const getClassName = status => {
    if (isEmpty(trim(status))) return "";

    const lcaseStatus = status.toLocaleLowerCase();

    if (STATUS_CLASS[lcaseStatus]) {
        return `status-label-${STATUS_CLASS[lcaseStatus]}`;
    }

    if (lcaseStatus === "unsubscribed") {
        return `status-label-${STATUS_CLASS["warning"]}`;
    }

    if (lcaseStatus.includes("created") || lcaseStatus.includes("subscribed")) {
        return `status-label-${STATUS_CLASS["success"]}`;
    }

    if (lcaseStatus.includes("failed") || lcaseStatus.includes("error")) {
        return `status-label-${STATUS_CLASS["failed"]}`;
    }
};

export const getOwnProductStatus = rawstatus => {
    const status = getStatus(rawstatus);
    const classname = getClassName(status);

    return {
        status,
        classname
    };
};




