const common = {

    branding: {
        title: "VMware Marketplace",
        link: "/"
    },
    timeout: 15000,
    topNavActive: false,
    subNavActive: true,

    navigation: [
        {
            icon: "list",
            route: "/services",
            exact: true,
            label: "Services",
            requiredEntitlements: {
                roles: ["marketplaceuser"]
            }
        },
        {
            icon: "wrench",
            route: "/services/management",
            label: "Service Management",
            requiredEntitlements: {
                roles: ["marketplaceuser"]
            }
        },
        {
            icon: "wrench",
            route: "/admin/services/management",
            label: "Service Management",
            requiredEntitlements: {
                roles: ["marketplaceadmin"]
            }
        },
        {
            icon: "administrator",
            route: "/admin/usermanagement",
            label: "User Management",
            requiredEntitlements: {
                roles: ["marketplaceadmin"]
            }
        },
        {
            icon: "users",
            route: "/admin/orgmanagement",
            label: "Org Management",
            requiredEntitlements: {
                roles: ["marketplaceadmin"]
            }
        },
        {
            icon: "view-list",
            route: "/admin/peripheralmanagement",
            label: "Peripheral Management",
            requiredEntitlements: {
                roles: ["marketplaceadmin"]
            }
        },
        {
            icon: "file-group",
            label: "Subscriptions",
            route: "/subscription/list",
            requiredEntitlements: {
                roles: [
                    "marketplaceuser"
                    // "marketplaceadmin"
                ]
            }
        },
        {
            icon: "file-settings",
            route: "/admin/datamanagement",
            label: "Data Management",
            requiredEntitlements: {
                roles: ["marketplaceadmin"]
            }

        },
        {
            icon: "list",
            route: "/eulamanagement",
            label: "EULA Management",
            requiredEntitlements: {
                roles: ["marketplaceadmin"]
            }
        },
        {
            icon: "bar-chart",
            route: "/admin/analytics",
            label: "Analytics",
            requiredEntitlements: {
                roles: ["marketplaceadmin"]
            },
            items: [
                {
                    label: "User Growth",
                    route: "/admin/analytics/users",
                    exact: true
                },
                {
                    label: "Org Growth",
                    route: "/admin/analytics/orgs",
                    exact: true
                },
                {
                    label: "Subscription Growth",
                    route: "/admin/analytics/subs",
                    exact: true
                },
                // {
                //     label: "Products Access",
                //     route: "/admin/analytics/AccessAnalytics",
                //     exact: true
                // },
                // {
                //     label: "Subscriptions",
                //     route: "/admin/analytics/SubscriptionAnalytics",
                //     exact: true
                // },
                {
                    label: "All Subscriptions",
                    route: "/admin/analytics/subscriptions-list",
                    exact: true
                }
            ]
        },
        {
            icon: "bar-chart",
            route: "/analytics",
            label: "Analytics",
            items: [
                {
                    label: "Subscription",
                    route: "/analytics/subscription",
                    exact: true
                }
            ],
            requiredEntitlements: {
                roles: ["marketplaceuser"]
            }
        },
        {
            icon: "clipboard",
            route: "/billing",
            label: "Billing",
            items: [
                {
                    label: "Dashboard",
                    route: "/billing/dashboard",
                    exact: true
                },
                {
                    label: "Bills",
                    route: "/billing/bills",
                    exact: true
                },
                {
                    label: "Revenue",
                    route: "/billing/revenue",
                    exact: true
                },
                {
                    label: "Payment History",
                    route: "/billing/history",
                    exact: true
                }
            ],
            requiredEntitlements: {
                roles: ["marketplaceuser"]
            }
        },
        {
            icon: "user",
            label: "Account",
            items: [
                {
                    route: "/user/notifications",
                    label: "Notifications",
                    exact: true
                },
                {
                    route: "/user/cloudaccounts",
                    label: "Cloud Accounts",
                    requiredEntitlements: {
                        roles: [
                            "marketplaceuser",
                            "marketplaceadmin"
                        ]
                    }
                },
                {
                    route: "/user/list",
                    label: "Users",
                    exact: true
                },
                {
                    route: "/legal-agreement",
                    label: "Legal Agreement",
                    exact: true,
                    requiredEntitlements: {
                        roles: ["marketplaceuser"]
                    }
                },
                {
                    route: "/admin/legal-agreement",
                    label: "Legal Agreement List",
                    exact: true,
                    requiredEntitlements: {
                        roles: ["marketplaceadmin"]
                    }
                },
                {
                    route: "/user/org/settings",
                    label: "Publishing Setting",
                    exact: true,
                    requiredEntitlements: {
                        roles: ["marketplaceuser"]
                    }
                },
                {
                    route: "/egress-info",
                    label: "Egress IPs",
                    exact: true,
                    requiredEntitlements: {
                        roles: [
                            "marketplaceuser",
                            "marketplaceadmin"
                        ]
                    }
                },
                {
                    route: "/leads",
                    label: "Leads",
                    exact: true,
                    requiredEntitlements: {
                        roles: [
                            "marketplaceuser",
                            "marketplaceadmin"
                        ]
                    }
                }

                // {
                //     label: "Preferences",
                //     route: "/user/preferences",
                //     exact: true,
                //     requiredEntitlements: { roles: ["marketplaceuser"] }
                // },
                // { label: 'Roles', route: '/user/roles', exact: true },
                // { label: 'Security', route: '/user/security', exact: true },
            ]
        }
    ]
};

export default common;
