import React from "react";
import "./agreement-notifications.css";

const AlertNotification = ({ show, type, message, closeButton=false, onClose=()=>{} }) => (
    <div className={`alert alert-agreement ${type}`} role="alert" hidden={!show}  >
        <div className="alert-items">
            <div className="alert-item static">
                <span className="alert-text">
                    {message}
                </span>
            </div>
        </div>
        { closeButton && 
                <button type="button" className="close" aria-label="Close" onClick={onClose}>
                    <clr-icon aria-hidden="true" shape="close"></clr-icon>
                </button>
        }
    </div>
);

export default AlertNotification;
