const config = require(`./config-files/${process.env.REACT_APP_CONFIG_ENV}.json`);

const permissionConfig = config["permissions"];

const PERMISSIONS = {
    PUBLISH_SERVICES_PEM: permissionConfig.PUBLISH_SERVICES_PEM,
    APPROVE_SERVICES_PEM: permissionConfig.APPROVE_SERVICES_PEM,
    REJECT_SERVICES_PEM: permissionConfig.REJECT_SERVICES_PEM,
    MANAGE_USERS_PEM: permissionConfig.MANAGE_USERS_PEM,
    GENERAL_PERMISSION: permissionConfig.GENERAL_PERMISSION,
    MANAGE_SOLUTIONS: permissionConfig.MANAGE_SOLUTIONS,
    PRODUCT_AUDITING: permissionConfig.PRODUCT_AUDITING,
    ANALYTICS_SERVICE: permissionConfig.ANALYTICS_SERVICE,
    MANAGE_ADMIN: permissionConfig.MANAGE_ADMIN,
    MANAGE_PERIPHERALS: permissionConfig.MANAGE_PERIPHERALS,
    ORG_PUBLISH_ENABLED: permissionConfig.ORG_PUBLISH_ENABLED,
    COMMERCE_ADMIN: permissionConfig.COMMERCE_ADMIN
};

export default PERMISSIONS;