import { get, appConfig, isEmpty } from "./helpers";
const oneTrustScriptId = get(appConfig, "oneTrustScriptId", null);
const ONE_TRUST_TAG_ID = "#one-trust-tag";

export const injectCookieManagement = () => {
    if(!isEmpty(oneTrustScriptId)) {
        if (document.querySelector(ONE_TRUST_TAG_ID) != null) {
            return;
        }

        const script = document.createElement("script");
        script.id = ONE_TRUST_TAG_ID;
        script.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
        script.type = "text/javascript";
        script.charset = "UTF-8";
        script.dataset.domainScript = oneTrustScriptId;
        document.head.appendChild(script);
    }
};
