/* eslint-disable */
import {GlobalWorkerOptions, getDocument} from "pdfjs-dist/build/pdf";
import propTypes from "prop-types";
import React, { Component } from "react";
import Logger from "../../helpers/logger";
import "./pdf-render.css";


class PDFRender extends Component {

    canvasHolderOffsetTop = 0;

    static propTypes = {
        url: propTypes.string.isRequired,
        onSuccessLoad: propTypes.func,
        onFailedLoad: propTypes.func,
        enableButton: propTypes.func
    };

    componentDidMount() {
        this.renderPDF();
        window.addEventListener("resize", this.getCanvasHolderHeight);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.getCanvasHolderHeight);
        this.pdfContainerHolder.removeEventListener("scroll", this.checkAcceptButtonStatus);
    }

    renderPDF = () => {
        const { url, onSuccessLoad, onFailedLoad } = this.props;

        GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/workers/pdf.worker.min.js`;

        let pageCount = "";
        try {
            getDocument(url).promise
                .then(pdfDocument => {
                    pageCount = pdfDocument.numPages;

                    const arr = Array(pageCount)
                        .fill(1)
                        .map((_item, index) => index + 1);

                    return Promise.all(arr.map(pageNumber => this.renderPage(pdfDocument, pageNumber)));
                }, () => {
                    onFailedLoad && onFailedLoad();
                })
                .finally(() => {
                    this.handleCanvasScroll();
                    onSuccessLoad && onSuccessLoad();

                })
                .catch(error => {
                    Logger.error("Error: " + error);
                    onFailedLoad && onFailedLoad();
                    throw error;
                });
        } catch(e) {
            console.log(e);
        }

    }

    renderPage = (pdfDocument, pageNumber) => pdfDocument.getPage(pageNumber).then(page => {
        const scale = 2;
        const viewport = page.getViewport({scale});
        const canvas = document.createElement("canvas");
        canvas.classList.add("pdf-canvas-page");
        const context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        if (window.devicePixelRatio > 1) {
            const canvasWidth = canvas.width;
            const canvasHeight = canvas.height;
            canvas.width = canvasWidth * window.devicePixelRatio;
            canvas.height = canvasHeight * window.devicePixelRatio;
            context.scale(window.devicePixelRatio, window.devicePixelRatio);
        }
        if(this.pdfContainerRef) {
            this.pdfContainerRef.appendChild(canvas);
        }

        const renderContext = page.render({
            canvasContext: context,
            viewport
        });

        return renderContext.promise;
    })
        .catch(error=>Logger.log(error));

    bindPdfContainer = ref => {
        this.pdfContainerRef = ref;
    };

    bindPdfScroll = ref => {
        this.pdfContainerHolder = ref;
    }

    handleCanvasScroll = () => {
        const { enableButton } = this.props;
        if (enableButton && this.pdfContainerRef) {
            this.getCanvasHolderHeight();
            const isScrollablePdf = this.pdfContainerHolder.offsetHeight <= this.pdfContainerRef.offsetHeight;
            if (isScrollablePdf) {
                this.pdfContainerHolder.addEventListener("scroll", this.checkAcceptButtonStatus);
            } else this.props.enableButton();
        }
    }

    checkAcceptButtonStatus = () => {
        const canvasOffsetTop = this.getPdfScrollValue();
        if (this.canvasHolderOffsetTop >= canvasOffsetTop) {
            this.props.enableButton();
        }
    }

    getCanvasHolderHeight = () => {
        if(this.pdfContainerHolder) {
            this.canvasHolderOffsetTop = this.pdfContainerHolder.getBoundingClientRect().bottom;
        } else {
            this.canvasHolderOffsetTop = 0;
        }
    }

    getPdfScrollValue = () => {
        if(this.pdfContainerRef) {
            return this.pdfContainerRef.getBoundingClientRect().bottom;
        }
        return 0;
    }

    render() {
        return (
            <div id="pdf-render-scroll-container" ref={this.bindPdfScroll}  aria-labelledby="pdfreader">
                <div id="pdf-render-main-container" ref={this.bindPdfContainer}role="region" aria-labelledby="pdfreader" tabIndex={0}>
                   <span className="sr-only" id="pdfreader">It is scrollable</span>
                    </div>
            </div>
        );
    }
}

export default PDFRender;
