import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect, useCallback } from "react";
import { config, heading, unsubscribeReason, OPT_IN, initialCaptchaDetails, OPT_OUT, checkboxGroupLabel, defaultErrorMssg } from "./constants";
import CaptchaComponent from "../CaptchaComponent";
import { BasicCheckbox, BasicRadio, BasicRadioGroup, BasicCheckboxGroup } from "@vmware-react/clarity/dist/basic-inputs";
import { isEmpty } from "../../../helpers";
import "./Newsletter.css";
import urlConfig from "../../../config/urls";

// eslint-disable-next-line react/display-name
const NewsletterForm = forwardRef(({ captchaAlert, setIsDisabled, error }, ref) => {
    const captchaRef = useRef(null);
    const [
        captchaDetails,
        setCaptchaDetail
    ] = useState(initialCaptchaDetails);

    const [
        newsletterSubscription,
        setNewsletterSubscription
    ] = useState(OPT_IN);

    const [
        unsubscriptionReason,
        setUnSubscriptionReason
    ] = useState([]);

    const handleOnChange = ({ target }) => {
        const { name, value } = target;

        if (name === "newsletterSubscription") {
            if (value === OPT_IN) {
                setUnSubscriptionReason([]);
                setNewsletterSubscription(value);
            }
            setNewsletterSubscription(value);
        } else {
            if (target.checked) {
                setUnSubscriptionReason([
                    ...unsubscriptionReason,
                    value
                ]);
            } else {
                const filterValue = unsubscriptionReason.filter(v => v !== value);
                setUnSubscriptionReason(filterValue);
            }
        }
    };

    const handleValidation = useCallback(() => {
        const fields = [
            newsletterSubscription,
            captchaDetails.captchaHash,
            captchaDetails.captchaInput
        ];

        const validForm = fields.every(value => !isEmpty(value));

        setIsDisabled(!validForm);
    }, [
        captchaDetails,
        newsletterSubscription,
        setIsDisabled
    ]);

    useEffect(() => handleValidation(), [
        handleValidation,
        captchaDetails,
        newsletterSubscription
    ]);

    const getFormValue = () => ({
        newsletterSubscription,
        unsubscriptionReason,
        captchaDetails
    });

    const reloadCaptcha = () => {
        captchaRef.current.requestNewCaptcha();
    };

    useImperativeHandle(
        ref,
        () => ({
            getFormValue,
            reloadCaptcha
        }));

    const renderSubscriptionForm = () => (
        <div className="subscription-form-wrapper">
            <BasicRadioGroup label={heading}>
                {config.map(({ label, value, name, subtext }) =>
                    <div key={label} className="field-wrapper">
                        <BasicRadio label={label} value={value} name={name}
                            checked={value === newsletterSubscription}
                            onChange={handleOnChange} />
                        {newsletterSubscription === value && <span className="subtext">{subtext}&nbsp;
                            <a href={urlConfig.cookieUsageUrl} rel="noopener noreferrer" target="_blank">
                                VMware Privacy Policy
                            </a>
                        </span>
                        }
                    </div>
                )}
            </BasicRadioGroup>
            <>
                {newsletterSubscription === OPT_OUT &&
                    (
                        <div className="checkbox-grp-wrapper">
                            <BasicCheckboxGroup label={checkboxGroupLabel}>
                                {unsubscribeReason.map(({ label, value }) => <BasicCheckbox key={label} label={label} name="unsubscribeReason" value={value}
                                    checked={unsubscriptionReason.includes(value)}
                                    onChange={handleOnChange} />)}
                            </BasicCheckboxGroup>
                        </div>
                    )}
            </>
        </div>
    );

    return (
        <div ref={ref} className="newsletter-form-wrapper">
            {renderSubscriptionForm()}
            <CaptchaComponent ref={captchaRef} setCaptchaDetail={setCaptchaDetail} captchaDetails={captchaDetails} captchaAlert={captchaAlert} />
            {!!error && !!error.isError && <div className="newsletter-error-wrapper">
                <clr-icon class="error-icon" shape="error-standard" size="14" style={{fill: "red"}}/>
                <span className="newsletter-error">{error.errorMssg || defaultErrorMssg}</span>
            </div>}
        </div>
    );
});

export default NewsletterForm;