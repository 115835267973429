import React, { useState, useRef } from "react";
import { Modal } from "../../Modal";
import { OPT_OUT, defaultErrorMssg, successfullSubscription, successfullUnSubscription } from "./constants";
import { OPT_IN } from "./constants";
import NewsletterForm from "./NewsletterForm";
import getUserDetails from "../../../helpers/getUserDetails";
import { getNewsletterOptIn, createNewsletterOptIn, updateNewsletterOptIn } from "../../../services/api/commonData";
import Loader from "../../Loader/Loader";
import "./Newsletter.css";
import { get } from "../../../helpers";
import { getErrorMessage } from "../utils/getErrorPayload";

const Newsletter = ({ isAuthValid }) => {
    const newsletterRef = useRef(null);
    const [
        optIn,
        setOptIn
    ] = useState(null);

    const [
        showNewsletterModal,
        setModal
    ] = useState(false);

    const [
        error,
        setError
    ] = useState({
        isError: false,
        errorMssg: ""
    });

    const [
        showMssg,
        setMssg
    ] = useState(false);

    const [
        newsletterId,
        checkNewsletter
    ] = useState({
        isNewsletterId: false,
        id: null
    });

    const [
        captchaAlert,
        setCaptchaAlert
    ] = useState(null);

    const [
        isDisabled,
        setIsDisabled
    ] = useState(true);

    const userData = isAuthValid ? getUserDetails() : null;

    const [
        isLoading,
        setLoading
    ] = useState(false);

    const handleNewsletter = () => {
        fetchNewsletterDetails(userData.userId);
        setModal(true);
    };

    // To get existing details about newsletter
    const fetchNewsletterDetails = userid => {
        setLoading(true);
        getNewsletterOptIn(userid).then(response => get(response, "data.response.data", {})).then(res => {
            if (res && res.id) {
                checkNewsletter({
                    isNewsletterId: true,
                    id: res.id
                });
            }
            if (res && res.isoptin) {
                setOptIn(OPT_IN);
            } else if (!!res.message && !res.isoptin) {
                setMssg(false);
            }
        })
            .catch(error => {});
        setLoading(false);
    };

    const onSubmit = () => {
        try {
            const payload = newsletterRef.current.getFormValue();
            onSubmitNewsletterDetails(payload);
        } catch (error) {
            setError({
                isError: true,
                errorMssg: error
            });
        }
    };
    // To create newsletter
    const onSubmitNewsletterDetails = data => {
        setLoading(true);
        let request = {
            "isoptin": data.newsletterSubscription === "opt-in"
        };
        // update if already subscribed
        if (newsletterId.isNewsletterId) {
            request = {
                ...request,
                "id": newsletterId.id,
                "commentsList": data.unsubscriptionReason ? data.unsubscriptionReason : []
            };
            updateNewsletterData(request, data);
        } else {
            createNewsletterOptIn({...request, captchaDetails: data.captchaDetails }).then(response => {
                setOptIn(request.isoptin ? OPT_IN: OPT_OUT);
                setMssg(true);
            })
                .catch(error => {
                    const message = getErrorMessage(error);
                    setError({
                        isError: true,
                        errorMssg: message
                    });
                });
        }
        setLoading(false);
    };

    // resubscribe when link is clicked
    const handleReSubscription = () => {
        try {
            setLoading(true);
            const request = {
                "isoptin": true,
                "id": newsletterId.id,
                "resubscribe": true
            };
            // re subscribe user to newsletter
            if (newsletterId.isNewsletterId) {
                updateNewsletterData(request);
            }
        } catch (error) {
            setError({
                isError: true,
                errorMssg: error
            });
        }
        setLoading(false);
    };

    const updateNewsletterData = (request, data = {}) => {
        const payload = Object.keys(data).length ? { ...request, captchaDetails: data.captchaDetails } : { ...request };
        updateNewsletterOptIn(newsletterId.id, payload)
            .then(response => get(response, "data.response", {})).then(res => {
                const { statuscode } = res;
                if (statuscode) {
                    setOptIn(request.isoptin ? OPT_IN : OPT_OUT);
                    setMssg(true);
                }
            }).catch(error => {
                const message = getErrorMessage(error);
                newsletterRef.current.reloadCaptcha();
                setError({
                    isError: true,
                    errorMssg: message
                });
            });
    };

    const handleModalAction = type => {
        if (type === "submit") {
            onSubmit();
        } else {
            onClose();
        }
    };

    const onClose = () => {
        setModal(false);
        captchaAlert && setCaptchaAlert(null);
        setMssg(false);
        setError({
            isError: false,
            errorMssg: ""
        });
    };

    const renderSubscriptionMssg = optIn => {
        switch (optIn) {
        case OPT_IN:
            return <>
                <clr-icon class="success-icon" shape="success-standard" size="30" style={{ fill: "green" }} />
                <p className="success-subscription-mssg">{successfullSubscription}</p>
            </>;
        case OPT_OUT:
            return <>
                <clr-icon class="sad-face-icon" shape="sad-face" size="30" style={{ fill: "green" }} />
                <p className="sorry-text">Sorry to see you going.</p>
                <p className="success-unsubscription-mssg">{successfullUnSubscription}</p>
                <span className="mistake-mssg">Was this a mistake?<button className="re-subscribe" onClick={handleReSubscription}>Re-subscribe</button></span>
            </>;
        default:
            return <>
                <clr-icon class="error-icon" shape="error-standard" size="30" style={{ fill: "red" }} />
                <p className="sorry-text">{defaultErrorMssg}</p>
            </>;
        }
    };
    return (
        <>
            {
                isAuthValid &&
                <button className="footer-link-button" onClick={handleNewsletter}>Newsletter</button>
            }
            {showNewsletterModal &&
                <div className="newsletter-modal-wrapper">
                    <Modal
                        title={!showMssg ? "Manage Newsletter Settings" : ""}
                        show={showNewsletterModal}
                        actions={!showMssg ? [
                            { label: "CLOSE", type: "cancel", className: "btn btn-outline modal-btn" },
                            { label: "SUBMIT", type: "submit", className: "btn btn-primary modal-btn", disabled: isDisabled }
                        ]
                            :
                            [{ label: "CLOSE", type: "cancel", className: "btn btn-primary modal-btn" }]
                        }
                        onAction={handleModalAction}
                        onClose={handleModalAction}
                        modalStaticBackdrop={true}
                    >
                        {!showMssg
                            ?
                            <NewsletterForm error={error} ref={newsletterRef} captchaAlert={captchaAlert} setIsDisabled={setIsDisabled} />
                            :
                            <div className="successfull-wrapper">
                                <div className="success-mssg-container">
                                    {renderSubscriptionMssg(optIn)}
                                </div>
                            </div>}
                    </Modal>
                    { isLoading && <Loader size="sm" loaderText="loading." /> }
                </div>
            }
        </>
    );
};

export default Newsletter;