import { useEffect } from "react";

export const useDebouncedEffect = (callbackFunc, delay) => {

    useEffect(() => {
        const handler = setTimeout(() => {
            callbackFunc();
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [
        callbackFunc,
        delay
    ]);
};
