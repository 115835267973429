import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { CspFeatureFlagClient } from "@vmw/csp-ff-sdk";

import { FeatureFlagsContext } from "../common/hooks/useFeatureFlags/useFeatureFlag";

//VAC-TODO: Sentry will be one which is being used in marketplace.
// import { requestError } from "../common/utils/sentry";
import { getSessionToken, isTokenExpired} from "../utils/userData";
import { vacContext } from "../../../appContext";
import featureFlags from "../../../config/featureFlags";

/**
 * Returns the integration key for CSP feature flag integration depending on
 * the environment.
 * The sdk key is using config.
 */
const getIntegrationKey = () => featureFlags.vacFeatureFlagSdkKey;

/**
 * FeatureFlagsProvider wraps CSP Feature Flag integration so flag evaluations are
 * allowed in all application through the client.
 *
 * Ideally calls to the service will be done with an user token for scoped flags. If no
 * token is provided, the call would still success.
 */
const FeatureFlagsProvider = ({children}) => {
    const [
        ffClient,
        setFfclient
    ] = useState(null);

    const [
        loading,
        setLoading
    ] = useState(true);
    // const token = useSelector(state => getSessionToken(state));
    // const tokenExpired = useSelector(state => isTokenExpired(state));
    const {store} = useContext(vacContext);
    const token = getSessionToken(store);
    const tokenExpired = isTokenExpired(store);


    useEffect(() => {
        if(!tokenExpired){
            CspFeatureFlagClient.initialize({
                sdkKey: getIntegrationKey(),
                token: !tokenExpired ? token : ""
            })
                .then(response => {
                    const ffClient = response.client;
    
                    if (
                        response.error &&
              response.error.name === "CspFeatureFlagLoadFailureError"
                    ) {
                        // Send error to Sentry only if the framework could not be loaded
                        //VAC-TODO: Sentry will be one which is being used in marketplace.
                        // requestError(response.error);
                    } else {
                        setFfclient(ffClient);
                    }
                })
                .finally(() => setLoading(false));
        }
        
    }, [
        token,
        tokenExpired
    ]);

    // Helper function to quickly evaluate flags without the need of the
    // client
    const isFlagEnabled = flag => ffClient && ffClient.evaluate(flag);

    return (
        <FeatureFlagsContext.Provider
            value={{
                ffClient,
                loading,
                isFlagEnabled
            }}
        >
            {children}
        </FeatureFlagsContext.Provider>
    );
};

FeatureFlagsProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default FeatureFlagsProvider;
