import React, { Component } from "react";
import PropTypes from "prop-types";


/**
 * General component description in JSDoc format.
 * should describe what this component is meant for
 */
class Notification extends Component {
    constructor(props){
        super(props);
        this.state = {type: "success"};
        this.close = this.close.bind(this);
    }

    close(){
        this.props.onNotificationClose();
    }

    render() {
        const {text, type, isHidden = false, isAlertAction, children} = this.props;
        let viewClass = null;
        let viewIcon = null;

        if(isHidden){
            return null;
        }

        switch (type) {

        case "success": {
            viewClass = "alert alert-success";
            viewIcon = "check-circle";
            break;
        }

        case "error": {
            viewClass = "alert alert-danger";
            viewIcon = "exclamation-circle";
            break;
        }

        case "info": {
            viewClass = "alert alert-info";
            viewIcon = "info-standard";
            break;
        }

        case "warning": {
            viewClass = "alert alert-warning";
            viewIcon = "info-standard";
            break;
        }

        default: break;
        }

        return (
            <div className={viewClass} role="alert">
                <div className="alert-items">
                    <div className="alert-item static">
                        <div className="alert-icon-wrapper">
                            <clr-icon className="alert-icon" shape={viewIcon}/>
                        </div>
                        <span className="alert-text">
                            {text}
                        </span>
                        <div className="alert-actions">
                            {isAlertAction && children}
                        </div>
                    </div>
                </div>
                { this.props.onNotificationClose && (
                    <button type="button" className="close" onClick={this.close} aria-label="Close">
                        <clr-icon aria-hidden="true" shape="close"/>
                    </button>)
                }
            </div>
        );
    }
}

Notification.propTypes = {
    /**
     * This needs to be a custom component with props type, text and isHidden.<br>
     * preferable this should be Notification.
    */
    text: PropTypes.string,
    /**
     * type can be - "success", "error", "info" or "warning"
     */
    type: PropTypes.string,
    isHidden: PropTypes.bool
};

export default Notification;