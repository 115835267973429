import React from "react";
import {Spinner} from "@vmware-react/clarity/dist/spinner";
import "./PageLoader.css";

function PageLoader() {
    return (
        <div className="page-loader-wrapper">
            <Spinner size="lg" />
            <span className="sr-only" aria-live="assertive">loading</span>
        </div>
    );
}

export default PageLoader;