const config = require(`./config-files/${process.env.REACT_APP_CONFIG_ENV}.json`);

const url = config["node-backend"].url;

const serverConfig = {
    "dev": {
        "url": url
    },
    "devqa": {
        "url": url
    },
    "local": {
        "url": url
    },
    "prod": {
        "url": url
    },
    "production": {
        "url": url
    },
    "staging": {
        "url": url
    }
};


export default serverConfig;