import React, { Component, Fragment } from "react";
import {requestCaptcha} from "../../services/api/product";
import {get, isEmpty} from "../../helpers";
import "./CaptchaDisplay.css";
import Logger from "../../helpers/logger";

const API_STATE = {
    LOADING: "loading",
    SUCCESS: "success",
    FAILED: "failed"
};

class CaptchaDisplay extends Component {

    constructor(props) {
        super(props);
        this.state = {
            apiState: API_STATE.LOADING,
            captcha_svg: null,
            captcha_code: null,
            status: props.status,
            alert: get(props, "alert", null)
        };

        this.inputRef = React.createRef();
    }

    get loadingView() {
        return (
            <span className="captcha-loading">
                <span className="spinner spinner-inline"></span> 
                loading captcha...
            </span>
        );
    }

    get successView() {
        const {captcha_svg} = this.state;
        const {onChange = ()=>false} = this.props;
        return (
            <Fragment>
                <div className="captcha-img">
                    <span dangerouslySetInnerHTML={{__html: captcha_svg}} />
                </div>
                <div className="captcha-refresh">
                    <button ref={this.props.ref} aria-label="Refresh Captcha" className="btn btn-icon" onClick={this.requestNewCaptcha}>
                        <clr-icon shape="refresh" aria-hidden="true"></clr-icon>
                    </button>
                </div>
                <div className="input-area">
                    <input ref={this.inputRef} type="text" autoComplete="off" placeholder="please enter the captcha" onChange={onChange} />
                </div>
            </Fragment>
        );
    }

    get failureView() {
        return (
            <div className="error-view">
                <div className="alert alert-danger" role="alert">
                    <div className="alert-items">
                        <div className="alert-item static">
                            <div className="alert-icon-wrapper">
                                <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                            </div>
                            <span className="alert-text">
                                Error in loading captcha.
                            </span>
                        </div>
                    </div>
                </div>
                
                <button className="btn btn-icon" onClick={this.requestNewCaptcha}>
                    <clr-icon shape="refresh"></clr-icon>
                </button>
            </div>
        );
    }

    requestNewCaptcha = ()=> {
        if(this.inputRef && this.inputRef.current) {
            this.inputRef.current.value = "";
        }
        this.setState({
            apiState: API_STATE.LOADING
        });
        requestCaptcha().then(this.catchaGetSuccess, this.captchaGetFailure).catch(err => {
            Logger.log(err);
        });
    }

    catchaGetSuccess = data => {
        const {onLoadNewCaptcha = ()=>false} = this.props;
        const captcha_code = get(data, "data.captcha_code", null);
        const captcha_svg = get(data, "data.captcha_svg", null);
        onLoadNewCaptcha(captcha_code);
        this.setState({
            captcha_code,
            captcha_svg,
            apiState: (isEmpty(captcha_code) || isEmpty(captcha_svg)) ? API_STATE.FAILED : API_STATE.SUCCESS
        });
    }

    captchaGetFailure = error => {
        this.setState({
            apiState: API_STATE.FAILED
        });
    }

    get mainView() {
        const {apiState} = this.state;

        switch (apiState) {
        
        case API_STATE.LOADING:
            return this.loadingView;
        
        case API_STATE.SUCCESS:
            return this.successView;
        
        case API_STATE.FAILED:
            return this.failureView;
        
        default:
            return null;
        }
    }

    componentDidMount() {
        this.requestNewCaptcha();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.status !== this.state.status) {
            if(this.inputRef && this.inputRef.current) {
                this.inputRef.current.value = "";
            }
        }

        if(nextProps.alert !== this.state.alert) {
            this.setState({
                alert: nextProps.alert
            }, () => {
                if(!isEmpty(nextProps.alert)){
                    this.requestNewCaptcha();
                }
            });
        }
    }


    render() {
        return (
            <div className="captcha-wrapper">
                {this.mainView}
            </div>
        );
    }
}

export default React.forwardRef((props, ref) => <CaptchaDisplay {...props} ref={ref}/>);