import { get } from "../../../helpers";
import { defaultErrorMssg } from "../Newsletter/constants";

function getErrorMessage(err) {
    const captchaError = get(err, "response.data", {});
    const responseError = get(err, "response.data.response", {});

    if (!!captchaError.message) {
        return captchaError.message;
    } else if (!!responseError.message) {
        return responseError.message;
    }

    return defaultErrorMssg;
}

export { getErrorMessage };
