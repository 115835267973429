import React from "react";
import cs from "classnames";
import PropTypes from "prop-types";
import { CdsProgressCircle } from "@cds/react/progress-circle";

import "./Spinner.scss";

/**
 * https://v2.clarity.design/spinners
 */
const Spinner = ({
    center,
    inline,
    inverse,
    medium,
    small,
    status,
    text,
    margin,
    value,
    ...others
}) => {
    // Initial size
    let size = "xl";

    if (medium) {
        size = "md";
    } else if (small) {
        size = "sm";
    }

    const containerCssClass = cs("spinner-container", {
        "spinner-center": center && !inline,
        [`spinner-margin-${margin}`]: margin != null && !inline
    });

    const cdsProps = {
        inverse,
        size,
        status,
        value,
        "aria-label": text
    };

    return (
        <div className={containerCssClass} {...others}>
            <CdsProgressCircle {...cdsProps} />
        </div>
    );
};

Spinner.propTypes = {
    center: PropTypes.bool,
    inline: PropTypes.bool,
    inverse: PropTypes.bool,
    medium: PropTypes.bool,
    small: PropTypes.bool,
    /* Set the status to change the color */
    status: PropTypes.oneOf([
        "neutral",
        "info",
        "success",
        "warning",
        "danger"
    ]),
    text: PropTypes.string,
    // Add some margin top to the Empty section
    margin: PropTypes.oneOf([
        "xs",
        "sm",
        "md",
        "lg",
        "xl",
        "xxl"
    ]),
    /* Set a specific progress value in the spinner */
    value: PropTypes.number
};

Spinner.defaultProps = {
    center: true,
    inline: false,
    inverse: false,
    medium: false,
    small: false,
    status: "neutral",
    text: "Loading..."
};

export default Spinner;
