import React, { PureComponent, createRef } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import "./Modal.css";
import "./Modal-dark.css";

import { get, set, isEmpty } from "../../helpers";

export class Modal extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            show: props.show || false,
            showContent: props.show || false
        };

        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.modal = createRef();
    }

    static propTypes = {
        errorMessage: PropTypes.string,
        disabledCloseIcon: PropTypes.bool,
        enableCloseModalOnDocumentClick: PropTypes.bool,
        //Property, which identifies that some api call is in progress.
        isLoading: PropTypes.bool,
        role: PropTypes.string
    }

    static defaultProps = {
        errorMessage: "",
        disabledCloseIcon:  false,
        enableCloseModalOnDocumentClick: true,
        isLoading: false,
        role: "dialog"
    }
    /**
     * TODO: replace it in "2.0.0" to helpers method "className"
     */
    className(condition, property) {
        if (isEmpty(property)) {
            return !isEmpty(condition) ? condition : "";
        }

        return condition ? property : "";
    }

    getBlock(condition, component) {
        if (condition) {
            if (typeof component === "function") {
                return component();
            }

            return component;
        }

        return null;
    }

    componentDidUpdate(props) {
        const { show } = this.props;

        if (props.show !== this.props.show) {
            if (show) {
                this.setState({
                    showContent: true
                }, ()=> {
                    if(this.modal.current) {
                        this.modal.current.focus();
                    }
                });
            } else {
                setTimeout(() => {
                    this.setState({
                        showContent: false
                    });
                }, 200);
            }
        }

    }

    componentDidMount() {
        const {show, enableCloseModalOnDocumentClick} = this.props;
        enableCloseModalOnDocumentClick && document.addEventListener("click", this.handleDocumentClick, true);
        show && this.modal.current.focus();
    }

    componentWillUnmount() {
        const {enableCloseModalOnDocumentClick} = this.props;
        enableCloseModalOnDocumentClick && document.removeEventListener("click", this.handleDocumentClick, true);
    }

    handleKeyDown = event => {
        const focusableElements = this.modal.current.querySelectorAll(
            "button:not([disabled]), input"
        );
        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];
      
        if (event.key === "Tab") {
            // TAB key
            if (event.shiftKey) {
            // Shift + TAB
                if (document.activeElement === firstElement) {
                    event.preventDefault();
                    lastElement.focus();
                }
            } else {
            // TAB
                if (document.activeElement === lastElement) {
                    event.preventDefault();
                    firstElement.focus();
                }
            }
        }
    };

    handleDocumentClick(event) {
        const { show, modalStaticBackdrop = false } = this.props;

        if (modalStaticBackdrop) {
            return;
        }

        if (show) {
            const {
                backdropHide = true
            } = this.props;

            if (backdropHide) {
                const parts = event.composedPath();

                if (!parts.includes(this.modal.current)) {
                    this.handleClose();
                }
            }
        }
    }

    handleClose() {
        const { property, parent, onClose } = this.props;
        if (!isEmpty(onClose) && typeof onClose === "function") {
            return onClose();
        }

        if (
            !isEmpty(property) &&
            !isEmpty(parent) &&
            get(parent, "updater.isMounted", () => false)(parent)
        ) {
            if (/\./g.test(property)) {
                return parent.setState(set(parent.state, property, false));
            }

            return parent.setState({
                [property]: false
            });
        }
    }

    handleAction(data) {
        
        const {
            onAction = function () {
            }
        } = this.props;

        if (
            data.type === "default.modal.cancel" ||
            data.type === "default.modal.ok"
        ) {
            data.type = data.type.replace(/^default\./gm, "");

            onAction(data.type, data);
        }

        onAction(data.type, data);
    }

    get size() {
        const { size } = this.props;

        return !isEmpty(size) ? `vm-modal-${size}` : "";
    }


    render() {
        const {
            show,
            title,
            className,
            actions = [
                {
                    label: "Cancel",
                    type: "default.modal.cancel",
                    className: "btn btn-outline"
                },
                {
                    label: "ok",
                    type: "default.modal.ok",
                    className: "btn btn-primary"
                }
            ],
            closeable = true,
            footer,
            errorMessage,
            disabledCloseIcon,
            isLoading,
            role
        } = this.props;

        const modalHeaderCssClass = cn(
            "vm-modal-header",
            {
                "is-loading" : isLoading
            }
        );

        const modalBodyCssClass = cn(
            "vm-modal-body",
            {
                "is-loading" : isLoading
            }
        );

        const { showContent } = this.state;

        if (show) {
            document.body.classList.add("no-scrolling");
        } else {
            document.body.classList.remove("no-scrolling");
            return null;
        }

        return (
            <div className={`vm-modal ${this.className(show, "show")}  ${this.className(className)} ${this.size}`} onKeyDown={this.handleKeyDown}>
                <div tabIndex="0" ref={this.modal} className="vm-modal-wrapper" aria-labelledby="modalheading" aria-modal="true" aria-live="polite" role={role}>
                    <div className={modalHeaderCssClass}>
                        <h3 id="modalheading" className="vm-modal-header-title">{title}</h3>
                        {
                            this.getBlock(
                                closeable,
                                <button className="btn vm-modal-header-close" aria-label="close" type="button" onClick={this.handleClose} disabled={disabledCloseIcon}>
                                    <clr-icon shape="times" />
                                </button>
                            )
                        }
                    </div>
                    <div className={modalBodyCssClass}>
                        {showContent ? this.props.children : null}
                    </div>
                    <div className="vm-modal-footer">
                        {errorMessage && <div className="modal-error-message">{errorMessage}</div>}
                        {
                            footer
                                ? footer
                                : actions.map((item, key) => (
                                    <button
                                        className={`${this.className(item.className)}`}
                                        type="button"
                                        disabled={item.disabled || isLoading}
                                        onClick={this.handleAction.bind(this, item)}
                                        key={key}
                                    >
                                        {item.label}

                                        {
                                            item.loading
                                                ? <div className="spinner spinner-sm" />
                                                : null
                                        }
                                    </button>
                                ))
                        }
                    </div>
                </div>

                <div className="vm-modal-backdrop" />
            </div>
        );
    }
}
