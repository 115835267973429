import React, { Component, Fragment } from "react";
import SearchBar from "./SearchBar";
import AppSearchHandler from "./AppSearchHandler";
import Backdrop from "../Backdrop";
import { isEmpty, get } from "../../helpers";
class AppSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchObj: new AppSearchHandler(get(props, "type", "public")),
            loading: false,
            expand: false,
            inputFocused: false,
            isTestOrg : props.isTestOrg ?? false
        };

    }

    searchText = (text, isFocused = false) => {
        const {searchObj, isTestOrg} = this.state;
        this.setState({loading: true, expand: isFocused});
        searchObj.searchProducts(text, isTestOrg).then(data => {
            this.setState({
                searchObj: {...data},
                loading: false
            });
        });
    }

    closeSearch = ()=> {
        const {searchObj} = this.state;
        const newObj = searchObj.closeSearch();
        setTimeout(() => {
            this.setState({loading: false, searchObj: {...newObj}, expand: false});
        }, 0);
    }

    inputFocusHandler = ()=> {
        const {loading, searchObj} = this.state;
        if(loading || !isEmpty(searchObj.list) || !isEmpty(searchObj.error)){
            this.setState({expand: true});
        }
    }

    inputBlurHandler = ()=> {
        this.setState({expand: false});
    }

    render() {

        const {searchObj, loading, expand} = this.state;
        const {
            type = "public", 
            placeholder, 
            hideSearchIconOnExpand = false, 
            id = "", 
            responsive=false, 
            lightBackground=true,
            compact=false
        } = this.props;

        return (
            <Fragment>
                <SearchBar
                    id={id}
                    onClear={this.closeSearch}
                    onSearch={this.searchText}
                    loading={loading}
                    error={searchObj.error}
                    searchData={searchObj}
                    expand={expand}
                    onInputFocus={this.inputFocusHandler}
                    type={type}
                    closeExpand={this.inputBlurHandler}
                    showAllLink={searchObj.showAllLink}
                    placeholder={placeholder}
                    hideSearchIconOnExpand={hideSearchIconOnExpand}
                    responsive = {responsive}
                    lightBackground = {lightBackground}
                    compact={compact}
                />
                { lightBackground && <Backdrop 
                    show={expand} 
                    onBackdropClick={this.inputBlurHandler} 
                />}
            </Fragment>
        );
    }
}

AppSearch.propTypes = {

};

export default AppSearch;