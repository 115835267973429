import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import ErrorMessagePlaceholder from "../ErrorMessagePlaceholder";

const NoPermission = ({title, buttonText, reRoutePath}) => {
    const history = useHistory();
    return(
        <ErrorMessagePlaceholder
            id="no-permission"
            title={title}
            buttonText={buttonText}
            onClickHandler={() => history.push(reRoutePath)}
        >
            <p>
        The issue has been automatically logged and our team has been notified.
        In the meantime try reloading the page in a few minutes, as this may be
        a transitory error.
            </p>
            <p>
        In addition to that,{" "}
                <b>
          please ensure your current user and organization has permissions to
          access it
                </b>
        .
            </p>
        </ErrorMessagePlaceholder>
    );
};

NoPermission.propTypes = {
    title: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    reRoutePath: PropTypes.string.isRequired
};

export default NoPermission;
