import { useEffect } from "react";
import { withRouter } from "react-router-dom";

function AutoScrollToTop({ history, targetId = null }) {
    useEffect(() => {
        const scrollWindow = targetId ? document.getElementById(targetId) : window;
        const unlisten = history.listen(() => {
            if(targetId) {
                scrollWindow.scrollTop = 0;
            } else {
                scrollWindow.scrollTo(0, 0);
            }
        });
        return () => {
            unlisten();
        };
    });

    return (null);
}

export default withRouter(AutoScrollToTop);