import serverConfig from "../../config/server";
import vacURL from "../../config/vacURL";

const CURRENT_ENV = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : "prod";
const SERVER_URL = serverConfig[CURRENT_ENV].url;
const VAC_URL = vacURL["vac_endpoint"];

export {
    SERVER_URL,
    VAC_URL
};
