import React from "react";
import PropTypes from "prop-types";
import { Modal } from "../Modal";
import "./FeedbackSuccesModal.css";

export const FeedbackSuccesModal = ({showSuccessModal, setShowSuccessModal}) => {

    const handleOnClose = () => setShowSuccessModal();

    const displaySuccessModal = () => (
        <Modal
            show={showSuccessModal}
            actions={[{ label: "CLOSE", type: "cancel", className: "btn btn-primary modal-btn" }]}
            onAction={handleOnClose}
            onClose={handleOnClose}
            modalStaticBackdrop={true}
        >
            <div className="success-modal-wrapper">
                <div className="success-icon">
                    <clr-icon shape="success-standard" size="30" className="success-icon"></clr-icon>
                </div>
                <div>Thank you for your feedback. Your input is valuable to us.</div>
            </div>

        </Modal>
    );

    return (
        <div>
            {displaySuccessModal()}
        </div>
    );

};

FeedbackSuccesModal.propTypes = {
    showSuccessModal: PropTypes.bool,
    setShowSuccessModal: PropTypes.func
};

FeedbackSuccesModal.defaultProps = {
    showSuccessModal: false,
    setShowSuccessModal: () => {}
};

export default FeedbackSuccesModal;