import React from "react";
import PropTypes from "prop-types";
import "./Loader.css";

/**
 * Loader component is to be used when we need wait
 * for the data from BE or for any other reason if user is to be indicated to wait.
 */
const Loader = function ({ size = "", loaderText }) {
    const className = size && `spinner-${size}`;

    return <div className="loader-container">
        <p className={`page-loader spinner ${className}`}/>
        { size ? <span aria-live="assertive" className="spinner-text">{loaderText}</span> : <span aria-live="assertive" className="sr-only">loading</span> }
    </div>;
};
Loader.propTypes = {
    /**
    * The size of the spinner can be sm, inline or ""
    */
    size : PropTypes.oneOf([
        "sm",
        "inline",
        ""
    ]),
    /**
    * The default text shown on the screen will be Loading...
    */
    loaderText: PropTypes.string
};

Loader.defaultProps = {
    loaderText : "Loading..."
};

export default Loader;
