const config = require(`./config-files/${process.env.REACT_APP_CONFIG_ENV}.json`);

const s3Conf = config["s3"];
const cdnEnabled = config["cdn-enabled"];

const media = s3Conf.media;
const cdn = s3Conf.cdn;
const deploymentImages = s3Conf["deployment-images"];

const S3 = {
    MEDIA: {
        BUCKETNAME: media.BUCKETNAME,
        DIR: media.DIR,
        REGION: media.REGION,
        ACCESSKEYID: media.ACCESSKEYID,
        SECRETACCESSKEY: media.SECRETACCESSKEY
    },
    DEPLOYMENT_IMAGES: {
        BUCKETNAME: deploymentImages.BUCKETNAME,
        DIR: deploymentImages.DIR,
        REGION: deploymentImages.REGION,
        ACCESSKEYID: deploymentImages.ACCESSKEYID,
        SECRETACCESSKEY: deploymentImages.SECRETACCESSKEY
    },
    CDN: (typeof cdn !== "undefined") ? {
        CONTAINERS: cdn.CONTAINERS,
        OVA: cdn.OVA,
        HELM: cdn.HELM,
        STATICMEDIA: cdn.STATICMEDIA

    } : {},
    CDN_ENABLED_CONFIG: cdnEnabled
};

export default S3;