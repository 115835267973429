import jwt from "jsonwebtoken";
import axios from "axios";
import { cookieHelper } from "./cookieHelper";
import KEYS from "../../config/keys";

const publicCalls = axios.create();

class tokenHelperClass {

    setTokenByName(name, value, noCookie=true) {
        this.setTokenToStorageByName(name, value);

        if (noCookie) {
            this.setTokenToCookieByName(name, value);
        }
    }

    setTokenToSessionByName(name, value) {
        window.sessionStorage.setItem(name, value);
    }

    setTokenToStorageByName(name, value) {
        window.localStorage.setItem(name, value);
    }

    setTokenToCookieByName(name, value) {
        cookieHelper.setCookieByName(name, value);
    }

    getTokenByName(name) {
        return this.getTokenFromStorageByName(name) || this.getTokenFromCookieByName(name);
    }

    getTokenFromStorageByName(name) {
        return window.localStorage.getItem(name) || window.sessionStorage.getItem(name);
    }

    getTokenFromCookieByName(name) {
        return cookieHelper.getCookieByName(name);
    }
    deleteTokenByName(key) {
        window.localStorage.removeItem(key);
        cookieHelper.deleteCookie(key);
        window.sessionStorage.removeItem(key);
    }

    getAuthToken () {
        return this.getAuthTokenFromStorage() || this.getAuthTokenFromCookie();
    }

    getAuthTokenFromStorage() {
        return window.sessionStorage.getItem(KEYS.ACCESS_TOKEN);
    }

    getAuthTokenFromCookie () {
        return cookieHelper.getCookieByName(KEYS.ACCESS_TOKEN);
    }

    setAuthToken (value) {
        this.setAuthTokenToStorage(value);

        return true;
    }

    setAuthTokenToStorage (value) {
        window.sessionStorage.setItem(KEYS.ACCESS_TOKEN, value);

        return true;
    }

    setAuthTokenToCookie (value) {
        cookieHelper.setCookieByName(KEYS.ACCESS_TOKEN, value);

        return true;
    }

    deleteAuthToken() {
        this.deleteAuthTokenFromStorage();
        this.deleteAuthTokenFromCookie();
    }

    deleteAuthTokenFromStorage() {
        window.sessionStorage.removeItem(KEYS.ACCESS_TOKEN);
    }

    deleteAuthTokenFromCookie() {
        cookieHelper.deleteCookie(KEYS.ACCESS_TOKEN);
    }

    getRefreshToken() {
        return this.getRefreshTokenFromStorage() || this.getRefreshTokenFromCookie();
    }

    getRefreshTokenFromStorage() {
        return window.sessionStorage.getItem(KEYS.REFRESH_TOKEN);
    }

    getRefreshTokenFromCookie() {
        return cookieHelper.getCookieByName(KEYS.REFRESH_TOKEN);
    }

    setRefreshToken(token) {
        this.setRefreshTokenToStorage(token);
    }

    setRefreshTokenToStorage(value) {
        window.sessionStorage.setItem(KEYS.REFRESH_TOKEN, value);

        return true;
    }

    setRefreshTokenToCookie(value) {
        cookieHelper.setCookieByName(KEYS.REFRESH_TOKEN, value);

        return true;
    }

    deleteRefreshToken() {
        this.deleteRefreshTokenFromStorage();
        this.deleteRefreshTokenFromCookie();
    }

    deleteRefreshTokenFromStorage() {
        window.sessionStorage.removeItem(KEYS.REFRESH_TOKEN);
    }

    deleteRefreshTokenFromCookie() {
        cookieHelper.deleteCookie(KEYS.REFRESH_TOKEN);
    }

    tokenAlreadyExpired(token) {
        const currentTime = Math.round((new Date()).getTime() / 1000);

        return (token.exp < currentTime);
    }

    /**
     *
     * @param {*} token This token will be of form string
     */
    decodeNotPossible(token) {
        //TODO: user empty function here
        return token === null || token === "undefined";
    }

    decodeToken(token) {
        return jwt.decode(token);
    }

    isTokenExpired () {
        const token = this.getAuthToken();
        if (this.decodeNotPossible(token)){
            return true;
        }

        const decodedToken = this.decodeToken(token);

        return this.tokenAlreadyExpired(decodedToken);
    }

    getTokenExpiryTime() {
        const token = this.getAuthToken();
        if (this.decodeNotPossible(token)) {
            return false;
        }

        const decodedToken = this.decodeToken(token);

        return decodedToken.exp;
    }

    clearAllTokens() {
        this.deleteAuthToken();
        this.deleteRefreshToken();
        this.deleteTokenByName("id_token");
    }

    setAllTokens(authToken, refreshToken, id_token, user, user_tracking_id, encUserId, hasVACAccess) {

        this.setAuthToken(authToken);
        this.setRefreshToken(refreshToken);
        this.setTokenToSessionByName("id_token", id_token);
        // this.setTokenByName("id_token", id_token, false);
        this.setTokenByName("user_tracking_id", user_tracking_id, false);
        this.setTokenByName("encUserId", encUserId, false);
        this.setTokenByName("user", JSON.stringify(user), false);
        this.setTokenToSessionByName("hasVACAccess", hasVACAccess);

        this.setTokenByName("current_permission", this.getUserType(user), false);
    }

    getUserType(user) {
        if(user) {
            return user.usertype.slug;
        }

        return null;
    }

    getOrgRolesFromAuthToken() {
        const cspAuthToken = this.getAuthTokenFromStorage();
        if (cspAuthToken) {
            const decodedToken = this.decodeToken(cspAuthToken);

            return decodedToken && decodedToken["perms"] ? decodedToken["perms"] : "";
        }
        return "";
    }

    //TODO: To be removed with RBAC changes
    getPermission(authToken, SERVICE_ID) {
        const decodedToken = this.decodeToken(authToken);
        const perms = decodedToken.perms;

        const userPerm = `external/${SERVICE_ID}/srv-marketplace:marketplaceuser`;
        const adminPerm = `external/${SERVICE_ID}/srv-marketplace:marketplaceadmin`;

        for (let perm of perms) {
            if (perm.includes(userPerm)) return true;
            else if (perm.includes(adminPerm)) return true;
            else if (perm.includes("publisher") && perm.includes(SERVICE_ID)) return true;
            else if (perm.includes("consumer") && perm.includes(SERVICE_ID)) return true;
        }

        return null;
    }

    getDataFromAuthToken(key) {
        const token = this.getAuthToken();

        if (this.isTokenExpired()) {
            return false;
        }

        const decodedToken = this.decodeToken(token);
        const keyName = KEYS.TOKEN_KEYS[key];

        if (typeof keyName === "undefined") {
            return false;
        }

        return decodedToken[keyName];
    }

    generateNewAuthToken(clientId, clientSecret, url) {
        const serialize = obj => {
            let str = [];
            for (let p in obj)
                if (Object.prototype.isPrototypeOf.call(obj, p)) {
                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                }
            return str.join("&");
        };

        const refreshToken = this.getRefreshToken();

        const formData = serialize({
            refresh_token: refreshToken,
            client_id: clientId,
            client_secret: clientSecret,
            grant_type: "REFRESH_TOKEN"
        });

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        return publicCalls.post(url, formData, config);
    }

    showPrivateFilters() {
        // To enable private products in app, just uncomment the line below.
        // return !this.isTokenExpired();
        return false;
    }

    // Function will clear specefic data on logout
    clearUserLocalData = () => {
        this.deleteTokenByName("current_permission");
        this.deleteTokenByName("current_org");
        this.deleteTokenByName("user");
        this.deleteTokenByName("userId");
        this.deleteTokenByName("hasVACAccess");
    }
}

const tokenHelper = new tokenHelperClass();
export { tokenHelper };

