const isObject = require("../../helpers").isObject;

if (!Object.values) {
    /**
     * @param  {object} object
     * @return {array}
     */
    Object.values = function (object) {
        if (!isObject(object)) {
            throw new TypeError("\"object\" must be a \"Object\"");
        }

        const values = [];

        for (const key in object) {
            if (Object.prototype.hasOwnProperty.call(object, key) && {}.propertyIsEnumerable.call(object, key)) {
                values.push(object[key]);
            }
        }

        return values;
    };
}

if (!Object.entries) {
    /**
     * @param  {object} object
     * @return {array}
     */
    Object.entries = function (object) {
        if (!isObject(object)) {
            throw new TypeError("\"object\" must be a \"Object\"");
        }

        const entries = [];

        for (const key in object) {
            if (Object.prototype.hasOwnProperty.call(object, key) && {}.propertyIsEnumerable.call(object, key)) {
                entries.push([
                    key,
                    object[key]
                ]);
            }
        }

        return entries;
    };
}



// POLYFILLS
// Event.composedPath
(function(E, d, w) {
    if(!E.composedPath) {
        E.composedPath = function() {
            if (this.path) {
                return this.path;
            }
            let target = this.target;
            this.path = [];
            while (target.parentNode !== null) {
                this.path.push(target);
                target = target.parentNode;
            }
            this.path.push(d, w);
            return this.path;
        };
    }
})(Event.prototype, document, window);
