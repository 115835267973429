import React, {useState, useCallback, useRef} from "react";
import {withRouter} from "react-router";
import {CdsIconButton} from "@cds/react/button";
import classnames from "classnames";
import {isEmpty, get, getUrlParams, convertToUrlParams} from "../../helpers";
import {useDebouncedEffect} from "./useDebounceHook";
import StatusView from "./StatusView";
import SearchResultView from "./SearchResultView";
import PropTypes from "prop-types";
import "./SearchBar.scss";
/* eslint-disable */
import { CdsIcon } from "@cds/react/icon";
/* eslint-enable */
import { ClarityIcons, searchIcon, timesCircleIcon } from "@cds/core/icon";

ClarityIcons.addIcons(searchIcon, timesCircleIcon);

SearchBar.propTypes = {
    placeholder: PropTypes.string,          //Placeholder to be shown in search box, Default to "Search for Solutions"
    searchText: PropTypes.string,
    onSearch: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    onClear: PropTypes.func.isRequired,
    searchData: PropTypes.any,
    expand: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    showAllLink: PropTypes.string.isRequired
};

function showStatusView(loading, error) {
    return loading || !isEmpty(error);
}

function SearchBar(props) {
    const {
        placeholder = "Search for solutions",
        searchText = getUrlParams(get(props, "location.search", "")).search || "",
        onSearch,
        loading = false,
        onClear,
        searchData = null,
        error = null,
        expand,
        onInputFocus,
        type,
        showAllLink,
        closeExpand,
        hideSearchIconOnExpand,
        history: {push, location},
        id = "",
        responsive = false,
        lightBackground= false,
        compact=false
    } = props;

    const searchInputRef = useRef();
    const clearInputRef = useRef();
    const errorSectionRef = useRef();
    const [
        searchString,
        setSearch
    ] = useState(searchText);

    const [
        focusedItem,
        setFocusedItem
    ] = useState(-1);

    const [
        escToggleBtn,
        setEscToggleBtn
    ] = useState(false);

    const searchHandler = useCallback(function(event) {
        const {value} = event.target;
        setSearch(value);
        setEscToggleBtn(false);
    }, []);

    const serachCallBack = useCallback(() => {
        if(!isEmpty(searchString)) {
            const isInputFocused = document.activeElement === (searchInputRef.current);
            onSearch(searchString, isInputFocused);
        } else {
            onClear();
        }
    }, [
        onClear,
        onSearch,
        searchString,
        searchInputRef
    ]);

    useDebouncedEffect(serachCallBack, 500);

    const clearSearch = () => {
        const urlSearchObj = getUrlParams(get(location, "search", ""));
        delete urlSearchObj.search;
        const newSearchStr = convertToUrlParams(urlSearchObj);
        push({
            pathname: location.pathname,
            search: newSearchStr
        });
        setSearch("");
        onClear();
        if(searchInputRef && searchInputRef.current) {
            setTimeout(() => {
                searchInputRef.current.focus();
            }, 0);
        }
    };

    const showAllClickHandler = useCallback(() => {
        closeExpand();
        push(showAllLink);
    }, [
        closeExpand,
        showAllLink,
        push
    ]);

    const handleEscapeKeyPress= ({key}) =>{
        if(key==="Escape"){
            setFocusedItem(-1);
            clearInputRef.current.focus();
            setEscToggleBtn(true);
            onClear();
        }
    };

    const handleInputFocus=()=>{
        searchInputRef.current.focus(); 
        setFocusedItem(-1);
    };
    
    const clearBtnFocus=e=>{
        const { count, list } = searchData;
        if (!e.shiftKey || e.key !== "Tab" || escToggleBtn) {
            return;
        }
        if (count !== 0) {
            e.preventDefault();
            setFocusedItem(list.length - 1);
        } else {
            e.preventDefault();
            errorSectionRef.current.focus();
        }
       
    };
    const handleKeyDown = e => {
        if(!isEmpty(searchString) && !escToggleBtn){ 
            e.preventDefault();
            clearInputRef.current.focus();
            setFocusedItem(-1);   
        }
    };

    const hasStatusView = showStatusView(loading, error);
    const hideSearch = hideSearchIconOnExpand && !isEmpty(searchString);
    return (
        <div onKeyDown={handleEscapeKeyPress} className="search-bar-wrapper">
            <div 
                className={classnames(
                    "input-wrapper",
                    {"compact": compact},
                    {"adaptive": responsive}, 
                    {"light": lightBackground}
                )} 
                cds-layout="horizontal gap:sm align:vertical-center"
            >
                {
                    !hideSearch && <div 
                        className={classnames(
                            "search-icon", 
                            {"light": lightBackground}
                        )}>
                        <CdsIcon 
                            shape="search" 
                            size={compact ? "20" : "24" }
                        />
                    </div>
                }
                <input
                    cds-layout="align:stretch"
                    value={searchString}
                    id={id}
                    type="text"
                    role="combobox"
                    aria-controls="select search"
                    aria-autocomplete="both"
                    aria-expanded={expand}
                    tabIndex="0"
                    placeholder={placeholder}
                    onClick={handleInputFocus}
                    onChange={searchHandler}
                    ref={searchInputRef}
                    onFocus={onInputFocus}
                />
                {
                    !isEmpty(searchString) &&
                    <CdsIconButton tabIndex="0" ref={clearInputRef} onClick={clearSearch} onKeyDown={clearBtnFocus} aria-label="clear search"  role="button" action="flat" size="icon">
                        <CdsIcon 
                            shape="times-circle">
                        </CdsIcon>
                    </CdsIconButton>
                }
            </div>
            <div className="result-view-wrapper">
                <div className="result-view-content">
                    {
                        hasStatusView && expand && <StatusView
                            errorSectionRef={errorSectionRef}
                            handleKeyDown={handleKeyDown}
                            loading={loading}
                            error={error}
                        />
                    }

                    {
                        !hasStatusView && !isEmpty(searchData) && expand && <SearchResultView 
                            showAllClick={showAllClickHandler} 
                            type={type} 
                            list={searchData.list} 
                            count={searchData.count} 
                            focusedItem={focusedItem} 
                            setFocusedItem={setFocusedItem}
                            onKeyBoardNavigation = {handleKeyDown}/>
                    }
                </div>
            </div>
        </div>
    );
}

export default withRouter(SearchBar);
