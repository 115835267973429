import React, { forwardRef } from "react";
import CaptchaDisplay from "../CaptchaDisplay";
import "./FeedbackForm.css";

const CaptchaComponent = forwardRef(({ setCaptchaDetail, captchaDetails, captchaAlert }, ref) => {

    const onCaptchaCodeChangeHandler = ({currentTarget}) => {
        const {value} = currentTarget;
        const newCaptchaDetails = {...captchaDetails, captchaInput: value};
        setCaptchaDetail(newCaptchaDetails);
    };

    const newCaptchaHandler = value => {
        setCaptchaDetail({captchaHash: value, captchaInput: null});
    };

    return (
        <div className="captcha-style">
            <CaptchaDisplay
                ref={ref}
                onChange={onCaptchaCodeChangeHandler}
                onLoadNewCaptcha={newCaptchaHandler}
                alert={captchaAlert}
            />
            {
                captchaAlert &&
                <span className="error-msg">
                    {captchaAlert}
                </span>
            }
        </div>
    );
});

CaptchaComponent.displayName="CaptchaComponent";

export default CaptchaComponent;