import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {DropdownItem} from "@vmware-react/clarity/dist/dropdown";

DropdownList.propTypes = {
    list: PropTypes.array.isRequired,
    onToggleClick: PropTypes.func.isRequired,
    classes: PropTypes.string
};
/*
    DropdownList componet will take dropdown list items in form of an array
    and return a React component array of type DropdownItem.
    We will call onToggleClick function recieved from prop onClick of list item
    Which in turn will cause the main dropdown to close.
    If item has a route property, clicking on item will redirect to route.
    TODO: In future we may add support to execute a custom function on click and
    display custom html formatted component in place of displayname. 
*/

function getButtonClass(item){
    return item.classes ? "dropdown-item".concat(" ", item.classes) : "dropdown-item";
}
function DropdownList(props) {
    const {list = [], onToggleClick, history, activePage} = props;

    const itemClickHandler = useCallback(item => {
        if(item && item.route) {
            history.push(item.route);
        }
        onToggleClick();
    }, [
        onToggleClick,
        history
    ]);

    return (
        list.map(item => {
            const isActivePage = activePage.displayname===item.displayname;
            if(item.type === "header") {
                return <h4 className={getButtonClass(item)} key={item.slug}>{item.displayname}</h4>;
            } else {
                return (
                    <DropdownItem key={item.slug} onItemClick={()=>itemClickHandler(item)} className={getButtonClass(item)} >
                        <div tabIndex="-1" role="button" aria-label={isActivePage ? `Selected ${item.displayname}` : item.airaLabelText}>
                            {item.displayname}
                        </div>
                    </DropdownItem>
                ); 
            }
            
        })
    );
}

export default withRouter(DropdownList);
