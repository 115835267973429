import {
    authPost,
    authGet,
    authDelete,
    authPut
} from "../helpers/httpHelpers";

import {SERVER_URL} from "./apiHelper";

const getMarketingAssetsList = (
    pagination = JSON.stringify({ page: 1, pageSize: 4, enable: true }),
    payload
) => authGet(`${SERVER_URL}/commondata/marketingassets?pagination=${pagination}`, payload);
const marketingAssetAdd = payload => authPost(`${SERVER_URL}/commondata/marketingasset`, payload); 
const marketingAssetUpdate = (assetId, payload) => authPost(`${SERVER_URL}/commondata/marketingasset/${assetId}`, payload);
const marketingAssetDelete = payload => authDelete(`${SERVER_URL}/commondata/marketingasset`, payload);
const getCountriesList = () => authGet(`${SERVER_URL}/commondata/countries`);
const getStatesList = countryCode => authGet(`${SERVER_URL}/commondata/countries/${countryCode}/states`);
const createNewsletterOptIn = payload => authPost(`${SERVER_URL}/newsletter`, payload);
const getNewsletterOptIn = userid => authGet(`${SERVER_URL}/newsletter/${userid}`);
const updateNewsletterOptIn = (id, payload) => authPut(`${SERVER_URL}/newsletter/${id}`, payload);
const getNewsletterOptInList = () => authGet(`${SERVER_URL}/newsletter`);


export {
    getMarketingAssetsList,
    marketingAssetAdd,
    marketingAssetUpdate,
    marketingAssetDelete,
    getCountriesList,
    getStatesList,
    createNewsletterOptIn,
    getNewsletterOptIn,
    updateNewsletterOptIn,
    getNewsletterOptInList
};
