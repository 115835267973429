import { searchProducts, adminSearchProducts } from "../../services/api/product";
import {get} from "../../helpers/";

class AppSearchHandler {
    
    constructor(type) {
        this.type = type;
        this.error = null;
        this.loading = false;
        this.list = [];
        this.count = 0;
        this.searchText = "";
        this.placeholder = this.getPlaceholder();
        this.showAllLink = "";
    }

    searchOwnProduct = ()=> (this.type === "publisher" || this.type === "admin")

    getPlaceholder = ()=> this.searchOwnProduct() ? "My Products" : "Products"

    searchProducts = async (text, isTestOrg = false) => {
        this.loading = true;
        this.list = [];
        this.count = 0;
        this.error = null;
        const searchFunction = isTestOrg ? adminSearchProducts : searchProducts;
        try {
            const { data } = await searchFunction(text, this.searchOwnProduct());
            if (data.response) {
                this.loading = false;
                this.list = get(data, "response.dataList", []);
                this.count = get(data, "response.params.itemsnumber", 0);
                this.searchText = text;
                this.showAllLink = this.getShowAllLink(isTestOrg);
                this.error = this.list.length === 0 ? `No result(s) found for "${text}".` : null;
            }
            return this;
        } catch (e) {
            this.loading = false;
            this.list = [];
            this.count = 0;
            this.error = "Something went wrong, please try again later.";
            return this;
        }
    }

    closeSearch = ()=> {
        this.loading = false;
        this.error = null;
        this.list = [];
        return this;
    }

    getShowAllLink = isTestOrg=> {
        const { searchText, type } = this;

        if (type === "public") {
            return `/services?search=${searchText}`;
        }

        if (type === "publisher") {
            return `/services/management?search=${searchText}`;
        }

        if(type === "admin") {
            return `/admin/services/${isTestOrg ? "test-" : ""}management?search=${searchText}`;
        }
    }
}

export default AppSearchHandler;
