import React, { useRef, useEffect, useCallback } from "react";
import {withRouter} from "react-router-dom";
import { authHelper } from "../../helpers/authHelper";
import {bindOnce} from "../../helpers";
import { tokenHelper } from "../../services/helpers/tokenHelper";
import APP_CONFIG from "../../config/app";

import "@vmw/csp-header/csp-header";

import "@clr/icons/clr-icons.min.css";
import "@clr/icons/clr-icons.min";

// explicitily adding the icons for header , because of compatibility issue
import { ClarityIcons, windowCloseIcon, vmBugIcon, infoCircleIcon } from "@cds/core/icon";
ClarityIcons.addIcons(vmBugIcon, infoCircleIcon, windowCloseIcon);

const CURRENT_ENV = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : "prod";
const CURRENT_CONFIG = APP_CONFIG.SSO[CURRENT_ENV.toLocaleUpperCase()];

const { CSP_HOST_STG, DISCOVERY_URL, CLIENT_ID, REDIRECT_URL, SERVICE_ID } = CURRENT_CONFIG;
const REDIRECT_PATH = "/";
const HOME_PAGE = "/";

const getHeaderEnv = env => {

    if(env === "production" || env === "staging") return "prd";
    if(env === "devint") return "dev";

    return "stg";
};
// TODO: Revisit this code -- Abhishek
const headerEnv = getHeaderEnv(process.env.REACT_APP_CONFIG_ENV);

const Header = props => {

    const headerRef =  useRef(null);
    const {history} = props;

    const getAuthState = props => props.isAuthValid && (tokenHelper.getAuthToken() && tokenHelper.getAuthToken().length > 0);

    const getAuthToken = useCallback(props => {
        if(getAuthState(props)) {
            return tokenHelper.getAuthToken();
        }

        return "";
    },
    []);

    const redirectToHome = useCallback(event => {
        event.preventDefault();
        if(history.location.pathname !== HOME_PAGE){
            history.push(HOME_PAGE);
        }
    }, [history]);

    useEffect(() => {
        const { current: ref } = headerRef;
        if (ref == null) {
            return;
        }
        ref.authToken = getAuthToken(props);
        ref.authenticated = getAuthState(props);
        ref.options = headerConfig();
        // this.headerRef.current.publicOptions = this.headerConfig;
        ref.environment = headerEnv;
        ref.showSignIn = true;

        ref.addEventListener("signIn", () => {
            authHelper.redirectToSSO();
        });

        ref.addEventListener("switchOrg", event => {
            const orgLink = event.detail.refLink;
            window.location = `${CSP_HOST_STG}${DISCOVERY_URL}?org_link=${orgLink}&orgLink=${orgLink}&redirect_uri=${REDIRECT_URL}&state=/&client_id=${CLIENT_ID}`;
        });

        bindOnce(ref, "titleClick", redirectToHome);

        const brandingLogo = ref.getElementsByClassName("branding");
        if(brandingLogo && brandingLogo[0]) {
            bindOnce(brandingLogo[0], "click", redirectToHome);
        }

        ref.addEventListener("signOut", () => {
            // Clear auth and refresh token and redirect user to homepage.
            tokenHelper.clearAllTokens();
            tokenHelper.clearUserLocalData();
            window.location = REDIRECT_PATH;
        });
    }, [
        getAuthToken,
        headerRef,
        props,
        redirectToHome
    ]);

    const headerConfig = () =>  ({
        baseRoute: "/",
        title: "VMware Marketplace",
        context: "service",
        showBackdrop: true,
        showOrgSwitcher: true,
        enableSignout: true,
        enableChangeDefaultOrg: true,
        enableIntercom: false,
        environment: headerEnv,
        showSignIn: true,
        showNotificationsMenu: true,
        // docsProducts: [
        //     "VMware Marketplace",
        //     "Marketplace"
        // ],
        // docsDefaultSearch: "VMware Marketplace",
        // chatButtonMessage: "The team usually replies in minutes",
        // statusIOComponentId: "q87sq6ktlz1h",
        // communitiesLink: "https://communities.vmware.com/community/vmtn/vmc-on-aws/overview",
        subTitle: "VMware Marketplace",
        showSupportTab: false,
        showSignOutInHeader: false,
        globalBranding: true,
        alwaysShowOrgSearch: true,
        serviceRefLink: `/csp/gateway/slc/api/definitions/external/${SERVICE_ID}`,
        theme: "Light", //IMP : This is required for web-compoent version as it is not set on initialization
        showHelpMenu: true, //To enable ? icon on header
        statusIOComponentId: "mgqtd8sfqlvb",
        docsProducts: [], // Used for Default filter.
        docsDefaultSearch: "Marketplace"  // To provide default value if search field is empty.
    });

    return <csp-header-x ref={headerRef}></csp-header-x>;
};

export default withRouter(Header);
