import React, { useState, useEffect, useCallback, memo, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import BasicLabel from "../../components/BasicLabel/BasicLabel";
import { BasicTextArea, BasicRadio, BasicRadioGroup, BasicInput} from "@vmware-react/clarity/dist/basic-inputs";
import { categories, typeOfFeedback } from "./Constants/Constants";
import getUserDetails from "../../helpers/getUserDetails";
import CaptchaComponent from "./CaptchaComponent";
import { trim, isEmail, isEmpty } from "../../helpers";
import i18en from "./Constants/i18en";
import "./FeedbackForm.css";

const customStyles = {
    indicatorSeparator: () => ({ 
        display: "none"
    }),
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? "#000000" : "#828282",
        cursor: "pointer"
    })
};

const customTheme = theme => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: "#dddddd",
        primary: "#dddddd"
    }
});
export const FeedbackForm = React.forwardRef(({ isAuthValid, setIsDisabled, captchaAlert }, ref) => {

    const [
        category,
        setCategory
    ] = useState("");

    const [
        feedback,
        setFeedback
    ] = useState("");

    const [
        description,
        setDescription
    ] = useState("");

    const [
        isWilling,
        setIsWilling
    ] = useState();

    const [
        name,
        setName
    ] = useState("");

    const [
        email,
        setEmail
    ] = useState("");

    const [
        captchaDetails,
        setCaptchaDetail
    ] = useState({captchaHash: null, captchaInput: null});


    const fieldAndCallbackMap = {
        category: e => setCategory(e.value),
        feedback: e => setFeedback(e.value),
        description: e => setDescription(e.target.value),
        willingOrNot: e => setIsWilling(e.target.value),
        name: e => setName(e.target.value),
        email: e => setEmail(e.target.value)

    };

    const handleOnChange =(e, type) => {
        fieldAndCallbackMap[type](e);
        handleValidation(type);
    };

    const handleValidation = useCallback(() => {
        const mandatoryFields = [
            category,
            feedback,
            description,
            captchaDetails.captchaHash,
            captchaDetails.captchaInput

        ];
        if(isWilling === "yes" && !isAuthValid) {
            isEmail(email) ?  mandatoryFields.push(name, email) : mandatoryFields.push(name, "");
        }

        const isFormValid = mandatoryFields.every(item =>  !isEmpty(trim(item))) && description.length <= 2000;
        setIsDisabled(!isFormValid);
    }, [
        setIsDisabled,
        isAuthValid,
        captchaDetails,
        isWilling,
        category,
        feedback,
        description,
        name,
        email
    ]);

    useEffect(() => handleValidation(), [handleValidation]);


    const getTellUsAboutSection = () => (
        <div className="tell-us-wrapper">
            <p>{i18en.HEADING}</p>
            <p>
                {i18en.SUBHEADING_TITLE}
                <span>{i18en.SUBHEADING}</span>
            </p>
            <p dangerouslySetInnerHTML={{__html: i18en.NOTES}}></p>
        </div>
    );

    const getSelectBox = (label, name, list) => {

        const selectedCategory = list.filter(item => item.value === category);
        const selectedFeedback = list.filter(item => item.value === feedback);

        return (
            <div className="select-wrapper">
                <BasicLabel label = {label} required = {true} />
                <Select
                    name={name}
                    value={name === "category" ? selectedCategory : selectedFeedback}
                    options={list}
                    styles={customStyles}
                    theme={customTheme}
                    onChange={e => handleOnChange(e, name)}
                />
            </div>
        );
    };

    const getCategoriesAndFeedbackSection = () => (
        <div className="categoies-feedback-wrapper">
            {getSelectBox("Select a category", "category", categories)}
            {getSelectBox("Type of Feedback", "feedback", typeOfFeedback)}
        </div>
    );

    const getDescriptionSection = () => {
        const charLimit = 2000;
        const showError = description.length > charLimit;
        return (
            <div className="feedback-description-wrapper">
                <BasicTextArea
                    label="Description"
                    name="description"
                    required={true}
                    placeholder="Share your feedback with us"
                    value={description}
                    onChange={e => handleOnChange(e, "description")}
                />
                <div className={`${showError ? "subText-err" : "subText-wrapper"}`}>
                    {
                        showError && 
                        <span className="error-msg">Maximum character limit is {charLimit}, please reduce the content length.</span>
                    }
                    <span className={`${showError ? "" : "character-count"}`}>{`${description.length}/${charLimit}`}</span>
                </div>
            </div>
        );
    };

    const getWillingToParticipateSection = () =>  (
        <div className="willing-section-wrapper">
            <BasicRadioGroup
                label="At times VMware does User Testing, would you be willing to participate in a follow-up conversation?"
            >
                <BasicRadio label="Yes" value="yes" name="willingOrNot" onChange={e => handleOnChange(e, "willingOrNot")} checked={isWilling === "yes"}/>
                <BasicRadio label="No" value="no" name="willingOrNot" onChange={e => handleOnChange(e, "willingOrNot")} checked={isWilling === "no"}/>
            </BasicRadioGroup>
            {isWilling === "yes" && !isAuthValid && renderUserDetails()}
        </div>
    );

    const renderUserDetails = () => (
        <div className="user-form-wrapper">
            <BasicInput label= "Name" value={name} name="name" required = {true} onChange={e => handleOnChange(e, "name")} /> 
            <BasicInput
                label= "Email"
                value={email}
                name="email"
                required = {true}
                onChange={e => handleOnChange(e, "email")}
                error={ !isEmpty(email) && !isEmail(email)}
                errorMessage="Please enter a valid Email"
                helpTextPosition = "after-label"
            /> 
        </div>
    );

    useImperativeHandle(ref, () => ({
        getFormValue
    }));

    const getFormValue = () => (
        {
            category,
            feedback,
            description,
            isWilling,
            userDetails: isAuthValid ? getUserDetails() : {name, email},
            captchaDetails
        }
    );

    return (
        <div className="feedback-form-wrapper" ref={ref}>
            {getTellUsAboutSection()}
            {getCategoriesAndFeedbackSection()}
            {getDescriptionSection()}
            {getWillingToParticipateSection()}
            <CaptchaComponent setCaptchaDetail={setCaptchaDetail} captchaDetails={captchaDetails} captchaAlert={captchaAlert} />
        </div>
    );
});

FeedbackForm.displayName = "FeedbackForm";

FeedbackForm.propTypes = {
    isAuthValid: PropTypes.bool,
    setIsDisabled: PropTypes.func
};

FeedbackForm.defaultProps = {
    isAuthValid: false,
    setIsDisabled: () => {}
};

export default memo(FeedbackForm);

