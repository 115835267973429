const vmwareMarketplace = "VMware Marketplace";

const titleRules = [
    {
        pattern: "/services",
        title: `${vmwareMarketplace} - View Solutions`
    },
    {
        pattern: /^\/services\/details\/.+/,
        title: `${vmwareMarketplace} - View Solution`
    },
    {
        pattern: /^\/product\/add(?:\/[\w-]+)?\/?$/,
        title: `${vmwareMarketplace} - Publish Solution`
    },
    {
        pattern: /^\/product\/update\/.+/,
        title: `${vmwareMarketplace} - Update Solution`
    }
    
];

export const getAppTitle = path => {

    const value = titleRules.find(item => {
        if(typeof item.pattern === "string") {
            return path === item.pattern;
        }
        return item.pattern.test(path);
    });

    return value ? value.title : vmwareMarketplace;
};


