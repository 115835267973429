import React from "react";
import Alert from "../Alert";
import { CLOSE_TOP_ALERT, useAlertContext } from "../../context/alerts";
import "./AlertManager.scss";

const AlertManager = () => {
    const {
        state:{alerts}, 
        dispatch
    } = useAlertContext();

    const onCloseAlert = (id, callback) => {
        dispatch({type: CLOSE_TOP_ALERT, payload: id});
        if (typeof callback === "function") {
            callback();
        }
    };

    return (
        <section
            aria-label="Important notifications"
            aria-live="polite"
            aria-hidden={alerts.length === 0}
            className="alert-section"
        >
            { alerts.map(alert => {
                const { id, message, closable, onClose, ...alertProps } = alert;

                if (closable) {
                    alertProps.closable = true;
                    alertProps.closeAlert = () => onCloseAlert(id, onClose);
                }

                return (
                    <Alert key={id} {...alertProps} message={message}/>
                );
            }) }
        </section>
    );
};

export default AlertManager;
