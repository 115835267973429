import axios from "axios";

export async function downloadPDF(url) {
    const fileName = getFileName(url);
    const resp = await axios.get(url,  {
        headers: {
            "Accept": "application/pdf"
        },
        responseType: "blob"
    }).then(resp => resp);
    if (resp) {
        saveFileFromBlob(await new Blob([resp.data]), fileName);
    }
}
	
function saveFileFromBlob(blob, fileName) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
        return;
    }
    const URL = window.URL || window.webkitURL;
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.style.display = "none";
    document.body.appendChild(link);
    link.href = blobUrl;
    link.download = fileName;

    link.click();
    setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(URL);
    }, 100);
	
}

function getFileName(url) {
    const urlSegments = url.split("/");
    return urlSegments.pop();
}
