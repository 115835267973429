const heading = "Stay ahead of the curve with the latest news, product updates, new solutions and more from the VMware Marketplace!",
    subscribeText = "Yes, I would like to receive VMware Marketplace Newsletters.",
    subscribeSubText = "Note: By subscribing to the newsletter, you agree to ",
    unsubscribeText = "No, I am no longer interested. Do not send me VMware Marketplace Newsletters.",
    unsubscribeSubText = "If you unsubscribe, you will still continue to receive account updates and notifications. For more information, refer",
    successfullSubscription = "You have been successfully subscribed!",
    successfullUnSubscription = "You've been successfully unsubscribed from VMware Marketplace Newsletter.",
    initialCaptchaDetails = { captchaHash: null, captchaInput: null },
    OPT_IN = "opt-in",
    OPT_OUT = "opt-out",
    checkboxGroupLabel = "If you have a moment, please let us know why you unsubscribed:",
    defaultErrorMssg = "Something went wrong! Please try again.";

const config = [
    {
        label: subscribeText,
        type: "radio",
        value: "opt-in",
        name: "newsletterSubscription",
        subtext: subscribeSubText,
        checked: true
    },
    {
        label: unsubscribeText,
        type: "radio",
        value: "opt-out",
        subtext: unsubscribeSubText,
        name: "newsletterSubscription"
    }
];

const unsubscribeReason = [
    {
        label: "I no longer want to receive your newsletter",
        value: "I no longer want to receive your newsletter"
    },
    {
        label: "The newsletter isn't relevant to me",
        value: "The newsletter isn't relevant to me"
    },
    {
        label: "I never signed up",
        value: "I never signed up"
    },
    {
        label: "I can't view the newsletter properly",
        value: "I can't view the newsletter properly"
    }
];

export {
    heading,
    config,
    unsubscribeReason,
    successfullSubscription,
    successfullUnSubscription,
    initialCaptchaDetails,
    OPT_IN,
    OPT_OUT,
    checkboxGroupLabel,
    defaultErrorMssg
};