import {
    authPost,
    authGet
} from "../helpers/httpHelpers";
import {SERVER_URL} from "./apiHelper";

const getAgreementDetails = orgType => authGet(`${SERVER_URL}/usermanagement/agreement/latest?orgType=${orgType}`);

const postAgreementAccept = payload => authPost(`${SERVER_URL}/usermanagement/agreement/accept`, payload);

const checkLatestAgreementSign = orgType => authGet(`${SERVER_URL}/usermanagement/agreement/sign?orgType=${orgType}`);

const getAgreementsList = (
    pagination = JSON.stringify({ page: 1, pageSize: 4, enable: true }),
    filters = JSON.stringify({}),
    search = "",
    sortBy = JSON.stringify({}),
    filterType = "",
    orgType
) => authGet(
    `${SERVER_URL}/usermanagement/agreement/list?pagination=${pagination}&filters=${filters}&search=${search}&sortBy=${sortBy}&filterType=${filterType}&orgType=${orgType}`
);

export {
    getAgreementDetails,
    postAgreementAccept,
    getAgreementsList,
    checkLatestAgreementSign
};
