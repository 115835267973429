import { appConfig, getFeatureFlagValue, get } from "../../helpers";


const CSP_FEATURE_FLAGS = get(appConfig, "CSP_FEATURE_FLAGS", "");
const { UI_MAINTENANCE_MODE } = CSP_FEATURE_FLAGS;

const initialState = {
    alerts:[]
};
export const getInitialAlertState = ffClient => {
    const nodeUIFF = getFeatureFlagValue(ffClient, UI_MAINTENANCE_MODE);
    const maintenanceModeAlert = {
        alerts:[
            {
                id: "123",
                message: "We are under maintenance. We will be up and running shortly. Thank you for your patience.",
                closable: false,
                onClose: ()=>{},
                type: "danger"
            }
        ]
    };

    return nodeUIFF ? maintenanceModeAlert : {alerts:[]};
};

export default initialState;