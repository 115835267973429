const CURRENT_ENV = process.env.REACT_APP_ENV;
const PROD_ENV = CURRENT_ENV === "prod";

class Logger {
    static log = (...args) => {
        !PROD_ENV && false && console.log(...args);
    }
    static warn = (...args) => {
        !PROD_ENV && console.warn(...args);
    }
    static error = (...args) => {
        !PROD_ENV && console.error(...args);
    }
}

export default Logger;
