import React from "react";
import PropTypes from "prop-types";
import Loader from "../Loader/Loader";

StatusView.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.string
};

function getStatusView(loading, error) {

    if(loading) {
        return (
            <Loader size="sm" />
        );
    }

    if(error) {
        return (
            <span className="status-text">{error}</span>
        );
    }

    return null;

}

function StatusView(props) {
    const {loading, error, handleKeyDown, errorSectionRef} = props;
    const keyNavigation = e => {
        if (e.key==="Tab" ) handleKeyDown(e);
    };

    return (
        <div tabIndex="0" onKeyDown={keyNavigation} ref={errorSectionRef} className="loading-view-wrapper">
            {getStatusView(loading, error)}
        </div>
    );
}

export default StatusView;