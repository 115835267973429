export default class LocalStorageHelper {

    static save(key, value) {
        localStorage.setItem(key, typeof value === "object" ? JSON.stringify(value) : value);
    }

    static get(key) {
        try {
            return JSON.parse(localStorage.getItem(key));
        } catch (e) {
            return localStorage.getItem(key);
        }
    }

    static getFromObj(key, ObjKey) {
        
        try{
            const obj = JSON.parse(localStorage.getItem(key));
            return obj && ObjKey ? obj[ObjKey] : "";
        } catch(e) {
            return "";
        }
    }

    static remove(key) {
        localStorage.removeItem(key);
    }

    static update(key, value) {
        LocalStorageHelper.save(key, { ...LocalStorageHelper.get(key), ...value });
    }

    /** Cleans full storage for current session */
    static clear() {
        localStorage.clear();
    }
}