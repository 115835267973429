import React from "react";
import ProductSuggestionCard from "../SuggestionsSearch/ProductSuggestionCard";
import OwnProductSuggestionCard from "../SuggestionsSearch/OwnProductSuggestionCard";


import PropTypes from "prop-types";

SearchResultItem.propTypes = {
    /** Description of prop "data". */
    data: PropTypes.object.isRequired,
    /** Description of prop "type". */
    type: PropTypes.string.isRequired
};

// Placeholder onclick function, will be used when changing redirect logic.
function onCardClickHandler() {

}

function SearchResultItem(props) {
    const {data, type, active, onKeyBoardNavigation, isLastItem} = props;
    if(type === "public") {
        return (
            <ProductSuggestionCard 
                isLastItem={isLastItem}
                data={data} 
                onItemClick={onCardClickHandler} 
                active={active} 
                onKeyBoardNavigation={onKeyBoardNavigation}
            />
        );
    }

    return (
        <OwnProductSuggestionCard 
            data={data} 
            onItemClick={onCardClickHandler} 
            active={active}
        />
    );
}

export default SearchResultItem;