import React, { Component } from "react";
import APP_CONFIG from "../config/app";
import { authHelper } from "../helpers/authHelper";
import Logger from "../helpers/logger";
import PageLoader from "../components/PageLoader";
import { login } from "../services/api/sso";
import { tokenHelper } from "../services/helpers/tokenHelper";


const ENV = ((process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "dev") ? "DEV" : "PROD");
const CURRENT_CONFIG = APP_CONFIG.SSO[ENV.toLocaleUpperCase()];

const PRIMARY = CURRENT_CONFIG.APP_BASE;

class SsoManager extends Component {
    constructor(props) {
        super(props);

        this.state = { loading: true, callbackUrl: null };
    }

    checkNoAccessPath = path => (typeof path === "string" && path.includes("no-access"));

    manageSsoResponse(response) {
        const {callbackUrl} = this.state;
        const { access_token, refresh_token, id_token, orgId, user, userId, redirect, user_tracking_id, encUserId, hasVACAccess, supplement3country = null } = response;
        tokenHelper.setAllTokens(access_token, refresh_token, id_token, user, user_tracking_id, encUserId, !!hasVACAccess);
        tokenHelper.setTokenByName("current_org", orgId);
        tokenHelper.setTokenByName("userId", userId);
        tokenHelper.setTokenToSessionByName("supplementCountry", supplement3country);
        // Check if callbackUrl path is "no-access", in that case do not rediret back to "no-access", instead redirect to "redirect" path const.
        authHelper.redirectUser(PRIMARY, ((this.checkNoAccessPath(redirect) || this.checkNoAccessPath(callbackUrl) || redirect === "/restricted") ? redirect : (callbackUrl || redirect)));
    }

    performSsoLogin(code) {
        const that = this;

        login({ code })
            .then(data => {
                that.manageSsoResponse(data.data.response || {});
            }, function (err) {
                if(err.response) {
                    that.manageSsoResponse(err.response.data.response || {});
                } else {
                    authHelper.redirectUserToLogout(PRIMARY);
                }
            }).catch(error=>Logger.log(error));
    }
    componentDidMount() {
        const params = (new URL(document.location)).searchParams;
        const code = params.get("code");
        const callbackUrl = params.get("state");
        this.setState({
            callbackUrl
        });
        if (code) {
            this.performSsoLogin(code);
        } else {
            authHelper.redirectUserToLogout();
        }
    }

    render() {
        return (
            <PageLoader />
        );
    }
}

export default SsoManager;
