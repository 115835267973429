import s3config from "../../src/config/s3";
const { MEDIA, CDN, CDN_ENABLED_CONFIG } = s3config;

function positionProductCards() {
    const mainContainer = document.getElementById("catalogContainer");
    if (!mainContainer) {
        return;
    }
    const mainContainerWidth = mainContainer.offsetWidth;
    const maxCardsInRow = parseInt(mainContainerWidth / 300, 10);
    let cardMargin = ((mainContainerWidth - maxCardsInRow * 300) / maxCardsInRow).toFixed(2) - 1;
    const cardArray = Array.from(document.getElementsByClassName("card-box"));
    cardMargin = cardMargin < 6 ? 6 : cardMargin;
    cardMargin = cardMargin > 20 ? 20 : cardMargin;
    cardArray.forEach(el => {
        el.style.marginRight = cardMargin + "px";
    });
    return cardMargin;
}
function getFullPlatformName(key) {
    const data = {
        "on-prem": "On-Prem",
        "onprem": "On-Prem",
        "tkgi": "VMware TKGI",
        "vcd": "VMware Cloud Director",
        "vmc": "VMware Cloud on AWS"
    };
    if (key && typeof key === "string") {
        return data[key.toLowerCase()] || null;
    }
    return null;
}

function fixProductFilterCategory(filterObj) {
    let responseObj = {...filterObj};
    if(filterObj && typeof filterObj["deploymentPlatforms"] !== "undefined") {
        responseObj["deploymentPlatforms.type"] = responseObj["deploymentPlatforms"];
        delete responseObj["deploymentPlatforms"];
    }
    if(filterObj && typeof filterObj["categories"] !== "undefined") {
        responseObj["category"] = responseObj["categories"];
        delete responseObj["categories"];
    }
    return responseObj;
}

function getTextIcons(item) {
    const theme = window.localStorage.getItem("theme");
    if (theme === "DARK") {
        return typeof item === "string" ? `${item.split("-").join("").toLowerCase()}_white_text_icon.svg` : "";
    }

    return typeof item === "string" ? `${item.split("-").join("").toLowerCase()}_text_icon.svg` : "";
}

function getIcons(item) {
    return typeof item === "string" ? `${item.split("-").join("").toLowerCase()}_icon.svg` : "";
}

function getPlatformIcons(type, format = "text") {

    if(typeof type === "string" && typeof format === "string") {
        switch (format) {
        case "text":
            return getStaticMediaContentCDN(`${getTextIcons(type)}`);
        case "notext":
            return getStaticMediaContentCDN(`${getIcons(type)}`);

        default:
            return "";
        }
    }
}

function getStaticMediaContentCDN(item, v2="") {
    if(CDN_ENABLED_CONFIG) {
        return `https://${CDN.STATICMEDIA}/${MEDIA.DIR}${v2}/${item}`;
    }else {
        return `https://${MEDIA.BUCKETNAME}.s3.${MEDIA.REGION}.amazonaws.com/${MEDIA.DIR}${v2}/${item}`;
    }
}


function getDefaultImage(event) {
    event.currentTarget.setAttribute("src", getStaticMediaContentCDN("product-logo-placeholder.png"));
}

function getStickyTopHeight(acceptableHeight) {
    const topStickyBar = document.querySelector(".sticky-top");
    if(topStickyBar && topStickyBar.offsetHeight >= acceptableHeight) {
        return (topStickyBar.offsetHeight - acceptableHeight);
    }
    return -1;
}

// Works for three digit numbers only
function getNumberToText(num) {
    const refArr = {
        "1": "one",
        "2": "two",
        "3": "three",
        "4": "four",
        "5": "five",
        "6": "six",
        "7": "seven",
        "8": "eight",
        "9": "nine",
        "10": "ten",
        "11": "eleven",
        "12": "twelve",
        "13": "thirteen",
        "14": "fourteen",
        "15": "fifteen",
        "16": "sixteen",
        "17": "seventeen",
        "18": "eighteen",
        "19": "nineteen",
        "20": "twenty",
        "30": "thirty",
        "40": "fourty",
        "50": "fifty",
        "60": "sixty",
        "70": "seventy",
        "80": "eighty",
        "90": "ninety"
    };
    if(num === 0)
        return "zero";

    let textNum = "";
    if(num/100 > 1){
        textNum = refArr[Math.floor(num/100)] + " hundred ";
    }
    num = num%100;
    if(num/10 >1){
        if(textNum !== ""){
            textNum = textNum + "and ";
        }
        if(num>=10 && num<=19){
            textNum = textNum + refArr[num];
            num=0;
        }else{
            textNum = textNum + refArr[Math.floor(num/10)*10] + " ";
        }
    }
    num=num%10;
    if(num !== 0){
        textNum = textNum + refArr[num];
    }
    return textNum;
}

function getFullPlatformNameForFilter(key) {
    const data = {
        "on-prem": "On-Prem",
        "vmc": "VMware Cloud",
        "vcd": "VMware Cloud Director",
        "onprem": "On-Prem",
        "tkgi": "VMware Tanzu",
        "others": "Others"
    
    };
    if (key && typeof key === "string") {
        return data[key.toLowerCase()] || key;
    }
    return null;
}
function compareObjects(a, b) {
    if (a === b) return true;
   
    if (typeof a !== "object" || typeof b !== "object" || a === null || b === null) return false;
   
    let keysA = Object.keys(a), keysB = Object.keys(b);
   
    if (keysA.length !== keysB.length) return false;
   
    for (let key of keysA) {
        if (!keysB.includes(key)) return false;
    
        if (typeof a[key] === "function" || typeof b[key] === "function") {
            if (a[key].toString() !== b[key].toString()) return false;
        } else {
            if (!compareObjects(a[key], b[key])) return false;
        }
    }
   
    return true;
}

/*
    Converts string to standard kabab case format,
    Example: AI + Machine Learning => ai-machine-learning
    Need regex to filter out multiple spaces and special symbols here.
*/
function convertStrToKababCase(str) {
    if(typeof str === "string") {
        return str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
            .map(x => x.toLowerCase())
            .join("-");
    }

    return "";
}

const formatSolutionTypeName = name => {
    const mapper = {
        VIRTUALMACHINES: "VIRTUAL MACHINES",
        PROFESSIONALSERVICES: "PROFESSIONAL SERVICES",
        CONTENTPACK: "CONTENT PACK",
        MANAGEMENTPACK: "MANAGEMENT PACK"
    };

    return mapper[name] ? mapper[name] : name;
};

const formatActionButtons = name=> {
    const key = name.toUpperCase();
    const mapper = {
        REJECTED: "Reject",
        ACTIVATED: "Activate",
        ACTIVATION_PENDING: "Activate",
        TERMINATED: "Terminate",
        CANCELLATION_PENDING: "Cancel",
        CANCELED: "Cancel",
        SUSPENDED: "Suspend"
    };

    return mapper[key] || key;
};


/**
 * Method to download text content in file.
 * @param {*} textToBeDownload Text content to be downloaded.
 * @param {*} fileName The file name of the file.
 * @param {*} fileFormat THe format of the file name to be downloaded.
 */
const downloadTextContentInFile = (textToBeDownload="", fileName="download", fileFormat="txt") =>{
    const element = document.createElement("a");
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(textToBeDownload));
    element.setAttribute("download", `${fileName}.${fileFormat}`);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};

export const KEY_SPACE = "Space";
export const KEY_ENTER = "Enter";

const isKeyboardActionTrigger = e => {
    const {code} = e;
    const triggerKeyList = [
        KEY_ENTER,
        KEY_SPACE
    ];

    return triggerKeyList.some(item => item === code);
};

const attachKeydown = (e, callback, ...args) => {
    if(isKeyboardActionTrigger(e)) {
        e.preventDefault();
        callback(...args);
    }
};

export {
    positionProductCards,
    getFullPlatformName,
    fixProductFilterCategory,
    getPlatformIcons,
    getStaticMediaContentCDN,
    getDefaultImage,
    getStickyTopHeight,
    getNumberToText,
    getFullPlatformNameForFilter,
    compareObjects,
    convertStrToKababCase,
    formatSolutionTypeName,
    formatActionButtons,
    downloadTextContentInFile,
    attachKeydown,
    isKeyboardActionTrigger
};
