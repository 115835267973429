const config = require(`./config-files/${process.env.REACT_APP_CONFIG_ENV}.json`);

const vac_endpoint = config["vac-endpoint"];



const vacURL = {
    vac_endpoint
};

export default vacURL;