const config = require(`./config-files/${process.env.REACT_APP_CONFIG_ENV}.json`);

const featureFlagsConfig = config["feature-flags"];
const secureCookies = config["secureCookies"];
const mixpanel = config["mixpanel"];
const vacFeatureFlagSdkKey = config["VAC_FEATURE_FLAG_SDK_KEY"];


const featureFlags = {
    billing: featureFlagsConfig.billing,
    cloudAccount: featureFlagsConfig.cloudAccount,
    secureCookies,
    mixpanel,
    vacFeatureFlagSdkKey
};

export default featureFlags;
