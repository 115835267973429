import featureFlags from "../../config/featureFlags";
import Logger from "../../helpers/logger";

class cookieHelperClass {

    decode(s) {
        return s.replace(/(%[0-9A-Z]{2})+/g, decodeURIComponent);
    }

    getCookieByName(name) {
        return this.getAllCookies()[name] || null;
    }

    getAllCookies() {
        let cookieJar = {};
        const cookies = document.cookie ? document.cookie.split("; ") : [];
        let i = 0;

        for (; i < cookies.length; i++) {
            const parts = cookies[i].split("=");

            let cookie = parts.slice(1).join("=");

            try {
                const name = this.decode(parts[0]);

                cookieJar[name] = cookie;
            } catch (e) {
                Logger.log("Error");
            }
        }

        return cookieJar;
    }

    setCookieByName(name, value, domain = ".market.csp.vmware.com") {
        const secure = this.needsSecureCookies() ? "Secure;" : "";
        document.cookie = `${name} = ${value};Domain=${domain};Path=/;${secure}`;
    }

    setCookieFromArray(cookiesJar) {
        return cookiesJar;
    }

    deleteCookie(name, domain = ".market.csp.vmware.com") {
        const secure = this.needsSecureCookies() ? "Secure;" : "";
        document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;Domain=${domain};${secure}`;
    }

    needsSecureCookies() {
        return featureFlags.secureCookies !== undefined ? featureFlags.secureCookies : true;
    }
}

const cookieHelper = new cookieHelperClass();

export {cookieHelper};
