import React from "react";
import EmptyTypes from "./Empty.types";
import PropTypes from "prop-types";
import cs from "classnames";
import { CdsIcon } from "@cds/react/icon";

// Load icons
import { ClarityIcons } from "@cds/core/icon";

import "./Empty.scss";

const Empty = ({
    children,
    icon,
    iconShape,
    iconProps,
    labelledBy,
    title,
    rotate,
    small,
    margin,
    inlineAlignLeft
}) => {
    // Load custom icons
    const customIcon = icon != null && iconShape != null;
    if (customIcon) {
        ClarityIcons.addIcons(iconShape);
    }

    const completeIconProps = {
        ...iconProps,
        size: small ? "48" : "96",
        shape: icon,
        "aria-hidden": "true"
    };

    const cssClass = cs("empty", {
        "empty-icon-rotate": rotate,
        [`empty-margin-${margin}`]: margin != null,
        "empty-align-left": inlineAlignLeft
    });
    const acccessibilityProps = {};

    if (title != null) {
        acccessibilityProps["aria-label"] = title;
    } else if (labelledBy != null) {
        acccessibilityProps["aria-labelledby"] = labelledBy;
    }

    return (
        <section className={cssClass} {...acccessibilityProps}>
            <CdsIcon {...completeIconProps} />
            <div>{children}</div>
        </section>
    );
};

Empty.propTypes = {
    children: PropTypes.node.isRequired,
    icon: PropTypes.string.isRequired,
    iconShape: PropTypes.array.isRequired,
    iconProps: PropTypes.object,
    labelledBy: PropTypes.string,
    // Add some margin top to the Empty section
    margin: PropTypes.oneOf([
        "xs",
        "sm",
        "md",
        "lg",
        "xl"
    ]),
    // Here we're validating that title or labelledBy is present.
    // It also validates if title is a String in case it's defined
    title: EmptyTypes.title,
    rotate: PropTypes.bool,
    small: PropTypes.bool,
    inlineAlignLeft: PropTypes.bool
};

Empty.defaultProps = {
    iconProps: {},
    small: false
};

export default Empty;
