import { createContext, useContext } from "react";

export const FeatureFlagsContext = createContext();

export const useFeatureFlags = () => {
    const context = useContext(FeatureFlagsContext);
    if (!context) {
        throw new Error(
            "useFeatureFlags must be used within a FeatureFlagsProvider"
        );
    }
    return context;
};
