
export default function componentLoader(cb, retriesLeft = 5, interval = 0) {
    return new Promise((resolve, reject) => {
        cb()
            .then(resolve)
            .catch(error => {
                setTimeout(() => {
                    if (retriesLeft === 1) {
                        // reject('maximum retries exceeded');
                        reject(error);
                        return;
                    }
                    // Passing on "reject" is the important part
                    if(error.type === "missing") {
                        // reload the page if the chunk is missing
                        window.location.reload();
                    } else {
                        componentLoader(cb, retriesLeft - 1, interval).then(resolve, reject);
                    }
                    
                }, interval);
            });
    });
}